import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { MailchimpService } from '../../../shared/services/mailchimp.service';
import { AuthService } from '../../../auth/auth.service';
import { ToastService } from '../../../shared/services/toast.service';

import { MailChimpConfig } from '../../../shared/models/mailchimp/campaign.model';

@Component({
  selector: 'app-mailchimp-config',
  templateUrl: './mailchimp-config.component.html',
  styleUrls: ['./mailchimp-config.component.scss']
})
export class MailchimpConfigComponent implements OnInit {
  mailchimpConfigForm: FormGroup;
  isFormInit: boolean = false;
  isEdit: boolean = false;

  mailChimpConfig: MailChimpConfig = new MailChimpConfig();

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private mailchimpService: MailchimpService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.mailchimpService.getMailChimpConfig().subscribe(
      (mailChimpConfig: MailChimpConfig) => {
        // - If API key was already submitted, update that API key
        if (mailChimpConfig) {
          this.isEdit = true;
          this.mailChimpConfig = mailChimpConfig;
        }

        this.initForm();
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving mailchimp config", error);
      }
    );
  }

  initForm() {
    this.mailchimpConfigForm = this.formBuilder.group({
      apiKey: this.formBuilder.control(null, [Validators.required])
    });

    this.isFormInit = true;
  }

  onAddMailChimpConfig(newMailChimpConfig: MailChimpConfig) {
    this.mailchimpService.addMailChimpConfig(newMailChimpConfig).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The MailChimp API Key has been accepted.");
        this.mailchimpService.configChanged.next(); // Let list component know of changes so it know when to re-init
        
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding API key", error);
        this.mailchimpService.configChanged.next(); // Let list component know of changes so it know when to re-init        
      }
    );
  }

  onUpdateMailChimpConfig(newMailChimpConfig: MailChimpConfig) {
    this.mailchimpService.updateMailChimpConfig(newMailChimpConfig).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The MailChimp API Key has been updated.");
        this.mailchimpService.configChanged.next(); // Let list component know of changes so it know when to re-init
        
        this.router.navigate(['../'], { relativeTo: this.route });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating API key", error);
        this.mailchimpService.configChanged.next(); // Let list component know of changes so it know when to re-init        
      }
    );
  }

  onSubmit() {
    let newMailChimpConfig = this.formBuilder.sanitizeFormValues(this.mailchimpConfigForm).value;

    if (this.isEdit) {
      newMailChimpConfig.id = this.mailChimpConfig.id; // - Set id of edited mailchimpConfig to its original id
      //newMailChimpConfig.updatedByUserId = this.authService.getUser().id;
      this.onUpdateMailChimpConfig(newMailChimpConfig);
    }
    else {
      //newMailChimpConfig.createdByUserId = this.authService.getUser().id;
      this.onAddMailChimpConfig(newMailChimpConfig);
    }
  }
}
