import { Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";

export abstract class BaseComponent implements OnDestroy {

  ngOnDestroy(): void {
    this.subsriptions$.map(elem => {
      if(elem) {
        elem.unsubscribe();
      }
    })
  }

  subsriptions$ : Subscription[] = [];

  ngOnInit() {

  }

  constructor() {

  }
}
