import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@angular/http';

import { AuthService } from '../../auth/auth.service';
import { LeadDeskService } from '../../shared/services/lead-desk.service';
import { ToastService } from '../../shared/services/toast.service';

import { CallingList, LeadDeskConfig } from '../../shared/models/lead-desk/lead-desk-config.model';
import { UtilityService } from 'app/views/shared/services/utility.service';

@Component({
  selector: 'app-lead-desk-list',
  templateUrl: './lead-desk-list.component.html',
  styleUrls: ['./lead-desk-list.component.scss']
})
export class LeadDeskListComponent implements OnInit {
  lists: CallingList[] = [];

  constructor(
    public router: Router,
    public authService: AuthService,
    public toastService: ToastService,
    private leadDeskService: LeadDeskService,
    private utilService: UtilityService
  ) { }

  ngOnInit() {
    this.utilService.setPageTitle('LeadDesk List');
    this.onGetConfig();

    this.leadDeskService.configChanged.subscribe(
      () => {
        this.onGetConfig();
      }
    );
  }

  onGetConfig() {
    this.leadDeskService.getLeadDeskConfig().subscribe(
      (leadDeskConfig: LeadDeskConfig) => {
        if (!leadDeskConfig) {
          this.router.navigate(["/lead-desk", "config"]);
        } else {
          // - Retrieve calling lists if API key was found
          this.onGetCallingLists();
        }
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving LeadDesk config", error);
      }
    );
  }

  onGetCallingLists() {
    this.leadDeskService.getCallingLists().subscribe(
      (lists: CallingList[]) => {
        this.lists = lists;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving lists", error);
      }
    );
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
