export class TenantAppSetting {
	constructor(
		public nextUpdate: Date = null,
		public values: TenantAppSettingItem[] = null
		) { }
}

export class TenantAppSettingItem {
	constructor(
		public name: string = null,
		public value: string = null
		) { }
}