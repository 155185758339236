import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { TenantAppSettingService } from "app/views/auth/tenant-app-setting.service";
import { CustomFormBuilder } from "app/views/shared/classes/CustomFormBuilder";
import { ContactSearchItem } from "app/views/shared/models/contact-search-item.model";
import { ContactService } from "app/views/shared/services/contact.service";
import { ToastService } from "app/views/shared/services/toast.service";
import { AutoComplete, SelectItem } from "primeng/primeng";
import { Response } from '@angular/http';
import { Contact } from "app/views/shared/models/contact.model";

@Component({
  selector: 'app-opportunity-board',
  templateUrl: './opportunity-board.component.html',
  styleUrls: ['./opportunity-board.component.scss']
})
export class OpportunityBoardComponent implements OnInit {
  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;
  opportunityBoardForm: FormGroup;                     // - Stores the opportunity form's values

  constructor(
    private cd: ChangeDetectorRef,
    private contactService: ContactService,
    public toastService: ToastService,
    private formBuilder: CustomFormBuilder
  ) {}

  private appSetting: TenantAppSettingService  // used on HTML
  assignedContactOptions: SelectItem[] = [];      // - Stores Contacts to select the Assigned Contact of this opportunity

  todoTasks: any[] = [
    { id: 'cd1', title: 'Residential Sale', agentPicture: 'https://isb.mt/wp-content/uploads/2019/12/alan.jpg', agentName: 'Alan Darmanin'},
    { id: 'cd2', title: 'Residential Lease', agentPicture: 'https://media.licdn.com/dms/image/D4E03AQGYWwlvHR3P5w/profile-displayphoto-shrink_400_400/0/1665081256336?e=1699488000&v=beta&t=eAG-Q2VHekX8pjtt1kgOO0n7pTPpHVDu7UTLln-0hQc', agentName: 'Hugo Boutroue'},
    { id: 'cd3', title: 'Residential Sale', agentPicture: 'https://media.licdn.com/dms/image/C5603AQHY7LL2zoy5CQ/profile-displayphoto-shrink_400_400/0/1534433997376?e=1699488000&v=beta&t=vnLML02DcDUUZV7ilL-qX9A8YdT6s0v713tg85g7U6s', agentName: 'Owen Faenza'},
  ];

  texts: string[];
  contacts = ["Alan Darmanin", "Owen Faenza", "Hugo Boutroue"]
  filteredContactMultiple: any[];

  ngOnInit() {
    this.opportunityBoardForm = this.formBuilder.group({
      filteredContactId: this.formBuilder.control(null, [Validators.required]),
    })
  }

  getContactTextLabel(c: Contact) {
    let labelText = c.fullName.trim().length > 0 ? c.fullName : "NO-NAME";
      c.idCardNumber ? labelText += " / ID: " + c.idCardNumber : "";
      c.companyName ? labelText += " / Company: " + c.companyName : "";
      c.homePhoneNumber && !this.appSetting.isHideSensitiveInfo ? labelText += " / Tel: " + c.homePhoneNumber : "";
      c.mobilePhoneNumber && !this.appSetting.isHideSensitiveInfo ? labelText += " / Mob: " + c.mobilePhoneNumber : "";
      c.email && !this.appSetting.isHideSensitiveInfo ? labelText += " / Email: " + c.email : "";

      return labelText;
  }

  filterContactMultiple(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: ContactSearchItem[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {
          let labelText = cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          // cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.companyName ? labelText += " / Company: " + cT.companyName : "";
          cT.telephone && !this.appSetting.isHideSensitiveInfo ? labelText += " / Tel: " + cT.telephone : "";
          cT.mobile && !this.appSetting.isHideSensitiveInfo ? labelText += " / Mob: " + cT.mobile : "";
          cT.contactEmail && !this.appSetting.isHideSensitiveInfo ? labelText += " / Email: " + cT.contactEmail : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.assignedContactOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactsAutoComplete.panelVisible) {
      this.contactsAutoComplete.hide();
    } else {
      this.contactsAutoComplete.show();
    }
  }

  inProgressTasks: any[] = [
    // Initialize with your initial tasks for the "In Progress" column
  ];

  completedTasks: any[] = [
    // Initialize with your initial tasks for the "Completed" column
  ];

  dateRange: any;
  createdBefore: any;
  createdSince: any;

  allowDrop(event: DragEvent) {
    event.preventDefault();
    const target = event.target as HTMLElement;
    if (target.classList.contains('dropzone')) {
      target.classList.add('droppable');
    }
  }
  
  clearDrop(event: DragEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('dropzone')) {
      target.classList.remove('droppable');
    }
  }

  draggedItem: any;

  drag(event: DragEvent) {
    const taskId = (event.target as HTMLElement).id;
    this.draggedItem = this.findTaskById(taskId);
    const target = event.target as HTMLElement;
    if (target.classList.contains('dropzone')) {
      target.classList.remove('droppable');
    }
  }

  drop(event: DragEvent, column: string) {
    debugger;
    event.preventDefault();
    if (this.draggedItem) {
      // Remove the task from the source column
      this.removeFromColumn(this.draggedItem, this.getColumnForTask(this.draggedItem));
      
      // Add the task to the destination column
      this.addToColumn(this.draggedItem, column);
      
      // Clear the dragged item
      this.draggedItem = null;
    }
  }

  // Helper function to find a task by its ID
  private findTaskById(id: string): any {
    debugger
    const allTasks = [...this.todoTasks, ...this.inProgressTasks, ...this.completedTasks];
    return allTasks.find(task => task.id === id);
  }

  // Helper function to determine the current column of a task
  private getColumnForTask(task: any): string {
    if (this.todoTasks.includes(task)) {
      return 'todo';
    } else if (this.inProgressTasks.includes(task)) {
      return 'inprogress';
    } else if (this.completedTasks.includes(task)) {
      return 'completed';
    }
    return '';
  }

  // Helper function to remove a task from a column
  private removeFromColumn(task: any, column: string): void {
    if (column === 'todo') {
      this.todoTasks = this.todoTasks.filter(t => t !== task);
    } else if (column === 'inprogress') {
      this.inProgressTasks = this.inProgressTasks.filter(t => t !== task);
    } else if (column === 'completed') {
      this.completedTasks = this.completedTasks.filter(t => t !== task);
    }
  }

  // Helper function to add a task to a column
  private addToColumn(task: any, column: string): void {
    if (column === 'todo') {
      this.todoTasks.push(task);
    } else if (column === 'inprogress') {
      this.inProgressTasks.push(task);
    } else if (column === 'completed') {
      this.completedTasks.push(task);
    }
  }
 }
