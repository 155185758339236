import { TableUtilityService } from './../../shared/services/table-utility.service';
import { Component, OnInit } from '@angular/core';
import { Response, URLSearchParams } from "@angular/http";

import { AuthService } from "../../auth/auth.service";
import { OpportunityService } from "../../shared/services/opportunity.service";
import { ConfirmationService } from "primeng/components/common/confirmationservice";
import { ToastService } from "../../shared/services/toast.service";
import { UserService } from '../../shared/services/user.service';

import { CookieService } from 'ngx-cookie';
import { Opportunity } from "../../shared/models/opportunity.model";
import { SelectItem, LazyLoadEvent, DataTable } from "primeng/primeng";


import { forkJoin } from 'rxjs/observable/forkJoin';
import { OpportunitiesSegment } from '../../shared/models/opportunities-segment.model';
import { OpportunityTypeService } from '../../shared/services/opportunity-type.service';
import { HttpParams } from '@angular/common/http';
import { ContactService } from 'app/views/shared/services/contact.service';
import { Contact } from 'app/views/shared/models/contact.model';
import { UtilityService } from 'app/views/shared/services/utility.service';
import { ActivatedRoute, Params, Router } from "@angular/router";

@Component({
  selector: 'app-opportunity-list',
  templateUrl: './opportunity-list.component.html',
  styleUrls: ['./opportunity-list.component.scss']
})
export class OpportunityListComponent implements OnInit {
  opportunities: Opportunity[] = [];

  favoriteOpportunities: Opportunity[] = [];

  contactsOptions: SelectItem[] = [];           // - Stores Contact Dropdown Options
  selectedContactOption: any;
  selectedContactFavOption: any;

  opportunityTypesOptions: SelectItem[] = [];   // - Stores Opp. Type Dropdown Options
  selectedOpportunityTypeOption: any;
  selectedOpportunityTypeFavOption: any;

  probabilityFilter: number;

  exportFileName: string = "PotenzaCRM-Opportunities-Export-" + new Date().toLocaleDateString("en-GB");

    //** DatePicker */
    todayDate: Date = new Date();
    yearRange: string = this.todayDate.getFullYear() - 10 + ':' + (this.todayDate.getFullYear() + 10); // - 20 Year Range

    /** Lazy Loading */
    totalRecords: number = 0;
    totalFavoriteRecords: number = 0;
    latestLazyLoadEvent: LazyLoadEvent;
    latestLazyLoadFavoriteEvent: LazyLoadEvent;

  /** Column Toggling */
  columnOptions: SelectItem[];
  selectedCols: any[];              // - Contains the table columns that should be visible
  selectedColsFavorite: any[];              // - Contains the table columns that should be visible
  allSelectableCols: any[] = [];    // - Contains all table columns that may be toggled on or off

  selectedColsDefault: any[] = [];  // - Contains the columns that are shown by default if no cookie is stored

  /** Advanced Options **/
  
  userOptions: SelectItem[] = [];
  selectedAssignedToOption: any;
  selectedCreatedByOption: any;

  firstLoad: boolean = true;
  
  queryContact: number;
  selectedContactName: any;
  selectedCreatedOnSince: any;
  selectedCreatedOnBefore: any;
  selectedName:any;
  selectedDescription:any;
  selectedProbability:any;
  selectedProbabilityMin: any;
  selectedProbabilityMax: any;
  selectedValue:any;

  selectedAssignedToFavOption:any;
  selectedCreatedByFavOption:any;    
  selectedContactNameFav:any;
  selectedCreatedOnSinceFav:any;
  selectedCreatedOnBeforeFav:any;
  selectedNameFav:any;
  selectedDescriptionFav:any;
  selectedValueFav:any;
  selectedProbabilityFavMin:any;
  selectedProbabilityFavMax:any;

  isAdvancedSearchCollapsed: boolean = false;
  isAdvancedSearchFavoriteCollapsed: boolean = false;
  allOppContacts: any;

  isFavoriteCollapsed: boolean = true;
  isNotFavoriteCollapsed: boolean = true;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private opportunityService: OpportunityService,
    private opportunityTypeService: OpportunityTypeService,
    private contactsService: ContactService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private cookieService: CookieService,
    private tableUtilService: TableUtilityService,
    private userService: UserService,
    private utilService: UtilityService
  ) { }

  ngOnInit() {
    this.utilService.setPageTitle('Opportunity List');
    

    this.route.queryParams.subscribe(
      (params: Params) => {
        const contactId = params['contactId'];
        if(contactId == undefined)
        {       
          this.ngOnInitDropDown();
          this.populateSearchOptions();
        //this.onGetOpportunities();
        this.initColumnOptions();
        }
        else{       
          
          this.queryContact = contactId;
          this.ngOnInitDropDownSingleContact(contactId);
          this.populateSearchOptions();
          //this.onGetOpportunities();
          this.initColumnOptions();
        }
       
        
      }
    );
  }

  ngOnInitDropDownSingleContact(contactId: number) {
    this.contactsService.getContact(contactId).subscribe(
      (contact: Contact) => {
        this.contactsOptions = [];

        this.selectedContactName = contact.fullName;
        this.allOppContacts = JSON.parse(JSON.stringify(contact));

        //this.contactsOptions.push({ label: "All Contacts", value: null });
        var contacts: Contact[] = [];
        contacts.push(contact);
        contacts.map(c => {
          this.contactsOptions.push({ label: c.fullName, value: c.fullName });
        });
      }
    );
  }

  ngOnInitDropDown() {
    this.contactsService.getOpportunityContacts().subscribe(
      (contacts: Contact[]) => {
        this.contactsOptions = [];

        this.allOppContacts = JSON.parse(JSON.stringify(contacts));

        this.contactsOptions.push({ label: "All Contacts", value: null });
        contacts.map(c => {
          this.contactsOptions.push({ label: c.fullName, value: c.fullName });
        });
      }
    );
  }

  initColumnOptions() {
    this.allSelectableCols = this.tableUtilService.getAllSelectableOpportunityCols();
    this.selectedColsDefault = this.tableUtilService.getSelectedOpportunityColsDefault();
    this.columnOptions = this.tableUtilService.getColumnOptions(this.columnOptions, this.allSelectableCols);

    let selectedColsCookie = this.cookieService.getObject("crm_selectedOpportunityCols") as any[];
    if (selectedColsCookie)
      this.selectedCols = selectedColsCookie;
    else
      this.selectedCols = this.selectedColsDefault;

    // favorite  selectedColsFavorite
    let selectedColsFavoriteCookie = this.cookieService.getObject("crm_selectedOpportunityColsFavorite") as any[];
    if (selectedColsFavoriteCookie)
      this.selectedColsFavorite = selectedColsFavoriteCookie;
    else
      this.selectedColsFavorite = this.selectedColsDefault;
  }

  // - Saves options in a cookie whenever they are changed
  onColumnOptionsChange() {
    this.cookieService.putObject("crm_selectedOpportunityCols", this.selectedCols);
  }

  onColumnOptionsFavoriteChange() {
    this.cookieService.putObject("crm_selectedOpportunityColsFavorite", this.selectedColsFavorite);
  }

  onSearchOptionChange(dt: DataTable, $event, field: string, matchMode: string) {
    dt.filter($event ? $event.value : null, field, matchMode);
  }
  onContactOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
    this.selectedContactName = $event.value;
    this.onSearchOptionChange(dt, $event, field, matchMode);
  }
  onProbabilityMinOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
    this.selectedProbabilityMin = $event.target.value;
    this.onSearchOptionChange(dt, $event, field, matchMode);
  }
  onProbabilityMaxOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
    this.selectedProbabilityMax = $event.target.value;
    this.onSearchOptionChange(dt, $event, field, matchMode);
  }

  onContactFavOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
    this.selectedContactNameFav = $event.value;
    this.onSearchOptionChange(dt, $event, field, matchMode);
  }
  onProbabilityFavMinOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
    this.selectedProbabilityFavMin = $event.target.value;
    this.onSearchOptionChange(dt, $event, field, matchMode);
  }
  onProbabilityFavMaxOptionChange(dt: DataTable, $event: any, field: string, matchMode: string) {
    this.selectedProbabilityFavMax = $event.target.value;
    this.onSearchOptionChange(dt, $event, field, matchMode);
  }

  onResetSearch(dt: DataTable) {

    this.selectedAssignedToOption = null;
    this.selectedCreatedByOption = null;      
    this.selectedContactName = null;
    this.selectedCreatedOnSince = null;
    this.selectedCreatedOnBefore = null;

    this.selectedName=null;
    this.selectedDescription=null;
    // this.selectedProbability=null;
    this.selectedValue=null;
  
    this.selectedProbabilityMin = null;
    this.selectedProbabilityMax = null;

    dt.filters = {};

    dt.filter(null, null, null);
  }

  onResetFavoriteSearch(dt: DataTable) {

    this.selectedAssignedToFavOption = null;
    this.selectedCreatedByFavOption = null;      
    this.selectedContactNameFav = null;
    this.selectedCreatedOnSinceFav = null;
    this.selectedCreatedOnBeforeFav = null;

    this.selectedNameFav=null;
    this.selectedDescriptionFav=null;
    // this.selectedProbability=null;
    this.selectedValueFav=null;
  
    this.selectedProbabilityFavMin = null;
    this.selectedProbabilityFavMax = null;

    dt.filters = {};

    dt.filter(null, null, null);
  }

  loadData(event: LazyLoadEvent) {
    this.latestLazyLoadEvent = event;

    let opportunityTypeId = this.selectedOpportunityTypeOption;
    let assignedToId = this.selectedAssignedToOption;
    let createdById = this.selectedCreatedByOption;

    
    let params = new HttpParams();
    params = params.set("from", event.first.toString());
    params = params.set("to", event.rows.toString());
    params = params.set("sortBy", event.sortField);
    if(event.sortOrder)
    params = params.set("order", event.sortOrder.toString());
    if(event.globalFilter)
    params = params.set("searchTerm", event.globalFilter);

    if(assignedToId)
    params = params.set("assignedToId", assignedToId);

    if(createdById)
    params = params.set("createdById", createdById);
    
    if(opportunityTypeId) {
    params = params.set("opportunityTypeId", opportunityTypeId);
    }
   
    if(this.selectedContactName) {
    params = params.set("fullName", this.selectedContactName ? this.selectedContactName.trim() : null);
    }
    // params.set("lastName", this.selectedContactFirstName ? this.selectedContactFirstName.trim() : this.selectedContactFirstName);

    // params.set("fullName", this.selectedContactLastName ? this.selectedContactLastName.trim() : this.selectedContactLastName);


    if(this.selectedName) {
    params = params.set("name", this.selectedName ? this.selectedName.trim() : null);
    }
    if(this.selectedDescription) {
    params = params.set("description", this.selectedDescription ? this.selectedDescription.trim() : null);
    }


    if (this.selectedProbabilityMin) {
      params = params.set("probabilityMin", this.selectedProbabilityMin ? this.selectedProbabilityMin.trim() : null);
    }
    if (this.selectedProbabilityMax) {
      params = params.set("probabilityMax", this.selectedProbabilityMax ? this.selectedProbabilityMax.trim() : null);
    }

    if(this.selectedValue) {
    params = params.set("value", this.selectedValue ? this.selectedValue.trim() : null);
    }

    if(this.selectedCreatedOnSince) {
    params = params.set("createdOnSince", this.selectedCreatedOnSince ? this.selectedCreatedOnSince : null);
    }
    if(this.selectedCreatedOnBefore) {
    params = params.set("createdOnBefore", this.selectedCreatedOnBefore ? this.selectedCreatedOnBefore : null);
    }

    if(this.queryContact)
    {
      this.contactsService.getContact(this.queryContact).subscribe(
        (contact: Contact) => {
          params = params.set("fullName", contact.fullName);
          this.onGetOpportunities(params);
        }
      );
    }
    else
    {
      
    this.onGetOpportunities(params);
    }
  }

  loadFavoriteData(event: LazyLoadEvent) {
    this.latestLazyLoadFavoriteEvent = event;

    let opportunityTypeId = this.selectedOpportunityTypeFavOption;
    let assignedToId = this.selectedAssignedToFavOption;
    let createdById = this.selectedCreatedByFavOption;

    let params = new HttpParams();

    params = params.set("from", event.first.toString());
    params = params.set("to", event.rows.toString());
    params = params.set("sortBy", event.sortField);
    if(event.sortOrder)
    params = params.set("order", event.sortOrder.toString());
    if(event.globalFilter)
    params = params.set("searchTerm", event.globalFilter);

    if(assignedToId)
    params = params.set("assignedToId", assignedToId);

    if(createdById)
    params = params.set("createdById", createdById);
    
    if(opportunityTypeId) {
    params = params.set("opportunityTypeId", opportunityTypeId);
    }
   
    if(this.selectedContactName) {
    params = params.set("fullName", this.selectedContactNameFav ? this.selectedContactNameFav.trim() : null);
    }
    // params.set("lastName", this.selectedContactFirstName ? this.selectedContactFirstName.trim() : this.selectedContactFirstName);

    // params.set("fullName", this.selectedContactLastName ? this.selectedContactLastName.trim() : this.selectedContactLastName);


    if(this.selectedName) {
    params = params.set("name", this.selectedNameFav ? this.selectedNameFav.trim() : null);
    }
    if(this.selectedDescription) {
    params = params.set("description", this.selectedDescriptionFav ? this.selectedDescriptionFav.trim() : null);
    }

    if (this.selectedProbabilityFavMin) {
      params = params.set("probabilityMin", this.selectedProbabilityFavMin ? this.selectedProbabilityFavMin.trim() : null);
    }
    if (this.selectedProbabilityFavMax) {
      params = params.set("probabilityMax", this.selectedProbabilityFavMax ? this.selectedProbabilityFavMax.trim() : null);
    }

    if(this.selectedValueFav) {
    params = params.set("value", this.selectedValueFav ? this.selectedValueFav.trim() : null);
    }

    if(this.selectedCreatedOnSinceFav) {
    params = params.set("createdOnSince", this.selectedCreatedOnSinceFav ? this.selectedCreatedOnSinceFav : null);
    }
    if(this.selectedCreatedOnBeforeFav) {
    params = params.set("createdOnBefore", this.selectedCreatedOnBeforeFav ? this.selectedCreatedOnBeforeFav : null);
    }

    if(this.queryContact)
    {
      this.contactsService.getContact(this.queryContact).subscribe(
        (contact: Contact) => {
          params = params.set("fullName", contact.fullName);
          this.onGetFavoriteOpportunities(params);
        }
      );
    }
    else
    {
      
      this.onGetFavoriteOpportunities(params);
    }
  }

  populateSearchOptions() {
    let opportunityTypes = this.opportunityTypeService.getOpportunityTypes();
    let users = this.userService.getUsers();
  
    forkJoin( opportunityTypes, users).subscribe(
      ([opportunityTypes, users ]) => {
  
        this.opportunityTypesOptions.push({ label: "All Types", value: null });
        opportunityTypes.map(cT => {
          this.opportunityTypesOptions.push({ label: cT.name, value: cT.id });
        });
        
        this.selectedOpportunityTypeOption = null;

        this.userOptions.push({ label: "All Agents", value: null }); // todo: Unassigned option?
        users.map(u => {
          this.userOptions.push({ label: u.fullName, value: u.id });
        });


        this.selectedAssignedToOption = null;
        this.selectedAssignedToFavOption = null;
        this.selectedCreatedByOption = null;
        this.selectedCreatedByFavOption = null;
      }
    );

  }

  isColVisible(colName: string, isFavorite: boolean = false) {

    if (isFavorite && this.selectedColsFavorite.find(sC => sC.value === colName))
      return true;

    if (isFavorite)
      return false;

    if (this.selectedCols.find(sC => sC.value === colName))
      return true;

    return false;
  }

  onGetOpportunities(params: HttpParams) {
    params = params.set("favorite", "0");
    this.opportunityService.getOpportunitiesSegment(params, false).subscribe(
      (opportunitiesSegment: OpportunitiesSegment) => {
        
        this.opportunities = opportunitiesSegment.opportunities;        

        console.log("this.opportunities ====> ", this.opportunities);
        this.totalRecords = opportunitiesSegment.totalOpportunityCount;

        this.populateContactsOptions(opportunitiesSegment.opportunities);
        //this.populateOpportunityTypesOptions(opportunitiesSegment.opportunities);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunities", error);
      }
    );
  }

  onGetFavoriteOpportunities(params: HttpParams) {
    params = params.set("favorite", "1");
    this.opportunityService.getOpportunitiesSegment(params, false).subscribe(
      (opportunitiesSegment: OpportunitiesSegment) => {
        
        this.favoriteOpportunities = opportunitiesSegment.opportunities;        

        console.log("this.opportunities favorite ====> ",  this.favoriteOpportunities);
        this.totalFavoriteRecords = opportunitiesSegment.totalOpportunityCount;

        //Hide a list
        if (this.favoriteOpportunities.length > 0) {
          this.isFavoriteCollapsed = false;
          this.firstLoad = false;
        } else {
          this.isNotFavoriteCollapsed = false;
          this.firstLoad = false;
        }
        //this.populateContactsOptions(opportunitiesSegment.opportunities);
        //this.populateOpportunityTypesOptions(opportunitiesSegment.opportunities);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunities", error);
      }
    );
  } 

  populateContactsOptions(opportunities: Opportunity[]) {
    // this.contactsOptions = [];
    // this.contactsOptions.push({ label: "All Contacts", value: null });
    // opportunities.map(o => {
    //   // If Opp. has an Assigned Contact and Contact has not already been added
    //   if (o.assignedContact && !this.contactsOptions.find(c => c.value === o.assignedContact.fullName))
    //     this.contactsOptions.push({ label: o.assignedContact.fullName, value: o.assignedContact.fullName });
    // });

    // this.selectedContactOption = null;
  }

  populateOpportunityTypesOptions(opportunities: Opportunity[]) {
    this.opportunityTypesOptions.push({ label: "All Types", value: null });
    opportunities.map(o => {
      if (!this.opportunityTypesOptions.find(oT => oT.value === o.opportunityType.name))
        this.opportunityTypesOptions.push({ label: o.opportunityType.name, value: o.opportunityType.name });
    });
    this.selectedOpportunityTypeOption = null;
    this.selectedOpportunityTypeFavOption = null;
  }

  onDeleteOpportunity(opportunity: Opportunity) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the opportunity ' + opportunity.name + '?',
      accept: () => {
        opportunity.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.opportunityService.deleteOpportunity(opportunity).subscribe(
          (response: Response) => {

            if (opportunity.isFavorite) {
              this.loadFavoriteData(this.latestLazyLoadFavoriteEvent);
            }else {
              this.loadData(this.latestLazyLoadEvent);
            }
           
            this.toastService.createSuccessMessage("Success", "The opportunity " + opportunity.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting opportunity", error);
          }
        );
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for opportunity " + opportunity.name + " has been aborted.");
      }
    });
  }

  onMarkFavorite(opportunity: Opportunity, updateToFavorite: boolean) {
    this.confirmationService.confirm({
      message:  (!updateToFavorite ? 'Remove this Opportunity from Favorite ?' : 'Add this Opportunity to Favorite ?'),
      accept: () => {
        opportunity.isFavorite = updateToFavorite;

        this.opportunityService.markFavorite(opportunity).subscribe(
          (response: Response) => {
            //this.onGetOpportunities();
            this.loadData(this.latestLazyLoadEvent);
            this.loadFavoriteData(this.latestLazyLoadFavoriteEvent);
            this.toastService.createSuccessMessage("Success", "The opportunity " + opportunity.name + " has been updated.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error updating opportunity", error);
          }
        );
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The updated for opportunity " + opportunity.name + " has been aborted.");
      }
    });
  } 

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
