import { Component, OnInit } from '@angular/core';

import { UserService } from "../../shared/services/user.service";
import { AuthService } from "../../auth/auth.service";
import { Contact } from "../../shared/models/contact.model";
import { CrmUser } from "../../shared/models/user.model";

import { SelectItem } from "primeng/primeng";
import { Response } from "@angular/http";
import { AppSettings } from 'app.settings'
import { UtilityService } from 'app/views/shared/services/utility.service';
import { ContactType } from 'app/views/shared/models/contact-type.model';
import { ContactTypeService } from "../../shared/services/contact-type.service";


@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {
  users: CrmUser[] = [];               // - Stores all contacts to populate dropdown options
  usersOptions: SelectItem[] = [];  // - Stores Assign User Dropdown Options
  contactTypesOptions: SelectItem[] = [];

  selectedUserOption: any;          // - Stores the selected dropdown option which can be 'all' or the id of a contact
  selectedContactTypeOption: any;  
  fromDate: Date = null;
  toDate: Date = null;

  crmApiEndPoint: string = AppSettings.API_ENDPOINT;

  // Datepicker
  dateRange: string = (new Date().getFullYear() - 150) + ':' + new Date().getFullYear(); // - Range: 150 Years Ago until Today

  accessToken = this.authService.currUser().access_token;//localStorage.getItem('accessToken');  
  
  
  constructor(
    public authService: AuthService,
    private userService: UserService,
    private utilService: UtilityService,
    private contactTypeService: ContactTypeService
  ) { }

  ngOnInit() {
    this.utilService.setPageTitle('Contacts Report');
    this.contactTypesOptions.push({ label: 'All', value: 'all' });

    if (this.authService.isAdmin())
      this.usersOptions.push({ label: 'All', value: 'all' });

    this.onGetUsers();
    this.onGetContactTypes();
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // Only Admins can get reports about all users
        if (this.authService.isAdmin())
          users.map(u => this.usersOptions.push({ label: u.fullName, value: u.id }));
        else {
          let user = users.find(u => u.id === this.authService.applicationProfileUser().id);
          this.usersOptions.push({ label: user.fullName, value: user.id });
          this.selectedUserOption = user.id;
        }
      }
    );
  }

  onGetContactTypes() {
    this.contactTypeService.getTypeContacts().subscribe(
      (contactTypes: ContactType[]) => {
        // Store retrieved contact types in the contactTypesOptions select list
        contactTypes.map(cT => this.contactTypesOptions.push({ label: cT.name, value: cT.id }));

      },
      (error: Response) => {
        //this.toastService.createErrorMessage("Error retrieving contact types", error);
      }
    );
  }
}
