import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "app.settings";

@Injectable({
  providedIn: 'root'
})
export class AuditService {
  private urlAuditMobile: string = AppSettings.API_ENDPOINT + 'api/Audit/auditViewMobileNumber';
  private urlAuditEmail: string = AppSettings.API_ENDPOINT + 'api/Audit/auditViewEmail';
  private urlAuditEditScreen: string = AppSettings.API_ENDPOINT + 'api/Audit/auditOpenEditScreen';
  private urlAuditAddress: string = AppSettings.API_ENDPOINT + 'api/Audit/auditViewAddress';
  private urlAuditOwners: string = AppSettings.API_ENDPOINT + 'api/Audit/auditViewOwners';
  private urlAuditTaskLocation: string = AppSettings.API_ENDPOINT + 'api/Audit/auditViewTaskLocation';
  private urlAuditTaskContacts: string = AppSettings.API_ENDPOINT + 'api/Audit/auditViewTaskContacts';

  constructor(private http: HttpClient) { }

  async auditViewMobileNumber(contactName: string, contactId: number) {
    this.http.post<any>(this.urlAuditMobile, { contactId: contactId, contactName: contactName }).subscribe();
  }

  async auditViewEmail(contactName: string, contactId: number) {
    this.http.post<any>(this.urlAuditEmail, { contactId: contactId, contactName: contactName }).subscribe();
  }

  async auditOpenEditScreen(contactName: string, contactId: number) {
    this.http.post<any>(this.urlAuditEditScreen, { contactId: contactId, contactName: contactName }).subscribe();
  }

  async auditViewAddress(productPrecode: string, propertyId: number) {
    this.http.post<any>(this.urlAuditAddress, { propertyId: propertyId, productPrecode: productPrecode }).subscribe();
  }

  async auditViewOwners(productPrecode: string, propertyId: number) {
    this.http.post<any>(this.urlAuditOwners, { propertyId: propertyId, productPrecode: productPrecode }).subscribe();
  }

  async auditViewTaskLocation(taskId: number) {
    this.http.post<any>(this.urlAuditTaskLocation, { taskId: taskId }).subscribe();
  }

  async auditViewTaskContacts(taskId: number) {
    this.http.post<any>(this.urlAuditTaskContacts, { taskId: taskId }).subscribe();
  }

}