import { Injectable } from '@angular/core';
import { Response } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { ListAutomation } from "../models/mailchimp/list-automation.model";
//import { User } from "../models/user.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import { ApiEndPoints } from "app.constant";
import { HttpClient } from '@angular/common/http';
import { AppSettings } from 'app.settings';

@Injectable()
export class MailchimpListAutomationService {
  private url: string = AppSettings.API_ENDPOINT + 'api/mailchimplistautomations/';

  public listsChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getListAutomations(): Observable<ListAutomation[]> {
    //this.authService.reauthenticateIfTokenExpired();
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let listAutomations: ListAutomation[] = (<any>response);
        listAutomations = listAutomations.map(
          lA => {
            lA.createdByUserAccountId = this.authService.applicationProfileUser().id
            lA.updatedByUserAccountId = this.authService.applicationProfileUser().id
            return lA;
          }
        );
        return listAutomations;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getListAutomation(id: number): Observable<ListAutomation> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let listAutomation: ListAutomation = (<any>response);
        return listAutomation;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addListAutomation(listAutomation: ListAutomation): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      listAutomation
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateListAutomation(listAutomation: ListAutomation): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + listAutomation.id,
      listAutomation
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteListAutomation(listAutomation: ListAutomation): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + listAutomation.id,
      listAutomation
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}
