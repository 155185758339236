import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { ContactTypeService } from "../../../shared/services/contact-type.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";
import { CommunicationHistoryTypeService } from "../../../shared/services/communication-history-type.service";

import { ContactType } from "../../../shared/models/contact-type.model";

import { valueExistsValidator } from "../../../shared/validators/value-exists.validator";
import { CommunicationLogType } from 'app/views/shared/models/communication-log-type.model';

@Component({
    selector: 'app-contact-logs-modal',
    templateUrl: './communication-log-types-modal.component.html',
    styleUrls: ['./communication-log-types-modal.component.scss']
  })
  export class CommunicationLogTypeModalComponent implements OnInit {
  contactTypeForm: FormGroup;       // - Stores the contactType form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a contactType if true and add a new contactType if false

  contactTypes: ContactType[] = [];             // - Stores all contactTypes retrieved from db
  contactType: ContactType = new ContactType(); // - Stores ContactType to populate form with a new / existing ContactType's values
  communicationLogTypes: CommunicationLogType[] = [];             // - Stores all contactTypes retrieved from db
  communicationLogType: CommunicationLogType = new CommunicationLogType();

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private contactTypeService: ContactTypeService,
    private authService: AuthService,
    private toastService: ToastService,
    private communicationHistoryTypeService: CommunicationHistoryTypeService

  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.communicationHistoryTypeService.getCommunicationLogTypes().subscribe(
      (communicationLogType: CommunicationLogType[]) => {
        this.communicationLogTypes = communicationLogType; // - Store all contact types retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const communicationLogTypeID = params['id'];
            this.isEdit = communicationLogTypeID != null;

            if (this.isEdit) {
              this.communicationLogType = this.communicationLogTypes.find(cType => cType.id === +communicationLogTypeID); // - Store the selected contactType

              // Redirect to 404 page if the selected contactType could not be found
              if (!this.communicationLogType) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact types", error);
      }
    );
  }

  initForm() {
    // Get all contactType names to know if it already exists
    const communicationLogNames = this.communicationLogTypes.map(cType => cType.name);

    this.contactTypeForm = this.formBuilder.group({
      name: this.formBuilder.control(this.communicationLogType.name, [Validators.required, valueExistsValidator(communicationLogNames)])
    });
    this.isFormInit = true;
  }

  onAddCommunicationLogType(newCommunicationLogType: CommunicationLogType) {
    this.communicationHistoryTypeService.addCommunicationLogType(newCommunicationLogType).subscribe(
      (response: Response) => {
        this.communicationHistoryTypeService.commlogtypesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The contact type " + newCommunicationLogType.name + " has been created.");
        this.router.navigate(['/contacts/communication-log-types']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding contact type", error);
      }
    );
  }

  onUpdateCommunicationLogType(newCommunicationLogType: CommunicationLogType) {
    this.communicationHistoryTypeService.updateCommunicationTypeLog(newCommunicationLogType).subscribe(
      (response: Response) => {
        this.communicationHistoryTypeService.commlogtypesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The contact type " + newCommunicationLogType.name + " has been updated.");
        this.router.navigate(['/contacts/communication-log-types']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating contact type", error);
      }
    );
  }

  onSubmit() {
    let newCommunicationLogType = this.formBuilder.sanitizeFormValues(this.contactTypeForm).value;

    if (this.isEdit) {
      newCommunicationLogType.id = this.communicationLogType.id; // - Set id of edited contactType to its original id
      this.onUpdateCommunicationLogType(newCommunicationLogType);
    }
    else {
      newCommunicationLogType.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddCommunicationLogType(newCommunicationLogType);
    }
  }
}
