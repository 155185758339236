import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { AppSettings } from 'app.settings'

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { ContactFile } from "../../shared/models/contact-file.model";
import { Observable } from "rxjs/Observable";

import { Subject } from "rxjs/Rx";
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ContactFileService {
  private url: string = AppSettings.API_ENDPOINT + 'api/contactfiles/';

  public contactFilesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getContactFiles(): Observable<ContactFile[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let contactFiles: ContactFile[] = (<any>response);
        contactFiles = contactFiles.map(
          cF => {
            // cF.createdByUserAccount = this.userService.getUser(cF.createdByUserAccountId);
            // cF.updatedByUserAccount = this.userService.getUser(cF.updatedByUserAccountId);
            return cF;
          }
        );
        return contactFiles;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getContactFilesByContactId(contactId: number): Observable<ContactFile[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "Contact/" + contactId).map(
      (response: Response) => {
        let contactFiles: ContactFile[] = (<any>response);
        contactFiles = contactFiles.map(
          cF => {
            // cF.createdByUserAccount = this.userService.getUser(cF.createdByUserAccountId);
            // cF.updatedByUserAccount = this.userService.getUser(cF.updatedByUserAccountId);
            return cF;
          }
        );
        return contactFiles;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getContactFile(id: number): Observable<ContactFile> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let contactFile: ContactFile = (<any>response);
        // contactFile.createdByUserAccount = this.userService.getUser(contactFile.createdByUserAccountId);
        // contactFile.updatedByUserAccount = this.userService.getUser(contactFile.updatedByUserAccountId);
        return contactFile;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  //** Contact Files are posted through 'DropZone' */
  // addContactFile(contactFile: ContactFile): Observable<Response> {
  //   //this.authService.reauthenticateIfTokenExpired();

  //   this.loadingService.displayLoadingSpinner();
  //   return this.http.post<Response>(
  //     this.url,
  //     contactFile
  //   ).finally(() => this.loadingService.hideLoadingSpinner());
  // }

  updateContactFile(contactFile: ContactFile): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + contactFile.id,
      contactFile
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteContactFile(contactFile: ContactFile): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + contactFile.id,
      contactFile
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  downloadContactFile(contactFile: ContactFile): Observable<any> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "File/" + contactFile.id , { responseType: 'blob'}).map(
      (response: Blob) => {
        let contactFiles = (<any>response);
        return contactFiles;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  
}
