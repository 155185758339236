import { Observable, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ApiService } from 'app/shared/api.service';
import { AuthService } from '../auth/auth.service';
// import { ApiService } from '../api.service';
// import { Profile } from './_models/profile.model';

export class DeveloperConstants {
  public static readonly IS_DEVELOPER_MODE = "__dC7CzvnkTYbBBkfSuNry59wbnBK3e2d9nppVLbJ8NWPm5u9hn6X2f7rTCPaXyRvzTbqQBDnhzqt3tjfc75yVTzAPHdkMZfUxwbtg699GHxysv4CFfsLGSrbQfxSdkbCxVJamJ2YuCRJVcedC4SSuCECrKZMKRcQD2p4YTys7pMEt4b7WtKhvSMvKzgRhGyVm47va9PKZnnuptDukwguccJach9sU8w3nGkBx6xznp3NHjEXkjpFXyT6BZk6XqB6d";
}

@Injectable()
export class DeveloperModeService {

  constructor(
    private _apiService: ApiService
  ) { }

  public enableDeveloperMode(): Observable<any> {
    return this._apiService.get(`api/developer/enable`);
  }

  public disableDeveloperMode(): Observable<any> {
    return this._apiService.get(`api/developer/disable`);
  }
}


@Injectable()
export class DeveloperModeHelper {
  public isDeveloper: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isDeveloperModeEnabled: BehaviorSubject<boolean> = new BehaviorSubject(<boolean><any>localStorage.getItem(DeveloperConstants.IS_DEVELOPER_MODE));

  constructor() {
    // _auth.mgr.events.addUserLoaded((e) => {
    //   if(_auth.currentUser.profile && _auth.currentUser.profile.role) {
    //     
    //     if(_auth.currentUser.profile.role === 'Developer') {
    //       console.log(`Developer Mode: Set to ${true}`);
    //       this.isDeveloper.next(true);
    //     }
    //   }
    // });

    // let permissions = JSON.parse(localStorage.getItem("permissions"));

    // if(permissions) {
    // var foundRole = permissions.find(_=>_ === "Developer");
    // if(foundRole) {
    //   this.isDeveloper.next(true);
    // }
    // }
   }

   next(val: boolean) {
    localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(val)); 
    this.isDeveloperModeEnabled.next(val);
   }
}  