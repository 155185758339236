import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";
import { BaseComponent } from 'app/shared/base.component';

import { AuthService } from "../../../auth/auth.service";
import { OpportunityLostTypeService } from "../../../shared/services/opportunity-lost-type.service";
import { ToastService } from "../../../shared/services/toast.service";
import { ConfirmationService } from "primeng/primeng";

import { OpportunityLostType } from "../../../shared/models/opportunity-lost-type.model";

import { PrepopulatedEntities } from "app.constant";
import { UtilityService } from 'app/views/shared/services/utility.service';

@Component({
  selector: 'app-opportunity-lost-type-list',
  templateUrl: './opportunity-lost-type-list.component.html',
  styleUrls: ['./opportunity-lost-type-list.component.scss']
})
export class OpportunityLostTypeListComponent extends BaseComponent implements OnInit {
  opportunityLostTypes: OpportunityLostType[] = [];
  isAnim: boolean = true;

  constructor(
    public authService: AuthService,
    private opportunityLostTypeService: OpportunityLostTypeService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private utilService: UtilityService
  ) {  super() }

  ngOnInit() {
    this.utilService.setPageTitle('Lost Opportunity Types');
    this.onGetOpportunityLostTypes();

    // Listen to changes made to opportunityLostTypes
    this.subsriptions$.push(this.opportunityLostTypeService.typesChanged.subscribe(
      () => {
        this.onGetOpportunityLostTypes();
      }
    ));
  }

  onGetOpportunityLostTypes() {
    this.opportunityLostTypeService.getOpportunityLostTypes().subscribe(
      (opportunityLostTypes: OpportunityLostType[]) => {
        this.opportunityLostTypes = opportunityLostTypes;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving lost opportunity types", error);
      }
    );
  }

  onDeleteOpportunityLostType(opportunityLostType: OpportunityLostType) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the lost opportunity type ' + opportunityLostType.name + '?',
      accept: () => {
        opportunityLostType.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.opportunityLostTypeService.deleteOpportunityLostType(opportunityLostType).subscribe(
          (response: Response) => {
            this.onGetOpportunityLostTypes();
            this.toastService.createSuccessMessage("Success", "The lost opportunity type " + opportunityLostType.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting lost opportunity type", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for lost opportunity type " + opportunityLostType.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
