import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class UtilityService {

  public constructor(private titleService: Title) {}

  //** Arithmetics - Start */
  //** - Returns the average value of an array of numbers */
  getAvg(numbers: number[]) {
    return numbers.reduce(function (a, b) {
      return a + b;
    }) / numbers.length;
  }

  //** - Returns the percentage of a number value */
  getPercentOfValue(percent: number, value: number) {
    return (percent / 100) * value;
  }
  //** Arithmetics - End */

  //** Misc - Start */
  //** - Returns a random colour value (hex) */
  //** - Obtained from: https://stackoverflow.com/questions/1484506/random-color-generator */
  getRandomColor() {
    let letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    
    return color;
  }
  //** Misc - End */

  //** List - Start */
  sortListByValueName(list: any[], valueToCompareName: string, orderAsc: boolean = true) {
    list = list.sort(
      (obj1, obj2) => {
        if (obj1[valueToCompareName] > obj2[valueToCompareName])
          return orderAsc ? 1 : -1;
        if (obj1[valueToCompareName] < obj2[valueToCompareName])
          return orderAsc ? -1 : 1;

        return 0;
      }
    );

    return list;
  }
  //** List - End */  

  //** Dates - Start */  
  //** - Returns the StartDate and EndDate of the Current Week (A Week starts on Sunday and ends on the following Saturday)
  //** - Obtained from: https://stackoverflow.com/a/44959028
  getWeekDates() {
    let now = new Date();
    let dayOfWeek = now.getDay(); // - 0-6, Sunday is 0, Saturday is 6
    let numDay = now.getDate();

    let start = new Date(now); //copy
    start.setDate(numDay - dayOfWeek);
    start.setHours(0, 0, 0, 0);

    let end = new Date(now); //copy
    end.setDate(numDay + (7 - dayOfWeek));
    end.setHours(0, 0, 0, 0);

    return [start, end];
  }

  isDateInCurrentWeek(date: Date) {
    let [start, end] = this.getWeekDates();
    if (+date >= +start && +date < +end)
      return true;

    return false;
  }

  isDateInCurrentMonth(date: Date) {
    let now = new Date();
    let currentMonth = now.getMonth();
    let currentYear = now.getFullYear();

    // The Date must have the same month and year as the current date's
    if (date.getMonth() === currentMonth && date.getFullYear() === currentYear)
      return true;

    return false;
  }
  //** Dates - End */

  setPageTitle(title: string) {
    this.titleService.setTitle("CRM | " + title);
  }
}
