import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from "@angular/http";

import { CommunicationHistoryService } from "../../../shared/services/communication-history.service";
import { ContactService } from "../../../shared/services/contact.service";

import { Contact } from "../../../shared/models/contact.model";
import { TenantAppSettingService } from 'app/views/auth/tenant-app-setting.service';
import { AuditService } from 'app/views/shared/services/audit.service';

@Component({
  selector: 'app-communication-history-detail',
  templateUrl: './communication-history-detail.component.html',
  styleUrls: ['./communication-history-detail.component.scss']
})
export class CommunicationHistoryDetailComponent implements OnInit {
  contact: Contact = null;
  isNumberVisible: boolean = false;
  isEmailVisible: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appSetting: TenantAppSettingService,
    private auditService: AuditService,
    private contactService: ContactService
  ) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        const contactId = params['contactId'];

        if (contactId != 'all') {
          this.contactService.getContact(contactId).subscribe(
            (contact: Contact) => {
              this.contact = contact;
            },
            (error: Response) => {
              this.router.navigate(['/error/404']);
            }
          );
        }
        else
          this.contact = null;
      }
    );
  }

  displayMobileNumber(contactName, contactId): void {
    this.isNumberVisible = true;
    this.auditService.auditViewMobileNumber(contactName, contactId);
  }

  displayEmail(contactName, contactId): void {
    this.isEmailVisible = true;
    this.auditService.auditViewEmail(contactName, contactId);
  }
}
