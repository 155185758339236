import { Component, OnInit } from '@angular/core';
import { Response } from "@angular/http";
import { BaseComponent } from 'app/shared/base.component';

import { AuthService } from "../../auth/auth.service";
import { ContactTypeService } from "../../shared/services/contact-type.service";
import { ToastService } from "../../shared/services/toast.service";
import { ConfirmationService } from "primeng/primeng";
import { CommunicationHistoryTypeService } from "../../shared/services/communication-history-type.service";

import { CommunicationLogType } from  "../../shared/models/communication-log-type.model";
import { CommunicationLogTypeNames, PrepopulatedEntities } from "app.constant";
import { UtilityService } from 'app/views/shared/services/utility.service';

@Component({
    selector: 'app-contact-logs',
    templateUrl: './communication-log-types.component.html',
    styleUrls: ['./communication-log-types.component.scss']
  })
  export class CommunicationLogTypeComponent extends BaseComponent implements OnInit {
  logTypesNotFromWebsite: string[] = CommunicationLogTypeNames.LogTypesNotFromWebsite;

  communicationLogTypesOptions: CommunicationLogType[] = [];
  isAnim: boolean = true;

  systemCreatedById = PrepopulatedEntities.SystemContactTypeCreatedById; // - Id of System used to check if contact type was prepopulated (Added by system)

  constructor(
    public authService: AuthService,
    private contactTypeService: ContactTypeService,
    private communicationHistoryTypeService: CommunicationHistoryTypeService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private utilService: UtilityService,
  ) { super()}

  ngOnInit() {
    this.utilService.setPageTitle('Communication Log Types');
    this.onGetCommunicationLogTypes();

    this.subsriptions$.push(this.communicationHistoryTypeService.commlogtypesChanged.subscribe(
      () => {
        this.onGetCommunicationLogTypes();
      }
    ));

  }

  // METHOD -> Gets Communication Log Types by referencing CommHistoryService.
  onGetCommunicationLogTypes() {
    this.communicationHistoryTypeService.getCommunicationLogTypes().subscribe(
      (communicationLogTypes: CommunicationLogType[]) => {
        this.communicationLogTypesOptions = [];

        // Store retrieved communicationLogTypes in the communicationLogTypesOptions select list
        communicationLogTypes.map(lT => this.communicationLogTypesOptions.push(lT));
          // Only have 'Comm Logs created from Website' as an option if the current log is of that kind          
          // this.communicationLogTypesOptions = this.communicationLogTypesOptions
          //   .filter(cT => this.logTypesNotFromWebsite.includes(cT.label));
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication log types", error);
      }
    );
  }

  // METHOD -> Asks the user if they want to delete first. Deletes by referencing CommHistoryService.
  onDeletecommunicationLogTypes(newCommunicationLogType: CommunicationLogType) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the communication log type ' + newCommunicationLogType.name + '?',
      accept: () => {
        this.communicationHistoryTypeService.deleteCommunicationLogType(newCommunicationLogType).subscribe(
          (response: Response) => {
            this.onGetCommunicationLogTypes();
            this.toastService.createSuccessMessage("Success", "The communication log type " + newCommunicationLogType.name + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting communication log type", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for communication log type " + newCommunicationLogType.name + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
