//import { User } from "../user.model";
import { ContactType } from "../contact-type.model";
import { ContactSource } from "../contact-source.model";
import { CrmUser } from "../user.model";

export class ListAutomation {
    constructor(
        public id: number = 0,

        public contactType: ContactType = new ContactType(),
        public contactTypeId: number = null,
        public contactSource: ContactSource = new ContactSource(),
        public contactSourceId: number = null,
        public mailchimpListId: string = null,

        // public createdByUser: User = null,
        // public createdByUserId: number= null,
        // public createdOn: string = null,
        // public updatedByUser: User = null,
        // public updatedByUserId: number = null,
        public updatedOn: string = null,
        public status: string = null,

        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number = null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
    ) { }
}