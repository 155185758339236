import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from "@angular/router";

import { LabelEmptyFieldPipe } from "./pipes/label-empty-field.pipe";
import { ConfirmDialogModule, GrowlModule, TooltipModule, DataTableModule, TabViewModule, SpinnerModule, MultiSelectModule, AutoCompleteModule, CalendarModule, Calendar } from "primeng/primeng";
import { TaskListComponent } from "../tasks/task-list/task-list.component";
import { TaskStatusPipe, TaskPriorityPipe } from "../shared/pipes/task-status.pipe";
import { AssignedContactsPipe } from "../shared/pipes/assigned-contacts.pipe";
import { AssignedUsersPipe } from "../shared/pipes/assigned-users.pipe";
import { FormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    TaskListComponent,
    LabelEmptyFieldPipe,
    TaskStatusPipe,
    TaskPriorityPipe,
    AssignedContactsPipe,
    AssignedUsersPipe
  ],
  imports: [
    CommonModule,
    RouterModule, // - Imported because issues with routerLink not being recognized when inserted task-list component within dashboard
    FormsModule,
    ConfirmDialogModule,
    GrowlModule,
    TooltipModule,
    DataTableModule,
    TabViewModule,
    SpinnerModule,
    CalendarModule,
    MultiSelectModule,
    NgxPermissionsModule
  ],
  exports: [
    CommonModule,
    TaskListComponent,
    TaskStatusPipe,
    TaskPriorityPipe,
    AssignedContactsPipe,
    AssignedUsersPipe,
    LabelEmptyFieldPipe,
    FormsModule,
    ConfirmDialogModule,
    GrowlModule,
    TooltipModule,
    DataTableModule,
    TabViewModule,
    SpinnerModule,
    MultiSelectModule,
    AutoCompleteModule,    
    NgxPermissionsModule
  ],
  providers: [
  ]
})
export class SharedModule { }
