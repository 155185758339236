import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BaseComponent } from 'app/shared/base.component';

import { AuthService } from "../../auth/auth.service";
import { TaskService } from "../../shared/services/task.service";
import { ConfirmationService, SelectItem } from "primeng/components/common/api";
import { ToastService } from "../../shared/services/toast.service";
import { TableUtilityService } from '../../shared/services/table-utility.service';
import { CookieService } from 'ngx-cookie';
import {TasksSegment } from '../../shared/models/tasks-segment.model';

import {Calendar } from "primeng/primeng";

import { LazyLoadEvent, DataTable } from "primeng/primeng";

import { HttpParams } from '@angular/common/http';

import { SqlTask,Task } from "../../shared/models/task.model";
import { CrmUser } from "../../shared/models/user.model";

import { Subject } from "rxjs/Rx";
import { UtilityService } from 'app/views/shared/services/utility.service';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { AuditService } from 'app/views/shared/services/audit.service';
import { TenantAppSettingService } from 'app/views/auth/tenant-app-setting.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent extends BaseComponent implements OnInit {

  @ViewChild('dt') dt: DataTable;

  tasks: SqlTask[] = [];     // - List of tasks displayed in table
  allTasks: Task[] = [];  // - All tasks retrieved from db

  showPendingTasks: boolean = true; // - Completed tasks are shown instead when set to false
  isDashboard: boolean = this.router.url === '/dashboard'; // - Need to know if Dashboard to only show todays tasks

  contactId: string = '';

  isMyList: boolean = false;

  /** Column Toggling */
  columnOptions: SelectItem[];
  selectedCols: any[];              // - Contains the table columns that should be visible
  allSelectableCols: any[] = [];    // - Contains all table columns that may be toggled on or off

  selectedColsDefault: any[] = [];  // - Contains the columns that are shown by default if no cookie is stored

  public fromDate: Date = new Date()
  public toDate: Date = new Date()

  dateRange: string = (new Date().getFullYear() - 10) + ':' + (new Date().getFullYear() + 5); 

  totalRecords: number = 0;

  latestLazyLoadEvent: LazyLoadEvent;

  isAddressVisible: boolean[] = [];
  areContactsVisible: boolean[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public authService: AuthService,
    private taskService: TaskService,
    private appSetting: TenantAppSettingService,  // used on HTML
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private cookieService: CookieService,    
    private tableUtilService: TableUtilityService,
    private utilService: UtilityService,
    private auditService: AuditService
  ) { 
    super();
    this.utilService.setPageTitle('Task List');
  }

  ngOnInit() {
    //this.onGetTasks();
    this.initColumnOptions();

    this.route.queryParams.subscribe(
      (params: Params) => {
        const contactId = params['contactId'];
        if(contactId != undefined)
        {       
          this.contactId = contactId
        }       
        
      }
    );

    this.fromDate.setMonth(this.fromDate.getMonth()-6);
    this.toDate.setMonth(this.toDate.getMonth()+6);

    if (this.router.url.toLowerCase().indexOf('/mylist') > -1) {
      this.isMyList = true;
    }

    //this.loadTasks(this.latestLazyLoadEvent);

    // Listen to changes made to tasks
/*     this.subsriptions$.push(this.taskService.tasksChanged.subscribe(
      () => {
        this.loadTasks(this.latestLazyLoadEvent);
      }
    )); */
    //appSetting.isHideSensitiveInfo = this.appSettingService.getBoolSetting('HideSensitiveInfo');
  }

  initColumnOptions() {
    this.allSelectableCols = this.tableUtilService.getAllSelectableTaskCols();
    this.selectedColsDefault = this.tableUtilService.getSelectedTaskColsDefault();
    this.columnOptions = this.tableUtilService.getColumnOptions(this.columnOptions, this.allSelectableCols);

    let selectedColsCookie = this.cookieService.getObject("crm_selectedTaskCols") as any[];
    if (selectedColsCookie)
      this.selectedCols = selectedColsCookie;
    else
      this.selectedCols = this.selectedColsDefault;
  }

  // - Saves options in a cookie whenever they are changed
  onColumnOptionsChange() {
    this.cookieService.putObject("crm_selectedTaskCols", this.selectedCols);
  }

  isColVisible(colName: string) {
    if (this.selectedCols.find(sC => sC.value === colName))
      return true;

    return false;
  }

  onGetTasks(params: HttpParams) {
    

    params = params.set("showPending",this.showPendingTasks ? "1":"0");
    params = params.set("isDashBoard", this.isDashboard ? "1":"0");
    params = params.set("isMyList",this.isMyList ? "1":"0");
    this.taskService.getOpportunitiesSegment(params).subscribe(
      (tasksSegment: TasksSegment) => {

        

        this.tasks = tasksSegment.tasks;      

        this.totalRecords = tasksSegment.totalTasksCount;

        //this.populateOpportunityTypesOptions(opportunitiesSegment.opportunities);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunities", error);
      }
    );
  }

  loadTasks(event: LazyLoadEvent) {

    

    let params = new HttpParams();

    if (event != null) {
      this.latestLazyLoadEvent = event;
      params = params.set("from", event.first.toString());
      params = params.set("to", event.rows.toString());
      params = params.set("sortBy", event.sortField);

      if (this.contactId != '') {
        params = params.set("contactId", this.contactId);
      }

      if(event.sortOrder)
        params = params.set("order", event.sortOrder.toString());
      if(event.globalFilter)
        params = params.set("searchTerm",event.globalFilter);// encodeURIComponent(event.globalFilter));
    }
    params = params.set("fromDate", this.fromDate.toLocaleDateString("en-GB"));
    let newToDate = new Date(this.toDate);
    newToDate.setDate(newToDate.getDate() + 1);
    params = params.set("toDate", newToDate.toLocaleDateString("en-GB"));
 
    this.onGetTasks(params);
  }


  onAskForCommunicationLog(task: SqlTask) {
    let firstContactId = (task as any).firstContactId;
    let firstContactName = task.contactTaskNames.split(',')[0];

    this.confirmationService.confirm({
      header: 'Communication Log',
      message: 'The task has been marked as complete. Would you now like to create a new communication log for ' + firstContactName + '?',
      accept: () => {
        this.router.navigate(['/contacts', firstContactId, 'communication-history', 'new']);
      }
    });
  }

  onCompleteTask(task: SqlTask) {
    //let completedTask = this.allTasks.find(t => t.id === task.id);
    //task.updatedByUserAccountId = this.authService.applicationProfileUser().id; // Actual task
    var firstContactId = task.firstContactId

    this.taskService.toggleTaskCompletion(task).subscribe(
      (response: Response) => {
        // NOTE: The update is made in the API!!!
        // Here I am setting the task to complete because the update was successful, so we want the change to be shown to the user
        this.loadTasks(this.latestLazyLoadEvent);

        // Only ask for new comm. log if task is complete and is assigned to at least one contact
        if ( firstContactId != null )
          task.firstContactId = firstContactId;
          this.onAskForCommunicationLog(task);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error completing task", error);
      }
    );
  }

  onDeleteTask(task: Task) {
    this.confirmationService.confirm({
      header: 'Delete Task',
      message: 'Are you sure you want to delete this task?',
      accept: () => {
        task.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.taskService.deleteTask(task).subscribe(
          (response: Response) => {
            this.loadTasks(this.latestLazyLoadEvent);
            // this.taskService.tasksChanged.next();            
            this.taskService.taskCounterChanged.next();
            this.toastService.createSuccessMessage("Success", "The task has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting task", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for the task has been aborted.");
      }
    });
  }


  onChangeTasksTabView() {
    this.showPendingTasks = !this.showPendingTasks;
    this.dt.reset();
    //this.loadTasks(this.latestLazyLoadEvent);
  }

  onDataChange() {
    const sortBy = this.latestLazyLoadEvent.sortField +'';
    this.dt.reset();
    this.latestLazyLoadEvent.sortField = sortBy;
    //this.loadTasks(this.latestLazyLoadEvent);
  }


  rowStyle(task: Task, rowIndex: number) {
    if (task.isTaskComplete)
      return "bg-success";

    // Is Task Late?
    let reminderDate1 = new Date(task.reminderDate);
    let now = new Date();

    // If the task is not completed and the reminder date has already passed
    if (!task.isTaskComplete && now > reminderDate1)
      return "bg-danger";

    // Is Task For Today?
    let reminderDate = new Date(task.reminderDate).setHours(0, 0, 0, 0);
    let todayDate = new Date().setHours(0, 0, 0, 0);

    let reminderDateTime = new Date(task.reminderDate);
    let todayDateTime = new Date();

    // If the task is not completed and the reminder date is set for today (but time has not yet passed)
    if (!task.isTaskComplete && (todayDate === reminderDate && todayDateTime <= reminderDateTime))
      return "bg-warning";

    return "";
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }

  displayLocation(index: number, taskId: number){
    this.isAddressVisible[index] = true;
    this.auditService.auditViewTaskLocation(taskId);
  }

  displayContacts(index: number, taskId: number){
    this.areContactsVisible[index] = true;
    this.auditService.auditViewTaskContacts(taskId);
  }
}
