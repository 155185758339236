import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from "@angular/http";
import 'jquery-slimscroll';

import { AuthService } from "../../../views/auth/auth.service";
import { TaskService } from "../../../views/shared/services/task.service";
import { ToastService } from "../../../views/shared/services/toast.service";

import { CrmUser } from "../../../views/shared/models/user.model";
import { Task } from "../../../views/shared/models/task.model";
import { PrepopulatedContactTypes } from "app.constant";
import { DeveloperModeHelper } from 'app/views/shared/developer.mode.service';
import { environment } from 'environments/environment';
import { AppSettings } from 'app.settings';

import {  Subscription } from "rxjs/Rx";

declare var jQuery: any;

@Component({
  selector: 'navigation',
  templateUrl: 'navigation.template.html'
})

export class NavigationComponent implements OnDestroy {
  user: any;
  incompleteTasksCount = 0; // - Number of incomplete tasks (exlcuding today's and late tasks) (Blue)
  todaysTasksCount = 0;     // - Today's incomplete tasks (Yellow)
  lateTasksCount = 0;       // - Tasks that passed their scheduled date and still incomplete (Red)

  leadTypeName = PrepopulatedContactTypes.Lead;

  showDeveloperLabel = false;
  showDeveloperModeLabel = false;
  isDeveloper$ = null;
  developerMode$ = null;
  reapUri = 'localhost';

  isEpic = AppSettings.IS_EPIC;

  subsriptions$ : Subscription[] = [];

  constructor(
    public authService: AuthService,
    public taskService: TaskService,
    private toastService: ToastService,
    private developer: DeveloperModeHelper,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isDeveloper$ = this.developer.isDeveloper.subscribe((isDeveloper) => {
    this.showDeveloperLabel = isDeveloper;
    this.reapUri = environment.REAP_URI;
});

this.developerMode$ = this.developer.isDeveloperModeEnabled.subscribe((isDeveloperModeEnabled) => {
  this.showDeveloperModeLabel = isDeveloperModeEnabled;
});

var authenticateUser = this.authService.currUser();//currentUser;
    this.user = authenticateUser.profile;

    //this.user = this.authService.applicationProfileUser() as CrmUser;
    this.onGetIncompleteTasksCount();

    // Listen to changes made to tasks
    this.subsriptions$.push(this.taskService.taskCounterChanged.subscribe(
      () => {
        this.onGetIncompleteTasksCount();
      }
    ));
  }
  ngOnDestroy() : void {
    if(this.isDeveloper$) {
      this.isDeveloper$.unsubscribe();
    }

    this.subsriptions$.map(elem => {
      if(elem) {
        elem.unsubscribe();
      }
    })
  }

  onGetIncompleteTasksCount() {

    var userId = this.authService.isAdmin() ? 0 : this.authService.applicationProfileUser().id;
    this.taskService.getIncompleteTasksInfo(userId).subscribe(
      (result: any) => {
        this.incompleteTasksCount = result.otherIncomplete;
        this.lateTasksCount = result.lateTasks;
        this.todaysTasksCount = result.todayTasks;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving tasks", error);
      }
    );
  }


  onLogOut() {
    this.authService.logOut();
  }

  ngAfterViewInit() {
    jQuery('#side-menu').metisMenu();

    if (jQuery("body").hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%'
      })
    }
  }

  activeRoute(routename: string): boolean {
    return this.router.url.indexOf(routename) > -1;
  }
}
