import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response } from "@angular/http";
import { Router, ActivatedRoute } from "@angular/router";

import { UserService } from "../shared/services/user.service";
import { LoadingSpinnerService } from "../shared/services/loading-spinner.service";

import { tokenNotExpired, AuthHttp, JwtHelper } from "angular2-jwt/angular2-jwt";

import { AppSettings } from 'app.settings'
import { Observable } from "rxjs/Rx";
import { tap, map } from 'rxjs/operators';

import { LoginModel } from "../shared/models/login.model";
import { RegisterModel } from "../shared/models/register.model";
import { ResetPasswordModel } from '../shared/models/reset-password.model';
import { ForgotPasswordModel } from '../shared/models/forgot-password.model';

import { UserManager, User } from 'oidc-client';
import { CrmUser } from '../shared/models/user.model';
import { HttpClient } from '@angular/common/http';
import { Profile } from 'app/shared/profile/_models/profile.model';
import { DeveloperConstants } from '../shared/developer.mode.service';
import { ProfileService } from 'app/shared/profile/profile.service';
import { createUnparsedSourceFile } from 'typescript';
import { nextTick } from 'process';
import { ToastService } from '../shared/services/toast.service';

@Injectable()
export class AuthService {
  private loginUrl: string = AppSettings.API_ENDPOINT + 'login';  
  private accountUrl: string = AppSettings.API_ENDPOINT + 'api/account';

  private user: User;

  // constructor(
  //   // private authHttp: AuthHttp, // Keep here to retrieve token from cookie before auth-guard checks if token is expired
  //   private http: HttpClient,
  //   private router: Router,
  //   private userService: UserService,
  //   private loadingService: LoadingSpinnerService
  // ) { }

  mgr: UserManager = new UserManager(settings);
  userLoadededEvent: EventEmitter<User> = new EventEmitter<User>();
  currentUser: User;
  appProf: Profile;
  loggedIn = false;

  // authHeaders: Headers;

  private loginRequest: Observable<boolean>;

  constructor(private http: HttpClient, 
    private route: ActivatedRoute,
    private router: Router, 
    private profileService: ProfileService,
    private toastService: ToastService,
    private loadingService: LoadingSpinnerService,
    // private _cookieService: CookieService
    ) {
    // 
    this.mgr.events.addUserUnloaded(() => {
      console.log('user unloaded');
      this.loggedIn = false;
    });

    this.mgr.events.addAccessTokenExpiring(function () {
      console.log("Access token expiring...");
    });
    this.mgr.events.addSilentRenewError(function (err) {
        console.log("Silent renew error: " + err.message);
    });
  }

  public currUser() {
    if(this.currentUser && this.currentUser.expired) {
      // 
        this.router.navigate(['unauthorized']);

      //this.router.navigate(['unauthorized']);
      return null;
    }
    return this.currentUser;

  }

  public applicationProfileUser() {
    if(this.appProf && this.appProf.id !== 0) {
      return this.appProf;
    } else {
      this.appProf = JSON.parse(localStorage.getItem('currentUser')) as Profile;
      if(this.appProf && this.appProf.id !== 0) {
        return this.appProf;
      }
    }
    return new Profile();
  }


  public setApplicationProfileUser(profile:Profile) {
    // 
    if(profile && profile.id !== 0) {
      this.appProf = profile;

      localStorage.setItem('currentUser', JSON.stringify(profile));
    }
  }
  
  // TODO: Needs to be looked at
  isUserLoggedIn(): Observable<boolean> {
    const self = this;
    // 
    // if (this.loginRequest) {
    //   return this.loginRequest;
    // }

    this.loginRequest = new Observable<boolean>(function (observer) {
      // const cookServ = self._cookieService;

      self.mgr.getUser()
        .then((user) => {
          // 
          if(user && user.expired) {

           self.router.navigate(['unauthorized']);            
            observer.next(false);
            observer.complete();
          }
          if (user) {
            self.loggedIn = true;
            
            self.currentUser = <User>user;
            self.userLoadededEvent.emit(user);
            
            observer.next(true);
            observer.complete();
          } else {
            self.loggedIn = false;
            
            self.router.navigate(['unauthorized']);
            observer.next(false);
            observer.complete();
          }
        })
        .catch((err) => {
          self.loggedIn = false;
          observer.error(false);
          observer.complete();
        });
    }).publishLast()
      .refCount();

    return this.loginRequest;
  }
  clearState() {
    this.mgr.clearStaleState().then(function () {
      console.log('clearStateState success');
    }).catch(function (e) {
      console.log('clearStateState error', e.message);
    });
  }

  getUser() {
    return this.currUser();
    //return new CrmUser();
    // this.mgr.getUser().then((user) => {
    //   console.log('got user', user);
    //   this.userLoadededEvent.emit(user);
    // }).catch(function (err) {
    //   console.log(err);
    // });
  }

  removeUser() {
    this.mgr.removeUser().then(() => {
      this.userLoadededEvent.emit(null);
      console.log('user removed');
    }).catch(function (err) {
      console.log(err);
    });
  }

  startSigninMainWindow() {
    // 
    this.mgr.signinRedirect({ data: 'some data' }).then(function () {
      console.log('signinRedirect done');
    }).catch(function (err) {
      console.log(err);
    });
  }
  endSigninMainWindow() {
    this.mgr.signinRedirectCallback().then(function (user) {
      console.log('signed in', user);
    }).catch(function (err) {
      console.log(err);
    });
  }

  startSignoutMainWindow() {
    this.mgr.signoutRedirect({logoutId: this.currUser().id_token}).then(function (resp) {
      console.log('signed out', resp);
      setTimeout(() => {
        console.log('testing to see if fired...');

      }, 5000);
    }).catch(function (err) {
      console.log(err);
    });
  };

  endSignoutMainWindow() {
    this.mgr.signoutRedirectCallback().then(function (resp) {
      console.log('signed out', resp);
    }).catch(function (err) {
      console.log(err);
    });
  };

  // getUser() {
  //   if (!this.user) {
  //     const jwtHelper: JwtHelper = new JwtHelper();
  //     let accessToken = localStorage.getItem('accessToken');
  //     let decodedToken = jwtHelper.decodeToken(accessToken);
  //     let userId = decodedToken["sub"];
  //     this.user = this.userService.getUserByApplicationUserId(userId);
  //   }
  //   return this.user;
  // }

  isAdmin() {
    var foundRole =  (this.applicationProfileUser().appUserRoles.find(e => { 
      if(e.appRole) {
        return (e.appRole.name === ("Admin") || e.appRole.name === ("Super Admin"));
    }
  }));
  if(foundRole) {return true};
  
  }

  isProfileAdmin() {
    var foundRole =  (this.applicationProfileUser().appUserRoles.find(e => { 
      if(e.appRole) {
        return (e.appRole.name === ("Admin") || e.appRole.name === ("Super Admin"));
    }
  }));
  if(foundRole) {return true};
  
  }

  isProfileHomeAccess() {
    var foundRole =  (this.applicationProfileUser().appUserRoles.find(e => { 
      if(e.appRole) {
        return e.appRole.name === ("HomeAccess");
    }
  }));
  if(foundRole) {return true};
  
  }

  isHomeAccessAllow(): Observable<boolean> {
    var foundRole =  (this.applicationProfileUser().appUserRoles.find(e => { 
      if(e.appRole) {
        return (e.appRole.name === ("HomeAccess") || e.appRole.name === ("Admin") || e.appRole.name === ("Super Admin"));
      }
    }));
    if(foundRole) {return Observable.of(true)};

    var accessHomeCheckStatus = localStorage.getItem("ahcs");

    if (accessHomeCheckStatus != null && accessHomeCheckStatus != '') {

      // if Not Admin and Not HomeAccess and IP was checked less then 5 min ago just return the last value

      var lastValue = accessHomeCheckStatus.substring(0,1);
      var time = parseInt(accessHomeCheckStatus.slice(1)) + 300;
      if (time > Math.round((new Date()).getTime() / 1000)) {
        if (lastValue == '0') { 
          this.toastService.createWarningMessage("Not Allowed","This section is available only when connected from the Office");
        }
        return Observable.of(lastValue == '1');
      }
    } 

    return this.profileService.checkOfficeIP().pipe(map(x => {
      if (!x) { 
        this.toastService.createWarningMessage("Not Allowed","This section is available only when connected from the Office");
      }
      localStorage.setItem("ahcs", (x ? '1':'0') + Math.round((new Date()).getTime() / 1000));
      return !!x;

    }))

  
  }

  isSuperAdmin() {
    var foundRole =  (this.applicationProfileUser().appUserRoles.find(e => { 
      if(e.appRole) {
        return e.appRole.name === ("Super Admin");
    }
  }));

  if(foundRole) {return true};
  
  }

  logOut(returnUrl: string = null) {
    //this.user = null; // - Important to clear user as auth guard will check if the user is still stored
    //localStorage.removeItem('accessToken');
    // this.router.navigate(["/login"], { queryParams: { returnUrl: returnUrl }});

    var args = {id_token_hint: this.currUser().id_token};
    // {post_logout_redirect_uri: "http://192.168.10.117:4200"}
    this.mgr.signoutRedirect().then(onfullfilled => {
      var onf = onfullfilled;
      localStorage.remoteItem(DeveloperConstants.IS_DEVELOPER_MODE);
      localStorage.remoteItem("permissions");
      // 
    });

    // this.mgr.signoutPopup({post_logout_redirect_uri: "http://192.168.10.117:4200"}).then(onfullfilled => {
    //     var onf = onfullfilled;
    //     
    //   });
    
    // this.mgr.signoutRedirectCallback("http://192.168.10.117:4200")
    // .then(onfullfilled => {
    //   var onf = onfullfilled;
    //   
    // });
    
    
    
  }

  isTokenValid() {
    try {
      return tokenNotExpired('accessToken');
    }
    catch (error) {
      // Error is thrown if token is empty or not a correctly formatted token
      return false;
    }
  }

  onLogin(loginModel: LoginModel): Observable<boolean> {
    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(this.accountUrl, loginModel).map(
      (response: Response) => {
        let tokenResponse = (<any>response);

        if (tokenResponse) {
          localStorage.setItem('accessToken', tokenResponse.accessToken);
          return true;
        }
        else
          return false;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  // reauthenticateIfTokenExpired() {
  //   if (!this.isTokenValid())
  //     this.logOut();
  // }

  registerUser(registerModel: RegisterModel) {
    return this.http.post<Response>(
      this.accountUrl + "/register",
      registerModel
    );
  }

  forgotPassword(forgotPasswordModel: ForgotPasswordModel) {
    return this.http.post<Response>(
      this.accountUrl + "/ForgotPassword",
      forgotPasswordModel
    );
  }

  
  resetPassword(code: string) {
    return this.http.get(this.accountUrl + '/resetPassword?code=' + code).map(
      (response: Response) => {
        let resetModel: ResetPasswordModel = (<any>response);        
        return resetModel;
      }
    );
  }

  resetPasswordConfirm(resetPasswordModel: ResetPasswordModel) {
    return this.http.post<Response>(
      this.accountUrl + "/ResetPasswordConfirm",
      resetPasswordModel
    );
  }

}


const settings: any = {
  authority: AppSettings.AUTHORITY_ENDPOINT,
  client_id: 'crmweb',
  redirect_uri: AppSettings.REDIRECT_URI,
  post_logout_redirect_uri: AppSettings.POST_LOGOUT_REDIRECT_URI,
  response_type: 'id_token token',
  scope: "openid profile crmapi.web app",

  // silent renew will get a new access_token via an iframe 
    // just prior to the old access_token expiring (60 seconds prior)
    silent_redirect_uri: AppSettings.SILENT_REDIRECT_URI,
    automaticSilentRenew: true,
  // silent_redirect_uri: AppSettings.REDIRECT_URI,
  // silent_redirect_uri: 'http://localhost:4200',

  // silentRequestTimeout:10000,
  filterProtocolClaims: true,
  loadUserInfo: true
};