import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";

import { OpportunitiesRoutingModule } from "./opportunities-routing.module";

import { SharedModule } from "../shared/shared.module";
import { AutoCompleteModule, CalendarModule, DropdownModule, SliderModule } from "primeng/primeng";
import { OpportunityFilesModule } from "./opportunity-files/opportunity-files.module";

import { OpportunityLostTypesModule } from './opportunity-lost-types/opportunity-lost-types.module';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { OpportunitiesComponent } from './opportunities.component';
import { OpportunityListComponent } from './opportunity-list/opportunity-list.component';
import { OpportunityManageComponent } from './opportunity-manage/opportunity-manage.component';
import { OpportnunitiyContactManageComponent } from './opportunity-manage/opportnunitiy-contact-manage/opportnunitiy-contact-manage.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OpportunityBoardComponent } from './opportunity-board/opportunity-board.component';
@NgModule({
  declarations: [
    OpportunitiesComponent,
    OpportunityListComponent,
    OpportunityManageComponent,
    OpportunityBoardComponent,
    OpportnunitiyContactManageComponent
  ],
  imports: [
    SharedModule,
    OpportunitiesRoutingModule,
    OpportunityFilesModule,
    OpportunityLostTypesModule,
    ReactiveFormsModule,
    AutoCompleteModule,
    DropdownModule,
    SliderModule,
    CollapseModule,
    CalendarModule,
    ModalModule.forRoot()
  ]
})
export class OpportunitiesModule { }
