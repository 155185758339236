import { Component, OnInit, ViewChild,ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';
import {Location} from '@angular/common';

import { AuditManager, PrepopulatedContactSources, PrepopulatedTypesContact } from "app.constant";

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { ContactService } from "../../../shared/services/contact.service";
import { ContactTypeService } from "../../../shared/services/contact-type.service";
import { ContactSourceService } from "../../../shared/services/contact-source.service";
import { OrganizationService } from "../../../shared/services/organization.service";
import { AuthService } from "../../../auth/auth.service";
import { ToastService } from "../../../shared/services/toast.service";
import { UserService } from "../../../shared/services/user.service";

import { Contact } from "../../../shared/models/contact.model";
import { ContactType } from "../../../shared/models/contact-type.model";
import { ContactSource } from "../../../shared/models/contact-source.model";
import { CrmUser } from "../../../shared/models/user.model";
import { Tag } from '../../../shared/models/tag.model';

// import { valueExistsValidator } from "../../shared/validators/value-exists.validator";
import { SelectItem, ConfirmationService, AutoComplete, DropdownModule } from "primeng/primeng";
import { CallInfo } from 'app/views/shared/models/lead-desk/call-info.model';
import { Property } from 'app/views/shared/models/property.model';
import { combineLatest } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { ContactSearchItem } from 'app/views/shared/models/contact-search-item.model';
import { UtilityService } from 'app/views/shared/services/utility.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-task-contact-manage',
  templateUrl: './task-contact-manage.component.html',
  styleUrls: ['./task-contact-manage.component.scss']
})
export class TaskContactManageComponent implements OnInit {
  contactForm: FormGroup;                         // - Stores the contact form's values
  isFormInit: boolean = false;                    // - The form is not created until it is initialised
  isEdit: boolean = false;                        // - The form is set to update a contact if true and add a new contact if false
  organizationIdFromClientCreate:number = 0;          // - Organization Id from Add Organization (needed to pre select dropdown)
  urlTokens: string[];                          

  contacts: Contact[] = [];                       // - Stores all contacts retrieved from db to validate unique values
  contact: Contact = new Contact();               // - Stores Contact to populate form with a new / existing contact's values

  contactTypesOptions: SelectItem[] = [];         // - Stores Contact Type Dropdown Options
  contactSourcesOptions: SelectItem[] = [];       // - Stores Contact Source Dropdown Options
  contactOrganizationsOptions: SelectItem[] = []; // - Stores Contact Organization Dropdown Options
  referredByContactsOptions: SelectItem[] = [];   // - Stores Contacts Options to select who was the Referrer if Contact Source is set as Referrer
  assignedUserOptions: SelectItem[] = [];         // - Stores Users to select the Assigned User of this contact
  Properties: Property[] = [];
  allowSave: boolean = false;    
  isModalShown: boolean = false;
  
  private fragment: string;

  @ViewChild('contactReferrersAutoComplete') private contactReferrersAutoComplete: AutoComplete;
  allTags: Tag[] = [];                            // - Stores all pre-existing Contact Tags
  filteredTags: Tag[] = [];                       // - Stores all tags filtered by a search term
  contactTags: Tag[] = [];                        // - Stores current contact's saved Contact Tags
  newTagName: string;

  @ViewChild('contactTagsAutoComplete') private contactTagsAutoComplete: AutoComplete;

  //** Used by DatePicker component */
  maxDate: Date = new Date();
  dateRange: string = (new Date().getFullYear() - 150) + ':' + new Date().getFullYear(); // - Range: 150 Years Ago until Today

  leadDeskCallInfo: CallInfo;

  constructor(
    public authService: AuthService,
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private contactService: ContactService,
    private contactTypeService: ContactTypeService,
    private contactSourceService: ContactSourceService,
    private organizationService: OrganizationService,
    private toastService: ToastService,
    private utilService: UtilityService,
    private location: Location
  ) { }

  ngOnInit() {
    this.utilService.setPageTitle('Add Contact');
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.setupForm();
    if((this.router.url).indexOf("edit") !== -1) {
      this.isEdit = true;
      this.urlTokens = (this.router.url).split("/");
    }else{
      this.isEdit = false;
    }
  }

  ngAfterViewInit(): void {
    try {
      
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

  getImageUrl(prop){
    //url(' + prop.propertyUrl + prop.propertyImageUrl + ')
    let imagUrl = environment.REAP_URI + prop.propertyImageUrl;

    let link = 'url(' + imagUrl + ')';

    return link;
  }

  searchAutoCompleteForContacts(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }

  searchAutoComplete(event) {
    if (event.query)
      this.filteredTags = this.allTags.filter(t => t.name.toLowerCase().includes(event.query.toLowerCase()));

    // - Store new tag name if it does not exist so user may hit enter and create a new tag
    if (!this.allTags.find(t => t.name.toLowerCase() === event.query.toLowerCase()))
      this.newTagName = event.query;
  }

  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactReferrersAutoComplete.panelVisible) {
      this.contactReferrersAutoComplete.hide();
    } else {
      this.contactReferrersAutoComplete.show();
    }
  }


  setupForm() {
    this.route.params.subscribe(
      (params: Params) => {      
          this.initForm();
      }
    );
  }


  initForm() {
    this.contactForm = this.formBuilder.group({
      firstName: this.formBuilder.control(this.contact.firstName),
      lastName: this.formBuilder.control(this.contact.lastName),
      assignedUserAccountId: this.formBuilder.control(this.contact.assignedUserAccountId),
      email: this.formBuilder.control(this.contact.email, [Validators.required]),
      mobilePhoneNumber: this.formBuilder.control(this.contact.mobilePhoneNumber, [Validators.required]),
      contactTypeIds: this.formBuilder.control(this.contact.contactTypeIds ? this.contact.contactTypeIds : null, [Validators.required]),
      contactSourceId: this.formBuilder.control(this.contact.contactSource.id, [Validators.required]),
      organizationId: this.formBuilder.control(this.contact.organizationId)
    });
    console.log(this.router.url);
    this.onGetUsers();  // - Retrieve all users to populate the Assigned User dropdown
    this.onGetContactTypes(); // - Retrieve all contact types to populate the Contact Type dropdown
    this.onGetContactSources();  // - Retrieve all contact sources to populate the Contact Source dropdown
    this.onGetContactOrganizations();  // - Retrieve all contact sources to populate the Contact Source dropdown
    //this.onFormValueChanges();

    this.isFormInit = true;
  }

  

  onFormValueChanges() {
    this.onEmailChange();
    this.onMobilePhoneNumberChange();
  }

  onEmailChange() {
    this.contactForm.controls["email"].valueChanges.subscribe(
      (email: string) => {
        // If email field is empty then Mobile field is set to required
        if (email.trim() === "" && this.contactForm.controls["mobilePhoneNumber"].value !== "") {
          this.contactForm.controls['mobilePhoneNumber'].setValidators([
            Validators.required
          ]);
          this.contactForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
          this.contactForm.controls['email'].clearValidators();
          this.contactForm.controls['email'].updateValueAndValidity({ emitEvent: false });
        }
        else if (email.trim() === "") {
          this.contactForm.controls['mobilePhoneNumber'].setValidators([
            Validators.required
          ]);
          this.contactForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
        }
        // If email was provided then Mobile field is no longer required
        else {
          this.contactForm.controls['mobilePhoneNumber'].clearValidators();
          this.contactForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
        }
      }
    );
  }

  onMobilePhoneNumberChange() {
    this.contactForm.controls["mobilePhoneNumber"].valueChanges.subscribe(
      (mobilePhoneNumber: string) => {
        // const contactEmails = this.contacts.map(c => c.email);
        // if (this.isEdit)
        //   contactEmails.splice(contactEmails.indexOf(this.contact.email), 1);

        // If MobilePhoneNumber field is empty then Email field is set to required
        if (mobilePhoneNumber.trim() === "" && this.contactForm.controls["email"].value !== "") {
          // this.contactForm.controls['email'].setValidators([
          //   Validators.required,
          //   valueExistsValidator(contactEmails)
          // ]);
          this.contactForm.controls['email'].setValidators([
            Validators.required
          ]);
          this.contactForm.controls['email'].updateValueAndValidity({ emitEvent: false });
          this.contactForm.controls['mobilePhoneNumber'].clearValidators();
          this.contactForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
        }
        else if (mobilePhoneNumber.trim() === "") {
          // this.contactForm.controls['email'].setValidators([
          //   Validators.required,
          //   valueExistsValidator(contactEmails)
          // ]);
          this.contactForm.controls['email'].setValidators([
            Validators.required
          ]);
          this.contactForm.controls['email'].updateValueAndValidity({ emitEvent: false });
        }
        // If MobilePhoneNumber was provided then Email field is no longer required
        else {
          // this.contactForm.controls['email'].setValidators([
          //   valueExistsValidator(contactEmails)
          // ]);
          this.contactForm.controls['email'].clearValidators();
          this.contactForm.controls['email'].updateValueAndValidity({ emitEvent: false });
        }
      }
    );
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: ContactSearchItem[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {
          let labelText = cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          // cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.companyName ? labelText += " / Company: " + cT.companyName : "";
          cT.telephone ? labelText += " / Tel: " + cT.telephone : "";
          cT.mobile ? labelText += " / Mob: " + cT.mobile : "";
          cT.contactEmail ? labelText += " / Email: " + cT.contactEmail : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.referredByContactsOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  onGetContactTypes() {
    this.contactTypeService.getTypeContacts().subscribe(
      (contactTypes: ContactType[]) => {
        // Store retrieved contact types in the contactTypesOptions select list
        contactTypes.map(cT => this.contactTypesOptions.push({ label: cT.name, value: cT.id }));

        // There must be at least one Contact Type created to create or edit a contact
        if (this.contactTypesOptions.length === 0) {
          this.isFormInit = false;
          return;
        }

        let defaultValue = [];


        this.route.queryParams.subscribe(
          (queryParam: Params) => {
              let typeId = contactTypes.find(cT => cT.name === PrepopulatedTypesContact.Client).id;
              if (!defaultValue.includes(typeId))
              {
                defaultValue.push(typeId);
              }
            
          }
        );

        this.contactForm.patchValue({
          contactTypeIds: defaultValue
        });

      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact types", error);
      }
    );
  }

  onGetContactSources() {
    this.contactSourceService.getContactSources().subscribe(
      (contactSources: ContactSource[]) => {
        // Store retrieved contact sources in the contactSourcesOptions select list
        contactSources.map(cS => this.contactSourcesOptions.push({ label: cS.name, value: cS.id }));

        // There must be at least one Contact Source created to create or edit a contact
        if (this.contactSourcesOptions.length === 0) {
          this.isFormInit = false;
          return;
        }

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.contactSourcesOptions[0].value;
        else
          defaultValue = this.contact.contactSource.id;

        this.contactForm.patchValue({
          contactSourceId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact sources", error);
      }
    );
  }

  onGetContactOrganizations() {
      let combined$ = combineLatest(
        this.organizationService.getOrganizations(),
        this.route.queryParams,
        (orgs, queryParam)=> ({orgs, queryParam}));

        combined$.pipe(
          tap((result) => {
            this.contactOrganizationsOptions.push({label: " - Select Organization -", value: null});

                // Store retrieved contact organization in the contactOrganizationOptions select list
                result.orgs.map(cT => this.contactOrganizationsOptions.push({ label: cT.name, value: cT.id }));
             
        let defaultValue = this.organizationIdFromClientCreate;
                
        if (!this.isEdit){
          if(defaultValue == 0)
            defaultValue = this.contactOrganizationsOptions[0].value;
        }
                else
                  defaultValue = this.contact.organizationId;
        
                this.contactForm.patchValue({
                  organizationId: defaultValue
                });
          }),
          map(result => {
            let queryParam = result.queryParam;
            let orgId = +queryParam['organization'];
            if (!isNaN(orgId))
            {
              this.contactForm.get('organizationId').setValue(orgId);
            }
          })
        ).subscribe(
          // Success
          ()=>{},
          // Error
          (error: Response) => {
            this.toastService.createErrorMessage("Error retrieving contact organization", error);
        });
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {

        // Store retrieved users in the usersOptions select list
        users.map(u => this.assignedUserOptions.push({ label: u.fullName, value: u.id }));       

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.authService.applicationProfileUser().id;
        else
          defaultValue = this.contact.assignedUserAccountId ? this.contact.assignedUserAccountId : this.authService.applicationProfileUser().id;

        this.contactForm.patchValue({
          assignedUserAccountId: defaultValue
        });
      }
    );
  }

  

  onAddContact(newContact: Contact) {
    let fullName = newContact.firstName + " " + newContact.lastName;
    this.contactService.addContact(newContact).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The contact " + fullName + " has been created.");

        let contact: Contact = (<any>response) as Contact; // - Can't use NewContact as we need the contact's ID retrieved from db after creation
        // Only ask for opportunity creation if type is set to client
        
        if(!this.isEdit){
          this.router.navigate(['/tasks/new'], { queryParams: { contact: contact.id } });
        }else{
          this.router.navigate(['/tasks/'+this.urlTokens[2]+'/edit'], { queryParams: { contact: contact.id } });
        }
        


      },
      (error) => {
        this.toastService.createErrorMessage("Error adding contact", error);
      }
    );
  }

  goBack(){
    this.location.back();
  }

  onSubmit() {
    let newContact = this.formBuilder.sanitizeFormValues(this.contactForm).value;

    // This is to pass correct value (contact id) from the autocomplete selection and to sanitize it
    // (Eg: writing random string in search and submit form would return an api error response)
    if (newContact.referredByContactId && newContact.referredByContactId.value)
      newContact.referredByContactId = newContact.referredByContactId.value;
    else
      newContact.referredByContactId = null;


    // Status is a checkbox value so only true or false can be returned
    // True - Active Contact, False - Hidden Contact
 
      newContact.status = AuditManager.StatusActive;
      newContact.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddContact(newContact);
    
  }

  isContactSourceRefferer(contactSourceId: number) {
    let referredOption = this.contactSourcesOptions.find(o => o.label === PrepopulatedContactSources.Referred);

    if (referredOption)
      if (contactSourceId === referredOption.value)
        return true;

    return false;
  }

}
