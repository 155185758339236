import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";

import { CommunicationLogType } from "../models/communication-log-type.model";

import { AuthService } from "../../auth/auth.service";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';
import { LoadingSpinnerService } from './loading-spinner.service';
import { Subject } from 'rxjs/Rx';


@Injectable()
export class CommunicationHistoryTypeService {
  private url: string = AppSettings.API_ENDPOINT + 'api/communicationlogtypes/';
  public commlogtypesChanged = new Subject();
  
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loadingService: LoadingSpinnerService

  ) { }

  getCommunicationLogTypes(): Observable<CommunicationLogType[]> {
    //this.authService.reauthenticateIfTokenExpired();

    return this.http.get(this.url).map(
      (response: Response) => {
        const logTypes: CommunicationLogType[] = (<any>response);
        return logTypes;
      }
    );
  }

  getCommunicationLogType(id: number): Observable<CommunicationLogType> {
    //this.authService.reauthenticateIfTokenExpired();

    return this.http.get(this.url + id).map(
      (response: Response) => {
        const logType: CommunicationLogType = (<any>response);
        return logType;
      }
    );
  }

  addCommunicationLogType(communicationLogType: CommunicationLogType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      communicationLogType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateCommunicationLogType(communicationLogType: CommunicationLogType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + communicationLogType.id,
      communicationLogType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateCommunicationTypeLog(communicationLogType: CommunicationLogType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + 'updatecommunicationlogtype/' + communicationLogType.id,
      communicationLogType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
  deleteCommunicationLogType(communicationLogType: CommunicationLogType): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + communicationLogType.id,
      communicationLogType
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}
