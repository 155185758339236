import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { MailchimpListAutomationService } from "../../../shared/services/mailchimp-list-automation.service";
import { ContactTypeService } from '../../../shared/services/contact-type.service';
import { ContactSourceService } from '../../../shared/services/contact-source.service';
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";
import { MailchimpService } from '../../../shared/services/mailchimp.service';

import { ListAutomation } from "../../../shared/models/mailchimp/list-automation.model";
import { ContactType } from '../../../shared/models/contact-type.model';
import { ContactSource } from '../../../shared/models/contact-source.model';
import { SelectItem } from 'primeng/primeng';
import { List } from '../../../shared/models/mailchimp/campaign.model';

@Component({
  selector: 'app-mailchimp-contact-list-automation-manage',
  templateUrl: './mailchimp-contact-list-automation-manage.component.html',
  styleUrls: ['./mailchimp-contact-list-automation-manage.component.scss']
})
export class MailchimpContactListAutomationManageComponent implements OnInit {
  listAutomationForm: FormGroup;       // - Stores the listAutomation form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a listAutomation if true and add a new listAutomation if false

  listAutomation: ListAutomation = new ListAutomation(); // - Stores MailchimpListAutomation to populate form with a new / existing MailchimpListAutomation's values

  contactTypesOptions: SelectItem[] = [];         // - Stores Contact Type Dropdown Options
  contactSourcesOptions: SelectItem[] = [];       // - Stores Contact Source Dropdown Options
  mailchimpListsOptions: SelectItem[] = [];

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private contactTypeService: ContactTypeService,
    private contactSourceService: ContactSourceService,
    private mailchimpService: MailchimpService,
    private listAutomationService: MailchimpListAutomationService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.route.params.subscribe(
      (params: Params) => {
        const listAutomationId = params['id'];
        this.isEdit = listAutomationId != null;

        if (this.isEdit) {
          this.listAutomationService.getListAutomation(+listAutomationId).subscribe(
            (listAutomation: ListAutomation) => {
              this.listAutomation = listAutomation;
              this.initForm();
            },
            (error: Response) => {
              this.toastService.createErrorMessage("Error retrieving list automation", error);
            }
          );
        }
        else
          this.initForm();
      }
    );
  }

  initForm() {
    this.listAutomationForm = this.formBuilder.group({
      contactTypeId: this.formBuilder.control(this.listAutomation.contactType.id, [Validators.required]),
      contactSourceId: this.formBuilder.control(this.listAutomation.contactSource.id, [Validators.required]),
      mailchimpListId: this.formBuilder.control(this.listAutomation.mailchimpListId, [Validators.required])
    });

    this.onGetMailchimpContactLists();
    this.onGetContactTypes(); // - Retrieve all contact types to populate the Contact Type dropdown
    this.onGetContactSources();  // - Retrieve all contact sources to populate the Contact Source dropdown

    this.isFormInit = true;
  }

  onGetContactTypes() {
    this.contactTypeService.getTypeContacts().subscribe(
      (contactTypes: ContactType[]) => {
        // Store retrieved contact types in the contactTypesOptions select list
        contactTypes.map(cT => this.contactTypesOptions.push({ label: cT.name, value: cT.id }));

        // There must be at least one Contact Type created to create or edit a contact
        if (this.contactTypesOptions.length === 0) {
          this.isFormInit = false;
          return;
        }

        let defaultValue = 0;

        if (!this.isEdit)
          defaultValue = this.contactTypesOptions[0].value;
        else
          defaultValue = this.listAutomation.contactType.id;

        this.listAutomationForm.patchValue({
          contactTypeId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact types", error);
      }
    );
  }

  onGetContactSources() {
    this.contactSourceService.getContactSources().subscribe(
      (contactSources: ContactSource[]) => {
        // Store retrieved contact sources in the contactSourcesOptions select list
        contactSources.map(cS => this.contactSourcesOptions.push({ label: cS.name, value: cS.id }));

        // There must be at least one Contact Source created to create or edit a contact
        if (this.contactSourcesOptions.length === 0) {
          this.isFormInit = false;
          return;
        }

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.contactSourcesOptions[0].value;
        else
          defaultValue = this.listAutomation.contactSource.id;

        this.listAutomationForm.patchValue({
          contactSourceId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact sources", error);
      }
    );
  }

  onGetMailchimpContactLists() {
    this.mailchimpService.getLists().subscribe(
      (lists: List[]) => {
        lists.map(cS => this.mailchimpListsOptions.push({ label: cS.name, value: cS.id }));

        let defaultValue = "";
        if (!this.isEdit)
          defaultValue = this.mailchimpListsOptions[0].value;
        else
          defaultValue = this.listAutomation.mailchimpListId;

        this.listAutomationForm.patchValue({
          mailchimpListId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving mailchimp contact lists", error);
      }
    );
  }

  onAddListAutomation(newListAutomation: ListAutomation) {
    this.listAutomationService.addListAutomation(newListAutomation).subscribe(
      (response: Response) => {
        this.listAutomationService.listsChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The list automation has been created.");
        this.router.navigate(['/mailchimp/list-automation']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding list automation", error);
      }
    );
  }

  onUpdateListAutomation(newListAutomation: ListAutomation) {
    this.listAutomationService.updateListAutomation(newListAutomation).subscribe(
      (response: Response) => {
        this.listAutomationService.listsChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The list automation has been updated.");
        this.router.navigate(['/mailchimp/list-automation']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating list automation", error);
      }
    );
  }

  onSubmit() {
    let newListAutomation = this.formBuilder.sanitizeFormValues(this.listAutomationForm).value;

    if (this.isEdit) {
      newListAutomation.id = this.listAutomation.id; // - Set id of edited listAutomation to its original id
      //newListAutomation.createdByUserId = this.listAutomation.createdByUserId; // - Need createdById of original list automation to check if it is a prepopulated type
      //newListAutomation.updatedByUserId = this.authService.getUser().id;
      this.onUpdateListAutomation(newListAutomation);
    }
    else {
      //newListAutomation.createdByUserId = this.authService.getUser().id;
      this.onAddListAutomation(newListAutomation);
    }
  }
}
