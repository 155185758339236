import { Pipe, PipeTransform } from '@angular/core';
import { List } from 'app/views/shared/models/mailchimp/campaign.model';
@Pipe({
    name: 'mailchimpList'
})
export class MailchimpListPipe implements PipeTransform {
    transform(value: string, lists: List[]) {
      if (lists.length > 0) {
        return lists.find(l => l.id === value).name;
      }
      return value;
    }
}