import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

import { AuthService } from '../auth/auth.service';
import { UtilityService } from '../shared/services/utility.service';

@Component({
  selector: 'app-message-centre',
  templateUrl: './message-centre.component.html',
  styleUrls: ['./message-centre.component.scss']
})
export class MessageCentreComponent implements OnInit {
  currentRoute: string = "";
  tabActiveIndex: number = 0;

  emailTabIndex: number = 0;
  smsTabIndex: number = 1;
  templatesTabIndex: number = 2;
  emailConfigTabIndex: number = 3;
  smsConfigTabIndex: number = 4;
  signaturesTabIndex: number = 5;

  constructor(
    public authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilityService
  ) { }

  ngOnInit() {
    this.utilService.setPageTitle('Messaging Centre');
    this.currentRoute = this.router.url.replace("/messaging-centre/", "");
    this.switchActiveTab();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url.replace("/messaging-centre/", "");
        this.switchActiveTab();
      }
    });
  }

  switchActiveTab() {
    switch (this.currentRoute) {
      case "sms":
        this.tabActiveIndex = this.smsTabIndex;
        break;
      case "email":
        this.tabActiveIndex = this.emailTabIndex;
        break;
      case "templates":
        this.tabActiveIndex = this.templatesTabIndex;
        break;
      case "config/sms":
        this.tabActiveIndex = this.smsConfigTabIndex;
        break;
      case "config/email":
        this.tabActiveIndex = this.emailConfigTabIndex;
        break;
      case "signatures":
        this.tabActiveIndex = this.signaturesTabIndex;
        break;
      default:
        this.tabActiveIndex = null;
    }
  }

  onTabChange($event) {
    switch ($event.index) {
      case this.smsTabIndex:
        this.router.navigate(['sms'], { relativeTo: this.route })
        break;
      case this.emailTabIndex:
        this.router.navigate(['email'], { relativeTo: this.route })
        break;
      case this.templatesTabIndex:
        this.router.navigate(['templates'], { relativeTo: this.route })
        break;
      case this.smsConfigTabIndex:
        this.router.navigate(['config/sms'], { relativeTo: this.route })
        break;
      case this.emailConfigTabIndex:
        this.router.navigate(['config/email'], { relativeTo: this.route })
        break;
      case this.signaturesTabIndex:
        this.router.navigate(['signatures'], { relativeTo: this.route })
        break;
      default:
        this.tabActiveIndex = null;
    }
  }

}
