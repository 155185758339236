// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const BASE_URL = 'https://contacts-m7kzte.epicagentcrm.com';
export const API_PORT = '80';
export const AUTH_PORT = '80';
// export const NOTIFS_PORT = '8003';
export const ANG_PORT = '80';

export const environment = {
  production: false,

  PORTAL_URI: `${BASE_URL}:${ANG_PORT}`, 
  API_ENDPOINT: `https://contacts-webapi-m7kzte.epicagentcrm.com/`, 
  AUTHORITY_ENDPOINT: `https://auth-m7kzte.epicagentcrm.com/`,

  POST_LOGOUT_REDIRECT_URI: `https://contacts-m7kzte.epicagentcrm.com`,
  REDIRECT_URI: `https://contacts-m7kzte.epicagentcrm.com/auth`,
  SILENT_REDIRECT_URI: `https://contacts-m7kzte.epicagentcrm.com/silent`,
  REAP_URI: 'https://app-m7kzte.epicagentcrm.com',
  IS_EPIC: true
};
