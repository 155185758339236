import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { Organization } from "../models/organization.model";
import { CrmUser } from "../models/user.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';
import { CompanyDetails } from '../models/company-details.model';


@Injectable()
export class OrganizationService {
  private url: string = AppSettings.API_ENDPOINT + 'api/organizations/';
  private companyDetailsUrl: string = AppSettings.API_ENDPOINT + "api/company-details/";

  public typesChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getOrganizations(): Observable<Organization[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        
        let organizations: Organization[] = (<any>response);
        organizations = organizations.map(
          cT => {
            
            //cT.createdByUserAccount = (<CrmUser>(cT.createdByUserAccount))//this.userService.getUser(cT.createdByUserAccountId);
            //cT.updatedByUserAccount =  (<CrmUser>(cT.updatedByUserAccount));//this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return organizations;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getOrganization(id: number): Observable<Organization> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let organization: Organization = (<any>response);
        // organization.createdByUserAccount = this.userService.getUser(organization.createdByUserAccountId);
        // organization.updatedByUserAccount = this.userService.getUser(organization.updatedByUserAccountId);
        return organization;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addOrganization(organization: Organization): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    
    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      organization
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateOrganization(organization: Organization): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + organization.id,
      organization
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteOrganization(organization: Organization): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + organization.id,
      organization
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  //create company Details
  createCompanyDetails(companyDetails: CompanyDetails): Observable<Response> {
    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.companyDetailsUrl,
      companyDetails
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  //read company details. Since there will be only 1 entry for the company, there is no need to retrieve the company details by id.
  //In this case the first instance inside the company details table, is retrieved and assumed to be the current company details.
  getCompanyDetails(): Observable<CompanyDetails> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.companyDetailsUrl).map(
      (response: Response) => {
        let companyDetails: CompanyDetails = (<any>response);
        return companyDetails;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  //update company details
  updateCompanyDetails(companyDetails: CompanyDetails): Observable<Response> {
    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.companyDetailsUrl + companyDetails.id,
      companyDetails
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}