import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { OpportunitiesComponent } from "./opportunities.component";
import { OpportunityListComponent } from "./opportunity-list/opportunity-list.component";
import { OpportunityManageComponent } from "./opportunity-manage/opportunity-manage.component";
import { OpportnunitiyContactManageComponent } from './opportunity-manage/opportnunitiy-contact-manage/opportnunitiy-contact-manage.component';
import { RoleGuard } from '../auth/role-guard.service';
import { HomeAccessGuard } from '../auth/home-access-guard.service';
import { OpportunityBoardComponent } from './opportunity-board/opportunity-board.component';

const opportunitiesRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard,HomeAccessGuard],
    children: [
      {
        path: 'opportunities', component: OpportunitiesComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: OpportunityListComponent },
          { path: 'board', component: OpportunityBoardComponent }, 
          { path: ':id/edit', component: OpportunityManageComponent,
          children: [
            { path: 'new-contact', component: OpportnunitiyContactManageComponent }
        ] },
          { path: 'new', component: OpportunityManageComponent,
          children: [
            { path: 'new-contact', component: OpportnunitiyContactManageComponent}
        ] }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(opportunitiesRoutes)],
  exports: [RouterModule]
})
export class OpportunitiesRoutingModule { }