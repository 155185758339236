import { Contact } from "./contact.model";
import { CommunicationLogType } from "./communication-log-type.model";
import { CrmUser } from "./user.model";
import { Task } from "./task.model";
import { Opportunity } from "./opportunity.model";
import { CommunicationLogProperty } from "./communication-log-property.model";

export class CommunicationLog {
    constructor(
        public id: number = 0,
        public communicationLogTypeId: number = null,
        public communicationLogTypeName: string = null,
        public communicationLogType: CommunicationLogType = new CommunicationLogType(),
        public direction: string = null,
        public contact: Contact = new Contact(),
        public contactId: number = 0,
        public opportunity: Opportunity = new Opportunity(),
        public opportunityId: number = 0,
        public assignedUser: CrmUser = new CrmUser(),
        public assignedUserAccountId: number = null,
        public task: Task = new Task(),
        public taskId: number = 0,
        public description: string = null,
        public occurredOn: string = null,        
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number= null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public status: string = null,
        public properties: CommunicationLogProperty[] = [],
        public assignedUserAccount: CrmUser = null,

    ) { }
}

export class CommunicationLogListRow {
    constructor(
        public id: number = 0,

        public typeName: string = null,
        public direction: string = null,

        public contName: string = null,
        public contEmail: string = null,
        public contMob: string = null,
        public contOrganization: string = null,
        public contId: number = 0,

        public oppId: number = 0,
        public assUser: string = null,
        public createdBy: string = null,

        public desc: string = null,

        public occurredOn: string = null,       
        public updatedByUserAccountId: number = null, 

    ) { }
}