import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { TaskTypeService } from "../../../shared/services/task-type.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";

import { TaskType } from "../../../shared/models/task-type.model";

import { valueExistsValidator } from "../../../shared/validators/value-exists.validator";

@Component({
  selector: 'app-task-type-manage',
  templateUrl: './task-type-manage.component.html',
  styleUrls: ['./task-type-manage.component.scss']
})
export class TaskTypeManageComponent implements OnInit {
  taskTypeForm: FormGroup;       // - Stores the taskType form's values
  isFormInit: boolean = false;      // - The form is not created until it is initialised
  isEdit: boolean = false;          // - The form is set to update a taskType if true and add a new taskType if false

  taskTypes: TaskType[] = [];             // - Stores all taskTypes retrieved from db
  taskType: TaskType = new TaskType(); // - Stores TaskType to populate form with a new / existing TaskType's values

  isModalShown: boolean = false;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private taskTypeService: TaskTypeService,
    private authService: AuthService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.taskTypeService.getTaskTypes().subscribe(
      (taskTypes: TaskType[]) => {
        this.taskTypes = taskTypes; // - Store all task types retrieved from db

        this.route.params.subscribe(
          (params: Params) => {
            const taskTypeId = params['id'];
            this.isEdit = taskTypeId != null;

            if (this.isEdit) {
              this.taskType = this.taskTypes.find(cType => cType.id === +taskTypeId); // - Store the selected taskType

              // Redirect to 404 page if the selected taskType could not be found
              if (!this.taskType) {
                this.router.navigate(['/error/404']);
                return;
              }
            }
            this.initForm();
          }
        );
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving task types", error);
      }
    );
  }

  initForm() {
    // Get all taskType names to know if it already exists
    const taskTypeNames = this.taskTypes.map(cType => cType.name);

    if(!this.isEdit){
      this.taskTypeForm = this.formBuilder.group({
        name: this.formBuilder.control(this.taskType.name, [Validators.required, valueExistsValidator(taskTypeNames)]),
        IsRelatedProperty: this.formBuilder.control(this.taskType.isRelatedProperty),
        IsRelatedLocation: this.formBuilder.control(this.taskType.isRelatedLocation)
      });
   } else {
      this.taskTypeForm = this.formBuilder.group({
        name: this.formBuilder.control(this.taskType.name),
        IsRelatedProperty: this.formBuilder.control(this.taskType.isRelatedProperty),
        IsRelatedLocation: this.formBuilder.control(this.taskType.isRelatedLocation)
    });
   }

    this.isFormInit = true;
  }

  onAddTaskType(newTaskType: TaskType) {
    this.taskTypeService.addTaskType(newTaskType).subscribe(
      (response: Response) => {
        this.taskTypeService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The task type " + newTaskType.name + " has been created.");
        this.router.navigate(['/tasks/types']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding task type", error);
      }
    );
  }

  onUpdateTaskType(newTaskType: TaskType) {
    this.taskTypeService.updateTaskType(newTaskType).subscribe(
      (response: Response) => {
        this.taskTypeService.typesChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "The task type " + newTaskType.name + " has been updated.");
        this.router.navigate(['/tasks/types']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating task type", error);
      }
    );
  }

  onSubmit() {
    let newTaskType = this.formBuilder.sanitizeFormValues(this.taskTypeForm).value;
    console.log(newTaskType);
    if (this.isEdit) {
      newTaskType.id = this.taskType.id; // - Set id of edited taskType to its original id
      newTaskType.createdByUserAccountId = this.taskType.createdByUserAccountId; // - Need createdById of original task type to check if it is a prepopulated type
      newTaskType.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateTaskType(newTaskType);
    }
    else {
      newTaskType.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddTaskType(newTaskType);
    }
  }
}
