import { AfterViewInit, Component, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { AuditManager, PrepopulatedEntities, PrepopulatedContactTypes, PrepopulatedContactSources, PrepopulatedOpportunityTypes, PrepopulatedTaskTypes } from "app.constant";

import { CustomFormBuilder } from "../../shared/classes/CustomFormBuilder";

import { OpportunityService } from "../../shared/services/opportunity.service";
import { OpportunityTypeService } from "../../shared/services/opportunity-type.service";
import { AuthService } from "../../auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { UserService } from "../../shared/services/user.service";
import { ContactService } from "../../shared/services/contact.service";
import { OpportunityLostTypeService } from '../../shared/services/opportunity-lost-type.service';
import { ProductService } from '../../shared/services/product.service';

import { Opportunity } from "../../shared/models/opportunity.model";
import { OpportunityType } from "../../shared/models/opportunity-type.model";

import { SelectItem, ConfirmationService, AutoComplete } from "primeng/primeng";
import { Observable, Subscription } from "rxjs/Rx";
import { CrmUser } from "../../shared/models/user.model";
import { Contact } from "../../shared/models/contact.model";
import { OpportunityLostType } from '../../shared/models/opportunity-lost-type.model';
import { Product } from '../../shared/models/product.model';
import { PropertyService } from 'app/views/shared/services/property.service';
import { PropertyCategory } from 'app/views/shared/models/property-category.model';
import { PropertyLocality } from 'app/views/shared/models/property-locality.model';
import { PropertyRegion } from 'app/views/shared/models/property-region.model';
import { PropertyDealType } from 'app/views/shared/models/property-dealtype';
import { PropertyAttribute } from 'app/views/shared/models/property-attribute';
import { PropertyDetails } from 'app/views/shared/models/property-details.model';
import { pipeFromArray } from 'rxjs/internal/util/pipe';
import { OpportunitySelection } from 'app/views/shared/models/opportunity-selection.model';
import { PropertyOppSelection } from 'app/views/shared/models/property-opportunity-selection.model';
import { Property } from 'app/views/shared/models/property.model';
import { OpportunityPropertiesNotInterested } from 'app/views/shared/models/opportunity-properties-not-interested.model';
import { ContactSearchItem } from 'app/views/shared/models/contact-search-item.model';
import { UtilityService } from 'app/views/shared/services/utility.service';
import { PropertyCountry } from 'app/views/shared/models/property-country-model';
import { RentalPriceType } from 'app/views/shared/models/rental-price-type-model';
import { environment } from 'environments/environment';
import { asTextData } from '@angular/core/src/view';
import { PrivateModeService } from 'app/views/shared/services/private-mode.service';
import { AuditService } from 'app/views/shared/services/audit.service';
import { TenantAppSettingService } from 'app/views/auth/tenant-app-setting.service';
import { PropertyCounty } from 'app/views/shared/models/property-county.model';
import { DefaultSelections } from 'app/views/shared/models/default-selections.model';
import { FinishingType } from 'app/views/shared/models/finishing.model';
import { FurnishingType } from 'app/views/shared/models/furnishing.model';
declare var Tour: any;

@Component({
  selector: 'app-opportunity-manage',
  templateUrl: './opportunity-manage.component.html',
  styleUrls: ['./opportunity-manage.component.scss']
})


export class OpportunityManageComponent implements OnInit, OnDestroy, AfterViewInit {
  opportunityForm: FormGroup;                     // - Stores the opportunity form's values
  isFormInit: boolean = false;                    // - The form is not created until it is initialised
  isEdit: boolean = false;                        // - The form is set to update an opportunity if true and add a new opportunity if false
  hasDefaultSeletions: boolean = false;
  isLoadingPage: boolean = false;
  opportunity: Opportunity = new Opportunity();   // - Stores Opportunity to populate form with a new / existing opportunity's values
  defaultSelections: DefaultSelections = new DefaultSelections();
  showCountry: boolean = false;
  productsOptions: SelectItem[] = [];             // - Stores Products Dropdown Options  
  //propertyCategoryOptions: SelectItem[] = [];             // - Stores Property Category Dropdown Options
  opportunityTypesOptions: SelectItem[] = [];     // - Stores Opportunity Type Dropdown Options
  opportunityLostTypesOptions: SelectItem[] = []; // - Stores Opportunity Lost Type Dropdown Options
  assignedUserOptions: SelectItem[] = [];         // - Stores Users to select the Assigned User of this opportunity
  assignedContactOptions: SelectItem[] = [];      // - Stores Contacts to select the Assigned Contact of this opportunity

  useRegionCounty: boolean = false;   

  MainCatOptions: SelectItem[] = [];
  SubCatOptions: SelectItem[] = [];
  SubCatOptionsList: number[] = [];
  localityOptionList: number[] = [];
  PropertyRegionsOptionsList: number[] = [];
  PropertyCountryOptions: SelectItem[] = [];
  PropertyRegionsOptions: SelectItem[] = [];
  PropertyCountiesOptions: SelectItem[] = [];
  PropertyLocalitiesOptions: SelectItem[] = [];
  PropertyDealTypesOptions: SelectItem[] = [];
  PropertyRoomsOptions: SelectItem[] = [];
  PropertyFeaturesOptions: SelectItem[] = [];
  PropertyRentalPriceTypeOptions: SelectItem[] = [];
  FinishingTypeOptions: SelectItem[] = [];
  FurnishingTypeOptions: SelectItem[] = [];
  Properties: Property[] = [];
  ParamValues: string = null;
  Viewings: number[] = [];
  PropNotInterested: number[] = [];
  reapUri = 'localhost';
  crmUri:string = '';
  tour: any;
  tourEdit: any;

  propertyPage = 0;
  propertyPageSize = 30;
  allPropertyLoaded = false;

  hasLettingRole:boolean = false;
  hasSalesRole:boolean = false;

  isFavoriteCollapsed: boolean = false;
  isNotFavoriteCollapsed: boolean = false;
  isNotIterestedCollapsed: boolean = true;

  FavoriteProperties: Property[] = [];
  NotInterestedProperties: Property[] = [];
  NotFavoriteProperties: Property[] = [];
  NotFavoriteViewingIds: number[] = [];

  //private Mode
  privateMode;
  private privateModeSubscription: Subscription;

  isAddressVisible: boolean[] = [];
  areOwnersVisible: boolean[] = [];


  @ViewChild('contactsAutoComplete') private contactsAutoComplete: AutoComplete;

  /* get FavoriteProperties() { return this.Properties.filter(x => x.isFavorite)}
  get NotInterestedProperties() { return this.Properties.filter(x => x.isNotInterested)}

  get NotFavoriteProperties() { 
    var tmp = this.Properties.filter(x => !x.isFavorite && !x.isNotInterested);

    if (this.allPropertyLoaded) {
      return tmp;
    } else {
      var num = tmp.length - (tmp.length % 3);
      return tmp.slice(0,num);
    }
  } */

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private contactService: ContactService,
    private propertyService: PropertyService,
    private productService: ProductService,
    private opportunityService: OpportunityService,
    private appSetting: TenantAppSettingService,  // used on HTML
    private opportunityTypeService: OpportunityTypeService,
    private opportunityLostTypeService: OpportunityLostTypeService,
    private confirmationService: ConfirmationService,
    private authService: AuthService,
    private toastService: ToastService,
    private utilService: UtilityService,
    private _ngZone: NgZone,
    private privateModeService: PrivateModeService,
    private auditService: AuditService
  ) { }

  ngOnInit() {
    this.utilService.setPageTitle('Add Opportunity');
    this.reapUri = environment.REAP_URI;
    this.crmUri = window.location.origin;

    this.setupForm();

    this.fillDummyDropdowns();
    this.privateModeSubscription = this.privateModeService.privateModeEmitter.subscribe(privateMode => {
      this.privateMode = privateMode;
    });
  }

  ngOnDestroy(): void {
    this.privateModeSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    

   if(this.isEdit){
    this._ngZone.runOutsideAngular(() => {
      this.tourEdit = new Tour({                           
          debug: true,
          storage: window.localStorage,
          name: "editOpportunity",                                               
          backdrop: true,
      });
      this.tourEdit.addStep({
          element: "#but-x",
          title: "New Contact",
          content: "Added the option to quickly add a contact.",
          placement: 'bottom',
          backdrop: true,
      })
      this.tourEdit.addStep({
        element: "#filterInReapBtn",
        title: "Filter In Listing",
        content: "This new button allows you to continue filtering this opportunity's properties in Property Listing",
        placement: 'bottom',
        backdrop: true
      });
      
      const runTourCreate = localStorage.getItem('editOpportunity_end');
      if(!(runTourCreate === "yes")){
              // Initialize the tour
        this.tourEdit.init();
        this.tourEdit.start(true);
      }


  
  });
   }else{
    this._ngZone.runOutsideAngular(() => {
      this.tour = new Tour({                           
          debug: true,
          storage: window.localStorage, 
          name: "createOpportunity",                       
          backdrop: true,
      });
      this.tour.addStep({
          element: "#but-x",
          title: "New Contact",
          content: "Added the option to quickly add a contact.",
          placement: 'bottom',
          backdrop: true,
      });
      
      // Initialize the tour
      const runTour = localStorage.getItem('createOpportunity_end');
      if(!(runTour === "yes")){
        this.tour.init();
        this.tour.start(true);
      }
  });
   }
  
}

  getImageUrl(prop){
    //url(' + prop.propertyUrl + prop.propertyImageUrl + ')
    let imagUrl = environment.REAP_URI + prop.propertyImageUrl;

    let link = 'url(' + imagUrl + ')';

    return link;
  }

  isPropertyViewedOrNotInterested(prop: Property) {

    if (prop.isNotInterested) {
      return 'NotInterested';
    }
    else if (this.Viewings.includes(prop.propertyId)) {
      return 'Viewed';
    }
  }

  NotInterested(prop) {
    this.confirmationService.confirm({
      header: prop.propertyPreCode + ' (Not Interested)',
      message: 'Would you like to mark this property as Not Interested?',
      accept: () => {
        var request = new OpportunityPropertiesNotInterested();
        request.OpportunityId = this.opportunity.id;
        request.PropertyId = prop.propertyId;
        this.opportunityService.addPropertyNotInterested(request).subscribe(
          (response: Response) => {
            this.toastService.createSuccessMessage("Success", "The property has been listed as not interested.");
            //this.router.navigate(['/opportunities/' + this.opportunity.id + '/edit']);
            this.PropNotInterested.push(prop.propertyId);
            (prop as any).isNotInterested = true;
            this.SortPropertyPerPreferences();
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error updating properties not interested", error);
          }
        );
      }
    });
  }

  UndoNotInterested(prop) {
    this.confirmationService.confirm({
      header: prop.propertyPreCode + ' (Undo Not Interested)',
      message: 'Would you like to undo Not Interested for this property ?',
      accept: () => {
        var request = new OpportunityPropertiesNotInterested();
        request.OpportunityId = this.opportunity.id;
        request.PropertyId = prop.propertyId;
        this.opportunityService.undoPropertyNotInterested(request).subscribe(
          (response: Response) => {
            this.toastService.createSuccessMessage("Success", "Property not interested has been removed.");
            //this.router.navigate(['/opportunities/' + this.opportunity.id + '/edit']);
            let index = this.PropNotInterested.indexOf(prop.propertyId);
            this.PropNotInterested.splice(index,1);
            (prop as any).isNotInterested = false;
            this.SortPropertyPerPreferences();
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error undo properties not interested", error);
          }
        );
      }
    });
  }

/*   sortByNotIntrested() {
    this.PropertyOrderedByNotIntrested = (this.Properties as any).sort((a, b) => 
    { if (a.notIntrested != b.notIntrested)
       return a.notIntrested > b.notIntrested && 1 || -1

      return -1
    }
    )
  } */

  fillDummyDropdowns(){
   
  }

  searchAutoComplete(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }

  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactsAutoComplete.panelVisible) {
      this.contactsAutoComplete.hide();
    } else {
      this.contactsAutoComplete.show();
    }
  }

  setupForm() {
    this.route.params.subscribe(
      (params: Params) => {
        const opportunityId = params['id'];
        this.isEdit = opportunityId != null; // - Returns true if an id was present within the url
        
        if (this.isEdit)
        {
          this.utilService.setPageTitle('Edit Opportunity');
          this.ParamValues = "&opportunity=" + opportunityId;
          this.onGetOpportunity(opportunityId);
        }
        else
          this.initForm();
      }
    );
  }

  initForm() {
    this.isLoadingPage = true;
    this.onGetOpportunityTypes();
    this.onGetOpportunityLostTypes();
    this.onGetMainCategories();
    this.onGetSubCategoriesOnLoad(this.opportunity.opportunitySelectionModel.subCatIds);

    if (!this.appSetting.useRegionCounty) {
      this.onGetPropertyLocalitiesOnLoad(this.opportunity.opportunitySelectionModel.localityIds);
      this.onGetPropertyRegions();
    }


    this.onGetPropertyCountries();
    this.onGetPropertyDealType();
    this.onGetPropertyRooms();
    this.onGetPropertyFeatures();
    this.onGetRentalPriceType();
    this.onGetUsers();
    this.onGetProducts();
    this.onGetFinishingType();
    this.onGetFurnishingType();

    this.opportunityForm = this.formBuilder.group({
      name: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.name : null, [Validators.required]),
      description: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.description : null),
      productIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunityProducts.map(oP => oP.productId) : null),
      probability: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.probability : null, [Validators.min(0), Validators.max(100)]),
      value: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.value : null),
      assignedContactId: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.assignedContactId : null, [Validators.required]),
      assignedUserAccountId: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.assignedUserAccountId : null),
      opportunityTypeId: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunityTypeId : null, [Validators.required]),
      opportunityLostTypeId: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunityLostTypeId : null),
      lostTypeDescription: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.lostTypeDescription : null),
      
      opportunitySelectionModel: this.formBuilder.group({
        mainCatIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.mainCatIds : null),
        subCatIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.subCatIds : null),
        countryId: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.countryId : null),
        rentalPriceTypeId: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.rentalPriceTypeId : null),
        regionId: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.regionId : null),
        regionIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.regionIds : null),
        countyId: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.countyId : null),
        localityIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.localityIds : null),
        dealTypeIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.dealTypeIds : null, [Validators.required]),
        startingBudget: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.startingBudget : null, [Validators.min(0), Validators.max(10000000)]),
        maximumBudget: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.maximumBudget : null, [Validators.min(0), Validators.max(10000000)]),
        minimumBedrooms: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.minimumBedrooms : null),
        minimumGarages: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.minimumGarages : null),
        minimumBathrooms: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.minimumBathrooms : null),
        roomsIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.roomsIds : null),
        featureIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.featureIds : null),
        description: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.description : null),
        finishingTypeIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.finishingTypeIds : null),
        furnishingTypeIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.furnishingTypeIds : null),
      }),
    });
    

    if (this.isEdit && this.opportunity.assignedContact) {
      let c = this.opportunity.assignedContact;
      let labelText = this.getContactTextLabel(c);

      let defaultSelectItem: SelectItem = {
        label: labelText,
        value: this.opportunity.assignedContactId
      };

      let defaultValue = this.opportunity.assignedContactId ? defaultSelectItem : null;

      

      this.opportunityForm.patchValue({
        assignedContactId: defaultValue
      });

      let retrievedContactsOptions = [];
      retrievedContactsOptions.push(
        {
          label: labelText,
          value: this.opportunity.assignedContact
        }
      );

    this.assignedContactOptions = retrievedContactsOptions;
    }

    this.onGetDefaultSelections();

    if (this.isEdit)
    {
      let assignedContactId = this.opportunityForm.get('assignedContactId').value.value;
      let viewingTypeLabel = PrepopulatedTaskTypes.Viewing;

      this.ParamValues = "?contact=" + assignedContactId + "&viewingtype=" + viewingTypeLabel + this.ParamValues;

      this.onGetViewings(assignedContactId);


      if (this.appSetting.useRegionCounty) {

        var selectedCountry = this.opportunityForm.get('opportunitySelectionModel.countryId').value; // it could be that this is better outside from IF
        this.onGetPropertyRegionsByCountryId(selectedCountry);
      
        var selectedRegion = this.opportunityForm.get('opportunitySelectionModel.regionId').value;
        this.onGetPropertyCountiesByRegionId(selectedRegion);

        var selectedCounty = this.opportunityForm.get('opportunitySelectionModel.countyId').value;
        this.onGetPropertyLocalitiesByCountyId(selectedCounty);
      } else {
/*         var selectedRegion = this.opportunityForm.get('opportunitySelectionModel.regionId').value;
        this.onGetPropertyLocalitiesByRegionId(selectedRegion); */
      }
    }
    else 
    {
      this.onChangeMainCatogory();
      this.onChangeRegion();
    }

    this.route.queryParams.subscribe(
      (queryParam: Params) => {
        let contactId = queryParam['contact'];
        if (contactId != null) {


          this.contactService.getContact(+contactId).subscribe(
            (contact: Contact) => {
              let labelText = this.getContactTextLabel(contact);


              let defaultValue: SelectItem = {
                label: labelText,
                value: +contactId
              };

              this.opportunityForm.patchValue({
                assignedContactId: defaultValue
              });

              let retrievedContactsOptions = [];
              retrievedContactsOptions.push(
                {
                  label: labelText,
                  value: +contactId
                }
              );
    
            this.assignedContactOptions = retrievedContactsOptions;

            }
          );
        }
      }
    );

    this.isFormInit = true;

    
    this.isLoadingPage = false;
  }

  getContactTextLabel(c: Contact) {
    let labelText = c.refCode ? "Ref: " + c.refCode : "";
      c.fullName.trim().length > 0 ? labelText += " / Name: " + c.fullName : "NO-NAME";
      c.idCardNumber ? labelText += " / ID: " + c.idCardNumber : "";
      c.companyName ? labelText += " / Company: " + c.companyName : "";
      c.homePhoneNumber && !this.appSetting.isHideSensitiveInfo ? labelText += " / Tel: " + c.homePhoneNumber : "";
      c.mobilePhoneNumber && !this.appSetting.isHideSensitiveInfo ? labelText += " / Mob: " + c.mobilePhoneNumber : "";
      c.email && !this.appSetting.isHideSensitiveInfo ? labelText += " / Email: " + c.email : "";

      return labelText;
  }

  isOpportunityTypeLost() {
    const oppTypeIdValue = this.opportunityForm.get('opportunityTypeId').value;
    const oppType = this.opportunityTypesOptions.find(o => o.value === oppTypeIdValue);
    
    if (oppType) {
      const oppTypeLabel = oppType.label;
      if (oppTypeLabel === PrepopulatedOpportunityTypes.Lost)
        return true;
    }

    return false;
  }

  onGetMainCategories() {
    this.propertyService.getMainCategoryTypes().subscribe(
      (mainCategories: PropertyCategory[]) => {
        // - Store the retrieved products as options for multiselect
        mainCategories.map(p => {
          this.MainCatOptions.push({ label: p.categoryName, value: p.categoryId });
        });
      }
    );
  }
  

  onGetSubCategories(SelectedMainCat: number[]) {
    this.propertyService.getSubCategoryTypes(SelectedMainCat).subscribe(
      (subCategories: PropertyCategory[]) => {
        // - Store the retrieved products as options for multiselect
        this.SubCatOptionsList = [];
        subCategories.map(p => {
          this.SubCatOptionsList.push(p.categoryId);
        });
        this.populateSubCategoryValues(this.SubCatOptionsList);
      }
    );
  }

  onGetSubCategoriesOnLoad(selectedSubCats: number[]) {
    this.propertyService.getSubCategoryTypesAll().subscribe(
      (subCategories: PropertyCategory[]) => {
        // - Store the retrieved products as options for multiselect
        this.SubCatOptions = [];
        subCategories.map(p => {
          this.SubCatOptions.push({ label: p.categoryName, value: p.categoryId });
        }
        
        );



        this.populateSubCategoryValues(selectedSubCats);
      }
    );
  }

  populateSubCategoryValues(subCategories: number[]) {

    this.opportunityForm.get('opportunitySelectionModel.subCatIds').setValue(subCategories);
  }

  // cleanSubCategoryValues(subCategories: PropertyCategory[]) {
  //   // Get the current selected sub-categories
  //   var subCatValues = this.opportunityForm.get('opportunitySelectionModel.subCatIds').value;
  //   // Get the sub-categoriy ids retrieved from the database
  //   var subCatIds = subCategories.map(x => x.categoryId);

  //   var subCatToSelect = subCatIds;//subCatValues.filter(x => subCatIds.includes(x));
  //   this.opportunityForm.get('opportunitySelectionModel.subCatIds').setValue(subCatToSelect);
  // }

  onGetDefaultSelections() {
    this.propertyService.getDefaultSelections().subscribe(
      (defaultSelections: DefaultSelections) => {
        this.defaultSelections = defaultSelections;
        this.hasDefaultSeletions = (this.defaultSelections.countryId > 0 || this.defaultSelections.regionId > 0 || this.defaultSelections.countyId > 0)

        if (this.hasDefaultSeletions) {
          if (this.defaultSelections.countryId > 0) {
            this.onGetPropertyRegionsByCountryId(this.defaultSelections.countryId);

            console.log('2: --- ' + this.appSetting.useRegionCounty)
            
            if (this.defaultSelections.regionId > 0) {
              if (this.appSetting.useRegionCounty) {

                  this.onGetPropertyCountiesByRegionId(this.defaultSelections.regionId);

                  if (this.defaultSelections.countyId > 0) {
                    this.onGetPropertyLocalitiesByCountyId(this.defaultSelections.countyId);
                  }
                

              } else {
                this.onGetPropertyLocalitiesByRegionId(this.defaultSelections.regionId);
              }
            }

            /* if (this.appSetting.useRegionCounty) {
      
        var selectedRegion = this.opportunityForm.get('opportunitySelectionModel.regionId').value;
        this.onGetPropertyCountiesByRegionId(selectedRegion);

        var selectedCounty = this.opportunityForm.get('opportunitySelectionModel.countyId').value;
        this.onGetPropertyLocalitiesByCountyId(selectedCounty);
      } else {
        var selectedRegion = this.opportunityForm.get('opportunitySelectionModel.regionId').value;
        this.onGetPropertyLocalitiesByRegionId(selectedRegion);
      } */

            this.opportunityForm.get('opportunitySelectionModel').patchValue({
              countryId: this.defaultSelections.countryId
            });
          }
        }
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving default selections", error);
        this.router.navigate(['/error/404']);
      }
    )
  }

  onGetPropertyRegionsByCountryId(seclectedCountry: number) {
    this.propertyService.getPropertyRegionsByCountry(seclectedCountry).subscribe(
      (propertyRegions: PropertyRegion[]) => {

        if (this.appSetting.useRegionCounty) {

          this.PropertyRegionsOptions = [];
          propertyRegions.map(p => {
            this.PropertyRegionsOptions.push({ label: p.regionName, value: p.regionId });
          });
  
          let defaultPropertyRegionValue = 0;
          if (this.isEdit)
          defaultPropertyRegionValue = this.opportunity.opportunitySelectionModel.regionId ? this.opportunity.opportunitySelectionModel.regionId : 0;
          else if (this.hasDefaultSeletions)
          defaultPropertyRegionValue = this.defaultSelections.regionId;
  
          this.opportunityForm.get('opportunitySelectionModel').patchValue({
            regionId: defaultPropertyRegionValue
          });

        } else {

          this.PropertyRegionsOptionsList = [];
          propertyRegions.map(p => {
            this.PropertyRegionsOptionsList.push(p.regionId);
          });
          this.populateRegionValues(this.PropertyRegionsOptionsList);

          this.onGetPropertyLocalities(this.PropertyRegionsOptionsList, seclectedCountry);

        }

        
      }
    );
  }

  onGetPropertyCountiesByRegionId(seclectedRegion: number) {
    this.propertyService.getPropertyCountiesByRegion(seclectedRegion).subscribe(
      (propertyCounties: PropertyCounty[]) => {
        this.PropertyCountiesOptions = [];
        propertyCounties.map(p => {
          this.PropertyCountiesOptions.push({ label: p.countyName, value: p.countyId });
        });

        let defaultPropertyCountyValue = 0;
        if (this.isEdit)
        defaultPropertyCountyValue = this.opportunity.opportunitySelectionModel.countyId ? this.opportunity.opportunitySelectionModel.countyId : 0;
        else if (this.hasDefaultSeletions)
        defaultPropertyCountyValue = this.defaultSelections.countyId;

        this.opportunityForm.get('opportunitySelectionModel').patchValue({
          countyId: defaultPropertyCountyValue
        });
      }
    );
  }

  onGetPropertyLocalitiesByCountyId(seclectedCounty: number) {
    this.propertyService.getPropertyLocalitiesByCounty(seclectedCounty).subscribe(
      (propertyLocalities: PropertyLocality[]) => {
        // - Store the retrieved products as options for multiselect
        this.PropertyLocalitiesOptions = [];
        propertyLocalities.map(p => {
          this.PropertyLocalitiesOptions.push({ label: p.localityName, value: p.localityId });
        }
        );
        // this.populateLocalityValues(this.localityOptionList);
      }
    );
  }

  onGetPropertyLocalitiesByRegionId(seclectedRegion: number) {
    this.propertyService.getPropertyLocalitiesByRegion(seclectedRegion).subscribe(
      (propertyLocalities: PropertyLocality[]) => {
        // - Store the retrieved products as options for multiselect
        this.PropertyLocalitiesOptions = [];
        propertyLocalities.map(p => {
          this.PropertyLocalitiesOptions.push({ label: p.localityName, value: p.localityId });
        }
        );
        // this.populateLocalityValues(this.localityOptionList);
      }
    );
  }

  populateRegionValues(propertyRegions: number[]) {
    console.log('propertyRegions')
    console.log(propertyRegions)
    if (!this.appSetting.useRegionCounty){
      this.opportunityForm.get('opportunitySelectionModel.regionIds').setValue(propertyRegions);
    } else {
      this.opportunityForm.get('opportunitySelectionModel.regionId').setValue(propertyRegions);
    }
  }

  populateCountyValues(propertyCounties: number[]) {
    this.opportunityForm.get('opportunitySelectionModel.countyId').setValue(propertyCounties);
  }

  onGetPropertyLocalities(SelectedRegions: number[], selectedCountry: number) {
    this.propertyService.getPropertyLocalities(SelectedRegions,selectedCountry).subscribe(
      (propertyLocalities: PropertyLocality[]) => {
        // - Store the retrieved products as options for multiselect
        this.localityOptionList = [];
        propertyLocalities.map(p => {
          this.localityOptionList.push(p.localityId);
        });
        this.populateLocalityValues(this.localityOptionList);
      }
    );
  }

  populateLocalityValues(propertyLocalities: number[]) {
    this.opportunityForm.get('opportunitySelectionModel.localityIds').setValue(propertyLocalities);
  }

  onGetPropertyLocalitiesOnLoad(propertyLocaltyIds: number[]) {
    this.propertyService.getPropertyLocalitiesAll().subscribe(
      (propertyLocalities: PropertyLocality[]) => {
        // - Store the retrieved products as options for multiselect
        this.PropertyLocalitiesOptions = [];
        propertyLocalities.map(p => {
          this.PropertyLocalitiesOptions.push({ label: p.localityName, value: p.localityId });
        }
        
        );
        this.populateLocalityValues(propertyLocaltyIds);
      }
    );
  }

  // cleanLocalityValuesOnLoad(propertyLocalities: number[]) {
  //   this.opportunityForm.get('opportunitySelectionModel.localityIds').setValue(propertyLocalities);
  // }

  onGetPropertyRegions() {
    this.propertyService.getPropertyRegions().subscribe(
      (propertyRegions: PropertyRegion[]) => {
        // - Store the retrieved products as options for multiselect
        propertyRegions.map(p => {
          this.PropertyRegionsOptions.push({ label: p.regionName, value: p.regionId });
        });
      }
    );
  }

  onGetPropertyCountries() {
    this.propertyService.getPropertyCountries().subscribe(
      (propertyCountries: PropertyCountry[]) => {
        // - Store the retrieved products as options for multiselect
        propertyCountries.map(p => {
          this.PropertyCountryOptions.push({ label: p.countryName, value: p.countryId });
        });
        if(propertyCountries.length > 2)//show country if it has more than the first country and  the "Any" option
        {
          this.showCountry = true;
        }
      }
    );
  }

  onGetPropertyDealType() {
    this.propertyService.getPropertyDealTypes().subscribe(
      (propertyDealTypes: PropertyDealType[]) => {
        // - Store the retrieved products as options for multiselect

        propertyDealTypes.map(p => {

          this.PropertyDealTypesOptions.push({ label: p.dealTypeName, value: p.dealTypeId });
          
        });

        var resSale = propertyDealTypes.find(x => x.dealTypeCode == 'RSLS');
        var resLet = propertyDealTypes.find(x => x.dealTypeCode == 'RLET');

        /* this.opportunityForm.patchValue({
          assignedUserAccountId: defaultValue
        }); */

        //dealTypeIds: this.formBuilder.control(this.opportunity.opportunitySelectionModel ? this.opportunity.opportunitySelectionModel.dealTypeIds : null, [Validators.required]),
      }
    );
  }

  onGetFinishingType(){
    this.propertyService.getFinishingTypes().subscribe(
      (finishingTypes: FinishingType[]) => {
        finishingTypes.map(p => {

          this.FinishingTypeOptions.push({ label: p.name, value: p.id });
          
        });
      }
    );
  }

  onGetFurnishingType(){
    this.propertyService.getFurnishingTypes().subscribe(
      (furnishingTypes: FurnishingType[]) => {
        furnishingTypes.map(p => {

          this.FurnishingTypeOptions.push({ label: p.name, value: p.id });
          
        });
      }
    );
  }

  onGetPropertyRooms() {
    this.propertyService.getPropertyRooms().subscribe(
      (propertyRooms: PropertyAttribute[]) => {
        // - Store the retrieved products as options for multiselect
        propertyRooms.map(p => {
          this.PropertyRoomsOptions.push({ label: p.attributeName, value: p.attributeId });
        });
      }
    );
  }

  onGetPropertyFeatures() {
    this.propertyService.getPropertyFeatures().subscribe(
      (propertyFeatures: PropertyAttribute[]) => {
        // - Store the retrieved products as options for multiselect
        propertyFeatures.map(p => {
          this.PropertyFeaturesOptions.push({ label: p.attributeName, value: p.attributeId });
        });
      }
    );
  }

  onGetRentalPriceType() {
    this.propertyService.getRentalPriceTypes().subscribe(
      (rentalPriceTypes: RentalPriceType[]) => {
        // - Store the retrieved products as options for multiselect
        rentalPriceTypes.map(p => {
          this.PropertyRentalPriceTypeOptions.push({ label: p.rentalPriceTypeName, value: p.rentalPriceTypeId });
        });
        let defaultPriceTypeValue = 0;
        if (this.isEdit)
          defaultPriceTypeValue = this.opportunity.opportunitySelectionModel.rentalPriceTypeId ? this.opportunity.opportunitySelectionModel.rentalPriceTypeId : 0;

        this.opportunityForm.get('opportunitySelectionModel').patchValue({
          rentalPriceTypeId: defaultPriceTypeValue
        });
      }
    );
  }

  onPostPropertyDetails(oppSelection: OpportunitySelection) {
    this.propertyService.postPropertyDetails(oppSelection).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The property details has been created.");
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding property details", error);
      }
    );
  }

  onUpdatePropertyDetails(oppSelection: OpportunitySelection) {
    this.propertyService.updatePropertyDetails(oppSelection.opportunityId, oppSelection).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The property details has been updated.");
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating property details", error);
      }
    );
  }

  onGetPropertyDetails(id: number) {
    this.propertyService.getPropertyDetails(id, this.propertyPage ,this.propertyPageSize).subscribe(
      (propertyOppSelection: PropertyOppSelection) => {
        // - Store the retrieved products as options for multiselect
        this.opportunity.opportunitySelectionModel = propertyOppSelection.oppSelections.oppSelectionModel;
        this.Properties = propertyOppSelection.properties;
        
        this.allPropertyLoaded = this.Properties.length < this.propertyPageSize;

        this.SortPropertyPerPreferences();

        //this.sortByNotIntrested();
        this.initForm();
      }
    );
  }

  GetMoreProperties() {
    
    this.propertyPage++;
    this.propertyService.getPropertyDetails(this.opportunity.id, this.propertyPage, this.propertyPageSize).subscribe(
      (propertyOppSelection: PropertyOppSelection) => {
        // - Store the retrieved products as options for multiselect
        this.allPropertyLoaded = propertyOppSelection.properties.length < this.propertyPageSize;

        for (var it of propertyOppSelection.properties) {
          if (this.Properties.find(x => x.propertyId == it.propertyId) == null) {
            this.Properties.push(it);
          }
        }

        this.SortPropertyPerPreferences();

      }
    );
  }

  onGetProducts() {
    this.productService.getProducts().subscribe(
      (products: Product[]) => {
        // - Store the retrieved products as options for multiselect
        products.map(p => {
          this.productsOptions.push({ label: p.name, value: p.id });
        });

        if (this.isEdit) {
          this.opportunityForm.patchValue({
            productIds: this.opportunity.opportunityProducts.map(oP => oP.productId)
          });
        }
      }
    );
  }

  onGetOpportunity(id: number) {
    this.opportunityService.getOpportunity(id).subscribe(
      (opportunity: Opportunity) => {
        this.opportunity = opportunity;
        this.onGetPropertyDetails(id);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunity", error);
        this.router.navigate(['/error/404']);
      }
    );
  }

  onGetOpportunityTypes() {
    this.opportunityTypeService.getOpportunityTypes().subscribe(
      (opportunityTypes: OpportunityType[]) => {
        // Store retrieved opportunity types in the opportunityTypesOptions select list
        opportunityTypes.map(cT => this.opportunityTypesOptions.push({ label: cT.name, value: cT.id }));

        let defaultValue = 0;

        if (!this.isEdit)
          defaultValue = this.opportunityTypesOptions[0].value;
        else
          defaultValue = this.opportunity.opportunityType.id;

        this.opportunityForm.patchValue({
          opportunityTypeId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving opportunity types", error);
      }
    );
  }

  onGetOpportunityLostTypes() {
    this.opportunityLostTypeService.getOpportunityLostTypes().subscribe(
      (opportunityLostTypes: OpportunityLostType[]) => {
        // Store retrieved opportunity types in the opportunityLostTypesOptions select list
        opportunityLostTypes.map(cT => this.opportunityLostTypesOptions.push({ label: cT.name, value: cT.id }));

        let defaultValue = null;

        if (this.isEdit)
          defaultValue = this.opportunity.opportunityLostType ? this.opportunity.opportunityLostTypeId : null;

        this.opportunityForm.patchValue({
          opportunityLostTypeId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving lost opportunity types", error);
      }
    );
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // Store retrieved users in the usersOptions select list
        users.map(u => this.assignedUserOptions.push({ label: u.fullName, value: u.id }));

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.authService.applicationProfileUser().id;
        else
          defaultValue = this.opportunity.assignedUserAccountId ? this.opportunity.assignedUserAccountId : this.authService.applicationProfileUser().id;

        this.opportunityForm.patchValue({
          assignedUserAccountId: defaultValue
        });
      }
    );
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: ContactSearchItem[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {
          let labelText = cT.refCode ? "Ref: " + cT.refCode : "";
          cT.fullName.trim().length > 0 ? labelText += " / Name: " + cT.fullName : "NO-NAME";
          // cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.companyName ? labelText += " / Company: " + cT.companyName : "";
          cT.telephone && !this.appSetting.isHideSensitiveInfo ? labelText += " / Tel: " + cT.telephone : "";
          cT.mobile && !this.appSetting.isHideSensitiveInfo ? labelText += " / Mob: " + cT.mobile : "";
          cT.contactEmail && !this.appSetting.isHideSensitiveInfo ? labelText += " / Email: " + cT.contactEmail : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.assignedContactOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  onGetViewings(contactId: number) {
    this.opportunityService.getViewingsByContactId(contactId).subscribe(
      (viewed: number[]) => {
        this.Viewings = viewed;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving viewed properties", error);
      }
    );
  }

  onAddOpportunity(newOpportunity: Opportunity, event) {
    this.opportunityService.addOpportunity(newOpportunity).subscribe(
      (response: Response) => {
        let oppSelection = new OpportunitySelection();
        oppSelection.opportunityId = (<any>response).id;
        oppSelection.oppSelectionModel = newOpportunity.opportunitySelectionModel;
        oppSelection.assignedUserAccountId = newOpportunity.assignedUserAccountId;
        this.onPostPropertyDetails(oppSelection);
        this.toastService.createSuccessMessage("Success", "The opportunity " + newOpportunity.name + " has been created.");

        this.confirmationService.confirm({
          header: 'New Task',
          message: 'Would you like to create a follow up task for this opportunity?',
          accept: () => {
            this.router.navigate(['/tasks/new'], { queryParams: { contact: newOpportunity.assignedContactId, opportunity: (<any>response).id } });
          },
          reject: () => {
            if (event == "ToList"){
              this.router.navigate(['/opportunities/']);
            }
            else if (event == "Refresh") {
              let uri = "/opportunities/" + oppSelection.opportunityId + "/edit";
              this.router.navigate([uri]);
            }
          }
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding opportunity", error);
      }
    );
  }

  onChangeMainCatogory() {
    if(!this.isLoadingPage)
    {
      var selectedMainCat = this.opportunityForm.get('opportunitySelectionModel.mainCatIds').value;
      this.onGetSubCategories(selectedMainCat);
      // if (selectedMainCat != null && selectedMainCat.length > 0) {

      //   this.opportunityForm.get('opportunitySelectionModel.subCatIds').enable();
      // }
      // else {
      //   this.opportunityForm.get('opportunitySelectionModel.subCatIds').disable();
      // }
    }
  }

  // onChangeRegion() {
  //   if(!this.isLoadingPage)
  //   {
  //     var selectedReg = this.opportunityForm.get('opportunitySelectionModel.regionIds').value;
  //     var selectedCountry;
  //     if(this.showCountry)
  //     { 
  //       selectedCountry= this.opportunityForm.get('opportunitySelectionModel.countryId').value;
  //     }
  //     else
  //     {
  //       selectedCountry = 0;
  //     }
  //     this.onGetPropertyLocalities(selectedReg,selectedCountry);
  //     // if (selectedReg != null && selectedReg.length > 0) {

  //     //   this.opportunityForm.get('opportunitySelectionModel.localityIds').enable();
  //     // }
  //     // else {
  //     //   this.opportunityForm.get('opportunitySelectionModel.localityIds').disable();
  //     // }
  //   }
  // }

  onChangeCountry() {
    if(!this.isLoadingPage)
    {
      var selectedCountry = this.opportunityForm.get('opportunitySelectionModel.countryId').value;
      this.onGetPropertyRegionsByCountryId(selectedCountry);
      // if (selectedReg != null && selectedReg.length > 0) {

      //   this.opportunityForm.get('opportunitySelectionModel.localityIds').enable();
      // }
      // else {
      //   this.opportunityForm.get('opportunitySelectionModel.localityIds').disable();
      // }
    }
  }

  onChangeRegion() {
    if(!this.isLoadingPage)
    {
      

      if (this.appSetting.useRegionCounty) {
        var selectedRegion = this.opportunityForm.get('opportunitySelectionModel.regionId').value;
        this.onGetPropertyCountiesByRegionId(selectedRegion);
      } else {
        var selectedReg = this.opportunityForm.get('opportunitySelectionModel.regionIds').value;
        var selectedCountry;
        if(this.showCountry)
        { 
          selectedCountry= this.opportunityForm.get('opportunitySelectionModel.countryId').value;
        }
        else
        {
          selectedCountry = 0;
        }
        this.onGetPropertyLocalities(selectedReg,selectedCountry);
      }
    }
  }

  onChangeCounty() {
    if(!this.isLoadingPage)
    {
      var selectedCounty = this.opportunityForm.get('opportunitySelectionModel.countyId').value;
      this.onGetPropertyLocalitiesByCountyId(selectedCounty);
    }
  }

  onTogglePropertyFavorite(property: Property) {
    
    this.opportunityService.togglePropertyFavorite(this.opportunity.id, property.propertyId ).subscribe(
      (notInterested: string) => {
        property.isFavorite = !property.isFavorite; 
        this.SortPropertyPerPreferences();
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving not interested properties", error);
      }
    );

  }

  onUpdateOpportunity(newOpportunity: Opportunity, event) {
    
    this.opportunityService.updateOpportunity(newOpportunity).subscribe(
      (response: Response) => {
        let oppSelection = new OpportunitySelection();
        oppSelection.opportunityId = newOpportunity.id;
        oppSelection.oppSelectionModel = newOpportunity.opportunitySelectionModel;
        oppSelection.assignedUserAccountId = newOpportunity.assignedUserAccountId;
        this.onUpdatePropertyDetails(oppSelection);
        this.toastService.createSuccessMessage("Success", "The opportunity " + newOpportunity.name + " has been updated.");
        
        if (event == "ToList"){
          this.router.navigate(['/opportunities/']);
        }
        else if (event == "Refresh") {
          window.location.reload();
        }
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating opportunity", error);
      }
    );
  }
  toggleSelect(event, propertyId){
    if ( event.target.checked ) {
      this.NotFavoriteViewingIds.push(propertyId)
    }
    else{
      this.NotFavoriteViewingIds = this.NotFavoriteViewingIds.filter(id => id !== propertyId);
    }
    
  }

  onSubmit(event) {
    let newOpportunity = this.formBuilder.sanitizeFormValues(this.opportunityForm).value;

    // This is to pass correct value (contact id) from the autocomplete selection and to sanitize it 
    // (Eg: writing random string in search and submit form would return an api error response)
    if (newOpportunity.assignedContactId && newOpportunity.assignedContactId.value)
      newOpportunity.assignedContactId = newOpportunity.assignedContactId.value;
    else
      newOpportunity.assignedContactId = null;

    newOpportunity.opportunityProducts = this.populateOpportunityProducts(newOpportunity.productIds);

    if (this.isEdit) {
      newOpportunity.id = this.opportunity.id; // - Set id of edited opportunity to its original id, same with externalId and createdById
      newOpportunity.updatedByUserAccountId = this.authService.applicationProfileUser().id;
      this.onUpdateOpportunity(newOpportunity, event);
    }
    else {
      newOpportunity.createdByUserAccountId = this.authService.applicationProfileUser().id;
      this.onAddOpportunity(newOpportunity, event);
    }
  }

  onViewing(){   
    if (this.NotFavoriteViewingIds.length == 0) {
      this.toastService.createWarningMessage("No Properties Selected","You have to check at least one property to create a Viewing.");
    } else {
      window.open('/tasks/new?contact='+this.opportunity.assignedContactId+'&viewingtype=Viewing&opportunity='+this.opportunity.id+'&propId='+this.NotFavoriteViewingIds.join(','), '_blank');
    }
  }

  populateOpportunityProducts(assignedProductIds: number[]) {
    let opportunityProducts: {}[] = [];

    for (let id of assignedProductIds) {
      let opportunityProduct = {
        productId: id,
        opportunityId: this.opportunity.id,
        createdByUserAccountId: this.authService.applicationProfileUser().id,
      };
      opportunityProducts.push(opportunityProduct);
    }

    return opportunityProducts;
  }

  filterInReap() {
    let c = this.opportunity.assignedContact;
    var title = (c.fullName.trim().length > 0 ? c.fullName : "NO-NAME") + " - " + this.opportunity.name;
    window.open(this.reapUri + "/product/?oppid=" + this.opportunity.id + "&title=" + title, "_blank");
  }

  SortPropertyPerPreferences() {
    this.FavoriteProperties =  this.Properties.filter(x => x.isFavorite);
    this.NotInterestedProperties = this.Properties.filter(x => x.isNotInterested);
    this.NotFavoriteProperties = this.Properties.filter(x => !x.isFavorite && !x.isNotInterested);

  }

  displayAddress(index: number, productPrecode: string, propertyId: number){
    this.isAddressVisible[index] = true;
    this.auditService.auditViewAddress(productPrecode, propertyId);
  }

  displayOwners(index: number, productPrecode: string, propertyId: number){
    this.areOwnersVisible[index] = true;
    this.auditService.auditViewOwners(productPrecode, propertyId);
  }

  UpdateLastContactedDate(propId)
  {
      //send to crm
      this.propertyService.UpdateLastContactedDate(propId).subscribe(
        (response: Response) => {
          this.toastService.createSuccessMessage("Success", "The last contacted date has been updated.");
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error updating last contacted date", error);
        }
      );
  }
}
