import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormArray } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { CustomFormBuilder } from "../../../shared/classes/CustomFormBuilder";

import { CommunicationHistoryService } from "../../../shared/services/communication-history.service";
import { CommunicationHistoryTypeService } from "../../../shared/services/communication-history-type.service";
import { UserService } from "../../../shared/services/user.service";
import { AuthService } from "../../../auth/auth.service";
import { ContactService } from "../../../shared/services/contact.service";
import { TaskService } from "../../../shared/services/task.service";
import { ToastService } from "../../../shared/services/toast.service";

import { CommunicationLog } from "../../../shared/models/communication-log.model";
import { CommunicationLogType } from "../../../shared/models/communication-log-type.model";
import { CrmUser } from "../../../shared/models/user.model";
import { Contact } from "../../../shared/models/contact.model";
import { Task } from "../../../shared/models/task.model";
import { UserTask } from "../../../shared/models/user-task.model";
import { Property } from 'app/views/shared/models/property.model';
import { PropertyIdCode } from 'app/views/shared/models/property-id-codes.model';
import { environment } from 'environments/environment';

import { SelectItem, ConfirmationService, AutoComplete } from "primeng/primeng";
import { ContactTask } from "../../../shared/models/contact-task.model";
import { CommunicationDirection, CommunicationLogTypeNames, PrepopulatedTaskTypes } from 'app.constant';
import { PrivateModeService } from 'app/views/shared/services/private-mode.service';
import { Subscription } from 'rxjs';
import { TenantAppSettingService } from 'app/views/auth/tenant-app-setting.service';
import { AuditService } from 'app/views/shared/services/audit.service';
import { PropertyService } from 'app/views/shared/services/property.service';

@Component({
  selector: 'app-communication-history-manage',
  templateUrl: './communication-history-manage.component.html',
  styleUrls: ['./communication-history-manage.component.scss']
})
export class CommunicationHistoryManageComponent implements OnInit, OnDestroy {
  
  @Input() screenCtxt: string;

  screenType: string = null;
  
  communicationLogForm: FormGroup;          // - Stores the communication log form's values
  isFormInit: boolean = false;              // - The form is not created until it is initialised
  isEdit: boolean = false;                  // - The form is set to update a communication log if true and add a new communication log if false

  communicationLog: CommunicationLog = new CommunicationLog();  // - Stores CommunicationLog to populate form with a new / existing contact's values
  assignedContact: Contact = new Contact();                     // - The contact assigned to this task
  opportunityId: number = 0;                                    // - The id of a contact's opportunity (creates log for that opportunity, separate from contacts-general)
  complaintId: number = 0;                                      // - The id of a contact's complaint (creates log for that complaint, separate from contacts-general)

  usersOptions: SelectItem[] = [];                  // - Stores User Dropdown Options
  communicationLogTypesOptions: SelectItem[] = [];  // - Stores CommunicationLogType Dropdown Options

  isAssignAgentDisabled: boolean = true;   // - Only enabled when edit and if admin and comm-type is 'Website'
  logTypesNotFromWebsite: string[] = CommunicationLogTypeNames.LogTypesNotFromWebsite;

  isAddressVisible: boolean[] = [];

  // -- for Property
  @ViewChild('propertiesAutoComplete') private propertiesAutoComplete: AutoComplete;
  propCodeOptions: SelectItem[] = [];
  PropertiesSelected: Property[] = [];
  get propertiesArray() {
    return this.communicationLogForm.get('properties') as FormArray;
 }

  // - Words in description that trigger a dialog box to create a follow up task
  followUpTriggerKeyWords: string[] = [
    "follow-up", "follow up", "followup",
    "folow-up", "folow up", "folowup",
    "call", "send", "task", "chase",
    "meet", "meeting",
    "set up", "set-up", "setup"
  ];

  //** DatePicker */
  maxDate: Date = new Date(); // - Can't select a date after the current day
  yearRange: string = this.maxDate.getFullYear() - 10 + ':' + this.maxDate.getFullYear(); // - 10 Year Range

  //private Mode
  privateMode;
  private privateModeSubscription: Subscription;

  constructor(
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private communicationHistoryService: CommunicationHistoryService,
    private communicationHistoryTypeService: CommunicationHistoryTypeService,
    private contactService: ContactService,
    private userService: UserService,
    private taskService: TaskService,
    private authService: AuthService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private privateModeService: PrivateModeService,
    private appSetting: TenantAppSettingService,  // used on HTML
    private auditService: AuditService,
    private propertyService: PropertyService
  ) { }

  ngOnInit() {
    this.screenType = this.screenCtxt;
    this.setupForm();
    this.privateModeSubscription = this.privateModeService.privateModeEmitter.subscribe(privateMode => {
      this.privateMode = privateMode;
    });
  }

  ngOnDestroy(): void {
    this.privateModeSubscription.unsubscribe();
  }

  setupForm() {
    this.route.parent.params.subscribe(
      (parentParams: Params) => {
        let assignedContactId = parentParams['contactId'];

        if (assignedContactId != 'all') {
          this.contactService.getContact(assignedContactId).subscribe(
            (contact: Contact) => {
              this.assignedContact = contact;

              let opportunityId = parentParams['opportunityId'];
              if (opportunityId)
                this.opportunityId = +opportunityId;
              else
                this.opportunityId = 0;
              
              let complaintId = parentParams['complaintId'];
              if (complaintId)
                this.complaintId = +complaintId;
              else
                this.complaintId = 0;
            },
            (error: Response) => {
              this.toastService.createErrorMessage("Error retrieving contact", error);
              this.router.navigate(['/error/404']);
            }
          );
        }
      }
    );

    this.route.params.subscribe(
      (params: Params) => {
        const communicationLogId = params['communicationLogId'];
        this.isEdit = communicationLogId != null; // - Returns true if an id for a communication log was present within the url

        if (this.isEdit)
          this.onGetCommunicationLog(communicationLogId);
        else
          this.initForm();
      }
    );

  }

  initForm() {
    this.communicationLogForm = this.formBuilder.group({
      occurredOn: this.formBuilder.control(
        this.communicationLog.occurredOn ? new Date(this.communicationLog.occurredOn) : new Date(),
        [Validators.required]
      ),

      assignedUserAccountId: this.formBuilder.control(
        this.communicationLog.assignedUserAccount ?
          this.communicationLog.assignedUserAccount.id !== 0 ?
            this.communicationLog.assignedUserAccount.id
            : this.authService.applicationProfileUser().id
          : null
      ),

      communicationLogTypeId: this.formBuilder.control(this.communicationLog.communicationLogType.id, [Validators.required]),
      direction: this.formBuilder.control(
        this.communicationLog.direction ?
          this.communicationLog.direction :
          CommunicationDirection.Outgoing, [Validators.required]
      ),
      description: this.formBuilder.control(this.communicationLog.description, [Validators.required]),
      properties: this.formBuilder.array([])
    });

    this.onGetCommunicationLogTypes();
    this.onGetUsers();

    if (this.isEdit) {
      if (this.authService.isAdmin()) {
        // Assign agent field is editable by admins if the comm log type is from Websites
        if (this.logTypesNotFromWebsite.includes(this.communicationLog.communicationLogType.name))
          this.isAssignAgentDisabled = true;
        else
          this.isAssignAgentDisabled = false;
      }
      else
        this.communicationLogForm.disable(); // Non-admins may not edit

      //// Trying to let non-admins edit their own comm. logs (use createdById instead maybe?)
      //// Error: Form does not change from disable to enable (or vice verse)...
      // if (this.authService.getRole() === 'user') {
      //   if (this.authService.getUser().id !== this.communicationLog.assignedUser.id) {
      //     this.communicationLogForm.disable();
      //   } else {
      //     this.communicationLogForm.enable();
      //   }
      // }
      this.onGetProperties();

    }

    this.isFormInit = true;
  }

  onGetCommunicationLog(id: number) {
    this.communicationHistoryService.getCommunicationLog(id).subscribe(
      (communicationLog: CommunicationLog) => {
        this.communicationLog = communicationLog;
        this.initForm();
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication log", error);
        this.router.navigate(['/error/404']);
      }
    );
  }

  onGetCommunicationLogTypes() {
    this.communicationHistoryTypeService.getCommunicationLogTypes().subscribe(
      (communicationLogTypes: CommunicationLogType[]) => {
        this.communicationLogTypesOptions = [];

        // Store retrieved communicationLogTypes in the communicationLogTypesOptions select list
        communicationLogTypes.map(lT => this.communicationLogTypesOptions.push({ label: lT.name, value: lT.id }));
        if (!this.isEdit) {
          // Only have 'Comm Logs created from Website' as an option if the current log is of that kind          
          // this.communicationLogTypesOptions = this.communicationLogTypesOptions
          //   .filter(cT => this.logTypesNotFromWebsite.includes(cT.label));
          //   console.log(this.logTypesNotFromWebsite);

          this.communicationLogForm.patchValue({
            communicationLogTypeId: this.communicationLogTypesOptions[0].value // - Set first option as default in Communication Log Type dropdown
          });
        } else {
          // Only have 'Comm Logs created from Website' as an option if the current log is of that kind
          if (this.logTypesNotFromWebsite.includes(this.communicationLog.communicationLogType.name))
            this.communicationLogTypesOptions = this.communicationLogTypesOptions
              .filter(cT => this.logTypesNotFromWebsite.includes(cT.label));
            
          this.communicationLogForm.patchValue({
            communicationLogTypeId: this.communicationLog.communicationLogType.id
          });
        }
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication log types", error);
      }
    );
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        this.usersOptions = [];
        // Store retrieved users in the usersOptions select list
        users.map(u => this.usersOptions.push({ label: u.fullName, value: u.id }));

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.authService.applicationProfileUser().id;
        else
          defaultValue = this.communicationLog.assignedUserAccountId;

        this.communicationLogForm.patchValue({
          assignedUserAccountId: defaultValue
        });
      }
    );
  }

  onAddCommunicationLog(communicationLog: CommunicationLog) {
    this.communicationHistoryService.addCommunicationLog(communicationLog).subscribe(
      (response: Response) => {
        this.communicationHistoryService.logsChanged.next(); // Let list component know of changes
        this.toastService.createSuccessMessage("Success", "A new communication log has been created for " + this.assignedContact.fullName + ".");

        let showDialog = false;
        let descriptionLowerCase = communicationLog.description.toLowerCase();

        for (let keyword of this.followUpTriggerKeyWords) {
          if (descriptionLowerCase.includes(keyword)) {
            showDialog = true;
            break;
          }
        }

        if (showDialog)
          this.onAskForNewTask();
        else
          if (this.opportunityId > 0)
            this.router.navigate(['contacts', this.assignedContact.id, 'opportunity', this.opportunityId, 'communication-history']);
          else if(this.complaintId > 0)
            this.router.navigate(['contacts', this.assignedContact.id, 'complaint', this.complaintId, 'communication-history']);  
          else
            this.router.navigate(['contacts', this.assignedContact.id, 'communication-history']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding communication log", error);
      }
    );
  }

  onUpdateCommunicationLog(communicationLog: CommunicationLog, displayNotification: boolean = true) {
    this.communicationHistoryService.updateCommunicationLog(communicationLog).subscribe(
      (response: Response) => {
        this.communicationHistoryService.logsChanged.next(); // Let list component know of changes

        if (displayNotification)
          this.toastService.createSuccessMessage("Success", "The communication log for " + this.assignedContact.fullName + " has been updated.");

        if (this.opportunityId > 0)
          this.router.navigate(['contacts', this.assignedContact.id, 'opportunity', this.opportunityId, 'communication-history']);
        else if(this.complaintId > 0)
          this.router.navigate(['contacts', this.assignedContact.id, 'complaint', this.complaintId, 'communication-history']);  
        else
          this.router.navigate(['contacts', this.assignedContact.id, 'communication-history']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating communication log", error);
      }
    );
  }

  onAskForNewTask() {
    let contact = this.assignedContact;

    this.confirmationService.confirm({
      key: 'newTask',
      header: 'Follow-up Task',
      message: 'The communication log has been created. Would you now like to create a new task with ' + contact.fullName + '?',
      accept: () => {
        this.router.navigate(['/tasks/new'], { queryParams: { contact: contact.id } });
      },
      reject: () => {
        this.router.navigate(['contacts', contact.id, 'communication-history']);
      }
    });
  }

  onAssignWebsiteEnquiry(communicationLog: CommunicationLog) {
    // Create new task object to post
    let newTask: any = {
      createdByUserAccountId: this.authService.applicationProfileUser().id,
      taskDescription: communicationLog.description,
      reminderDate: communicationLog.occurredOn,
      contactTasks: [{
        contactId: communicationLog.contactId,
        createdByUserAccountId: this.authService.applicationProfileUser().id
      }],
      userTasks: [{
        userId: communicationLog.assignedUserAccountId,
        createdByUserAccountId: this.authService.applicationProfileUser().id
      }],
      taskTypeName: PrepopulatedTaskTypes.CommunicationLog
    };

    let userName = this.usersOptions.find(u => u.value === communicationLog.assignedUserAccountId).label;

    // Create new task if no task was already assigned to someone
    if (!this.communicationLog.task) {
      this.taskService.addTask(newTask, true, false).subscribe(
        (response: Response) => {
          communicationLog.taskId = (<any>response).id; // - Set task id for communication log
          this.onUpdateCommunicationLog(communicationLog, false);
          this.toastService.createSuccessMessage("Success", "A new task has been assigned to " + userName + ".");
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error adding task", error);
        }
      );
    }
    // Update task with newly assigned user to replace previously assigned user
    else {
      newTask.id = this.communicationLog.task.id; // - Set id of edited task to its original id
      newTask.updatedByUserAccountId = this.authService.applicationProfileUser().id;

      this.taskService.updateTask(newTask, true, false).subscribe(
        (response: Response) => {
          communicationLog.taskId = (<any>response).id; // - Set task id for communication log          
          this.onUpdateCommunicationLog(communicationLog, false);
          this.toastService.createSuccessMessage("Success", "This task has now been assigned to " + userName + ".");
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error updating task", error);
        }
      );
    }
  }

  onSubmit() {

    
    let newCommunicationLog = this.formBuilder.sanitizeFormValues(this.communicationLogForm).value;
    console.log(newCommunicationLog);
    newCommunicationLog.contactId = this.assignedContact.id;

    if (this.isEdit) {
      newCommunicationLog.id = this.communicationLog.id; // - Set id of edited communication log to its original id
      newCommunicationLog.updatedByUserAccountId = this.authService.applicationProfileUser().id;

      // If comm-log-type is website then also create a task and assign it to a user
      if (!this.logTypesNotFromWebsite.includes(this.communicationLog.communicationLogType.name)) {
        this.onAssignWebsiteEnquiry(newCommunicationLog);
      }
      // Otherwise, just update the communication log 
      else {
        this.onUpdateCommunicationLog(newCommunicationLog);
      }
    }
    else {
      newCommunicationLog.createdByUserAccountId = this.authService.applicationProfileUser().id;

      if (this.opportunityId > 0)
      {
        newCommunicationLog.opportunityId = this.opportunityId; // - Only add opportunity id for Create, cant change which opportunity a log belongs too
        newCommunicationLog.complaintId = null; // null value just in case
      }
      if (this.complaintId > 0)
      {
        newCommunicationLog.complaintId = this.complaintId; // - Only add opportunity id for Create, cant change which opportunity a log belongs too  
        newCommunicationLog.opportunityId = null; // null value just in case
      }
      console.log(newCommunicationLog);
      this.onAddCommunicationLog(newCommunicationLog);
    }
  }


  //  -----------------------   PROPERTY Manage  ----------------------  

  searchAutoCompleteProperties(event) {
    
    if (event.query)
      this.onGetPropertySearch(event.query);
  }

  onGetPropertySearch(searchTerm: string) {   

    this.taskService.getPropertiesSearch(searchTerm).subscribe(
      (properties: PropertyIdCode[]) => {
        
        let propOpt = [];
        // - Store the retrieved property codes as options for dropdown
        properties.map(p => { propOpt.push({ label: p.propertyCode, value: p.propertyId}); });

        this.propCodeOptions = propOpt;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving properties", error);
      }
    );
    
  }

  handleDropdownProperties(event) {

    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.propertiesAutoComplete.panelVisible) {
      this.propertiesAutoComplete.hide();
    } else {
      this.propertiesAutoComplete.show();
    }
  }

  onGetProperties() {

    console.log('get PRoperty');
    const _this = this;

    /* this.route.queryParams.subscribe(function (params) {
      let paramPropertyId = params['propId'];

        if (!_this.isEdit && paramPropertyId != null)
        {
         
          //keep for the validation
         
          _this.getProperty({value: paramPropertyId});
        }
    }); */
    this.PropertiesSelected = [];
    while (this.propertiesArray.length !== 0) {
      this.propertiesArray.removeAt(0);
    }

    if (this.isEdit && this.communicationLog.properties.length > 0) {
      for(let it of this.communicationLog.properties) {
        this.getProperty({value: it.propertyId});
      }
    }

  }

  getProperty(event) {

    this.taskService.getProperty(event.value).subscribe(
      (property: Property) => {
  
        var existing = this.PropertiesSelected.find(x => x.propertyId == property.propertyId);
  
        if (existing == null) {
          this.PropertiesSelected.push(property);
          this.propertiesArray.push(this.formBuilder.control({propertyId: property.propertyId}));
        }
  
  
      });
    }
  
    removeProperty(it : any) {
      const property = this.PropertiesSelected.find(x => x.propertyId == it.propertyId);
      if (property != null) {
        this.PropertiesSelected.splice( this.PropertiesSelected.indexOf(property),1 )
  
        while (this.propertiesArray.length !== 0) {
          this.propertiesArray.removeAt(0);
       }
  
        for (let prop of this.PropertiesSelected) {
          this.propertiesArray.push(this.formBuilder.control({propertyId: prop.propertyId}));
        }
      }
  
    }

    getImageUrl(property){

      let imagUrl  = environment.REAP_URI + property.propertyImageUrl;
  
      let link = 'url(' + imagUrl + ')';
  
      return link;
    }

    displayAddress(index: number, productPrecode: string, propertyId: number){
      this.isAddressVisible[index] = true;
      this.auditService.auditViewAddress(productPrecode, propertyId);
    }

    UpdateLastContactedDate(propId)
  {
      //send to crm
      this.propertyService.UpdateLastContactedDate(propId).subscribe(
        (response: Response) => {
          this.toastService.createSuccessMessage("Success", "The last contacted date has been updated.");
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error updating last contacted date", error);
        }
      );
  }
}
