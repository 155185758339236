import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";
import { Router } from '@angular/router';

import { AuthService } from "../../auth/auth.service";
import { MailchimpListAutomationService } from "../../shared/services/mailchimp-list-automation.service";
import { ToastService } from "../../shared/services/toast.service";
import { MailchimpService } from '../../shared/services/mailchimp.service';
import { ConfirmationService } from "primeng/primeng";

import { ListAutomation } from "../../shared/models/mailchimp/list-automation.model";
import { List } from '../../shared/models/mailchimp/campaign.model';
import { Subject } from "rxjs/Rx";
import { PrepopulatedEntities } from "app.constant";

@Component({
  selector: 'app-mailchimp-contact-list-automation',
  templateUrl: './mailchimp-contact-list-automation.component.html',
  styleUrls: ['./mailchimp-contact-list-automation.component.scss']
})
export class MailchimpContactListAutomationComponent implements OnInit {
  listAutomations: ListAutomation[] = [];
  isAnim: boolean = true;

  mailchimpLists: List[] = [];

  constructor(
    public authService: AuthService,
    private router: Router,
    private listAutomationService: MailchimpListAutomationService,
    private confirmationService: ConfirmationService,
    private mailchimpService: MailchimpService,    
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.onGetMailchimpContactLists();
    this.onGetListAutomations();

    // Listen to changes made to listAutomations
    this.listAutomationService.listsChanged.subscribe(
      () => {
        this.onGetListAutomations();
      }
    );
  }

  onGetMailchimpContactLists() {
    this.mailchimpService.getLists().subscribe(
      (lists: List[]) => {
        this.mailchimpLists = lists;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving mailchimp contact lists", error);
      }
    );
  }

  onGetListAutomations() {
    this.listAutomationService.getListAutomations().subscribe(
      (listAutomations: ListAutomation[]) => {
        debugger;
        this.listAutomations = listAutomations;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving list automations", error);
      }
    );
  }

  onChangeTabView() {
    this.router.navigate(["/mailchimp"]);
  }

  onDeleteListAutomation(listAutomation: ListAutomation) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the list automation?',
      accept: () => {
        //listAutomation.updatedByUserId = this.authService.getUser().id;

        this.listAutomationService.deleteListAutomation(listAutomation).subscribe(
          (response: Response) => {
            this.onGetListAutomations();
            this.toastService.createSuccessMessage("Success", "The list automation has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting list automation", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for the list automation has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
