import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { AuthService } from "./auth.service";
import { UserService } from "../shared/services/user.service";
import { CrmUser } from "../shared/models/user.model";
import { Observable } from "rxjs/Observable";
import { TenantAppSettingService } from './tenant-app-setting.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private tenantAppSettingService: TenantAppSettingService,
        private userService: UserService,
        private route: ActivatedRoute
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
      let loggedIn = false;
      const claims = route.data;

      
      
      // Hot or Cold Observables - Make it hot!
      loggedIn = this.authService.loggedIn;
     // 
      const self = this;
    
      sessionStorage.setItem("postSuccessfulLoginRedirectUrl", state.url);
      return this.authService.isUserLoggedIn().map(res => {
        
        // --------  Load app setting from local Storage or API if Refresh page or any 5 min
        this.tenantAppSettingService.loadTenantAppSetting();

        return res;
      });  
      // if (this.authService.isTokenValid()) {
        //     // Check if the user is currently stored and if not retrieve all users
        //     if (!this.authService.getUser()) {
        //         return this.userService.getUsers().map(
        //             (users: User[]) => {
        //                 if (users)
        //                     return true;
        //                 // Deny access if no users could be retrieved
        //                 return false;
        //             }
        //         );
        //     }
        //     else
        //         return true;
        // }
        // else {
        //     this.authService.logOut(state.url);
        //     return false;
        // }
    }
}