import { ContactType } from "./contact-type.model";
import { ContactSource } from "./contact-source.model";
import { CrmUser } from "./user.model";
import { CommunicationLog } from "./communication-log.model";

export class Contact {
    constructor(
        public id: number = 0,
        public externalId: number = 0,
        public contactType: ContactType = new ContactType(),
        public contactTypes: ContactType [] = [],
        public contactTypeId: number = null,
        public contactTypeIds: number [] = [],
        public contactSource: ContactSource = new ContactSource(),
        public contactSourceId: number = null,
        public organizationId: number = null,
        public convertedDate: string = null,
        public referredByContactId: number = null,
        public referredByContact: Contact = null,
        public firstName: string = null,
        public lastName: string = null,
        public refCode: string = null,
        public fullName: string = firstName + ' ' + lastName,
        public companyName: string = null,
        public birthdate: string = null,
        public idCardNumber: string = null,
        public email: string = null,
        public emailB: string = null,
        public mobilePhoneNumber: string = null,
        public homePhoneNumber: string = null,
        public officePhoneNumber: string = null,
        public alternatePhoneNumber: string = null,
        public notes: string = null,
        public vatNumber: string = null,
        public assignedUserAccountId: number = null,
        public assignedUser: CrmUser = null,
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number = null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public lastActionDate: string = null,
        public status: string = null,
        public addressLine1: string = null,
        public addressLine2: string = null,
        public addressLine3: string = null,
        public addressLine4: string = null,
        public postCode: string = null,
        public country: string = null,
        public location: string = null,
        public locationLat: number = null,
        public locationLng: number = null,
        public contactTypeNames: string = null,
        public doNotCall: boolean = false,
        public doNotSendEmail: boolean = false,
        public agreedToGDPR: boolean = false,
        public spouseName: string = null,
        public spouseEmail: string = null,


    ) { }
}