import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { UserManager, Log, MetadataService, User } from 'oidc-client';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { AuthService } from 'app/views/auth/auth.service';
import { Profile } from 'selenium-webdriver/firefox';
import { ProfileService } from '../profile/profile.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { DeveloperConstants, DeveloperModeHelper } from 'app/views/shared/developer.mode.service';
//import { RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";

@Component({
  selector: 'app-auth',
  providers: [CookieService],
  templateUrl: 'auth.component.html',
  styleUrls: ['auth.component.scss']
})
export class AuthComponent implements OnInit {
  //, private location:Location
  cookies: Object;

  constructor(private location: Location,
    private service: AuthService,
    private cookieService: CookieService, private router: Router,
    private _profileService: ProfileService,
    private _permissions: NgxPermissionsService,
    private _dev: DeveloperModeHelper,
    // route: ActivatedRouteSnapshot, state: RouterStateSnapshot
  ) {}

  ngOnInit() {
    Log.logger = console;
    var locObject = this.location;
    var self = this;

    // var cookServ = this.cookieService;
    self.service.mgr.signinRedirectCallback().then(function (user: User) {
      if (user == null) {
        document.getElementById("waiting").style.display = "none";
        document.getElementById("error").innerText = "No sign-in request pending.";
      }
      else {
        // var appCookies = cookServ.getAll();
        // var redirectUrl = appCookies['postSuccessfulLoginRedirectUrl']
        // self.router.navigate([redirectUrl]);
        self.service.currentUser = user;
        self.service.userLoadededEvent.emit(user);
        let redirectUrl = sessionStorage.getItem('postSuccessfulLoginRedirectUrl')
        

        // Compute check if Profile exists for the first time here.
        var authenticatedUser = user;
        var userProfile = authenticatedUser.profile;
        var subjectId = userProfile.sub;

        
 if (subjectId) {
  // self.router.navigate(['/profile', `${subjectId}`,'authorise']);
  self.onProfileCheck(subjectId, redirectUrl);

} else {
  self.router.navigateByUrl(redirectUrl);
}
       
      }
    })
      .catch(function (er) {
        console.log(er);
        console.log(er.message);

        // application not working page - error page - check metronic.
        self.router.navigate(['/auth-error']);

        // document.getElementById("waiting").style.display = "none";
        // document.getElementById("error").innerText = er.message;
      });
  }

  onProfileCheck(subjectId: string, redirectUrl: string) {
    // this._loadingService.showLoading('Please wait...');

    let vars = {
      subjectId: subjectId
    };

    this._profileService.getProfileBySubjectIdQuery(subjectId)
      .pipe(map(result => {
      
        var item = (<any>result);
        return (<any>item) as Profile;
      })
      ).subscribe((item: any) => {
        //this.item = item as Profile;
        // 
        if (item.id) {
          // this.service.appProf = item;
          this.service.setApplicationProfileUser(item);
          
          var userRole = item.appUserRoles;
          var permissions = [];
          if(userRole)
                  if(userRole[0]) {
                    if(userRole[0].appRole) 
                    if(userRole[0].appRole.appRolePermissions) {
                      userRole[0].appRole.appRolePermissions.map((elem, i) => {
                          permissions.push(elem.appPermission.claimValue);
                        }) 
                    }
                  }

                  let identityRole = '';
                  if(identityRole = this.service.currentUser.profile.role) {
                    this._dev.isDeveloper.next(true);
                    //localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(false));
                    permissions.push(identityRole);
                  } else {
                    this._dev.isDeveloper.next(false);
                    localStorage.removeItem(DeveloperConstants.IS_DEVELOPER_MODE);
                    // localStorage.setItem(DeveloperConstants.IS_DEVELOPER_MODE, JSON.stringify(false));
                  }

                  

                  localStorage.setItem("permissions", JSON.stringify(permissions));
                 this._permissions.loadPermissions(permissions);
          
          this.router.navigateByUrl(redirectUrl);
        } else {
          this.router.navigate(['/profile', `${subjectId}`, 'authorise']);
          // this._loadingService.hideLoading();
        }
      });
  }
}


// const settings: any = {
//   authority: 'http://clients.isb.com.mt/SimplyIntelligent/Auth',
//   client_id: 'js',
//   redirect_uri: 'http://clients.isb.com.mt/SimplyIntelligent/iQmePortal/auth',
//   post_logout_redirect_uri: 'http://clients.isb.com.mt/SimplyIntelligent/iQmePortal/',
//   response_type: 'id_token token',
//    scope: "openid profile iqme.write",

//   silent_redirect_uri: 'http://localhost:4200',
//   automaticSilentRenew: true,
//   //silentRequestTimeout:10000,

//   filterProtocolClaims: true,
//   loadUserInfo: true
// };
