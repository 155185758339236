import { Component, OnInit, ViewChild,ElementRef, ChangeDetectorRef, NgZone, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from '@angular/http';

import { AuditManager, PrepopulatedEntities, PrepopulatedContactTypes, PrepopulatedContactSources, GoogleMaps, PrepopulatedTypesContact } from "app.constant";

import { CustomFormBuilder } from "../../shared/classes/CustomFormBuilder";

import { ContactService } from "../../shared/services/contact.service";
import { ContactTypeService } from "../../shared/services/contact-type.service";
import { ContactSourceService } from "../../shared/services/contact-source.service";
import { OrganizationService } from "../../shared/services/organization.service";
import { AuthService } from "../../auth/auth.service";
import { ToastService } from "../../shared/services/toast.service";
import { UserService } from "../../shared/services/user.service";
import { TagService } from '../../shared/services/tag.service';
import { LeadDeskService } from '../../shared/services/lead-desk.service';

import { Contact } from "../../shared/models/contact.model";
import { ContactType } from "../../shared/models/contact-type.model";
import { ContactSource } from "../../shared/models/contact-source.model";
import { CrmUser } from "../../shared/models/user.model";
import { Tag } from '../../shared/models/tag.model';

// import { valueExistsValidator } from "../../shared/validators/value-exists.validator";
import { CustomValidators } from 'ng2-validation';
import { SelectItem, ConfirmationService, AutoComplete, DropdownModule } from "primeng/primeng";
import { Observable } from "rxjs/Rx";
import { ContactTag } from 'app/views/shared/models/contact-tag.model';
import { CallInfo } from 'app/views/shared/models/lead-desk/call-info.model';
import { LeadDeskConfig } from 'app/views/shared/models/lead-desk/lead-desk-config.model';
import { Organization } from '../../shared/models/organization.model';
import { ContactProperty } from 'app/views/shared/models/contact-property.model';
import { Property } from 'app/views/shared/models/property.model';
import { Subscription, combineLatest } from 'rxjs';
import { tap, switchMap, map } from 'rxjs/operators';
import { ContactSearchItem } from 'app/views/shared/models/contact-search-item.model';
import { UtilityService } from 'app/views/shared/services/utility.service';
import { environment } from 'environments/environment';
import { PrivateModeService } from 'app/views/shared/services/private-mode.service';
import { AuditService } from 'app/views/shared/services/audit.service';
import { TenantAppSettingService } from 'app/views/auth/tenant-app-setting.service';
import { PropertyService } from 'app/views/shared/services/property.service';


declare var google: any;
declare var Tour: any;


@Component({
  selector: 'app-contact-manage',
  templateUrl: './contact-manage.component.html',
  styleUrls: ['./contact-manage.component.scss']
})
export class ContactManageComponent implements OnInit, OnDestroy, AfterViewInit {
  contactForm: FormGroup;                         // - Stores the contact form's values
  isFormInit: boolean = false;                    // - The form is not created until it is initialised
  isEdit: boolean = false;                        // - The form is set to update a contact if true and add a new contact if false
  organizationIdFromClientCreate:number = 0;          // - Organization Id from Add Organization (needed to pre select dropdown)

  contacts: Contact[] = [];                       // - Stores all contacts retrieved from db to validate unique values
  contact: Contact = new Contact();               // - Stores Contact to populate form with a new / existing contact's values

  contactTypesOptions: SelectItem[] = [];         // - Stores Contact Type Dropdown Options
  contactSourcesOptions: SelectItem[] = [];       // - Stores Contact Source Dropdown Options
  contactOrganizationsOptions: SelectItem[] = []; // - Stores Contact Organization Dropdown Options
  referredByContactsOptions: SelectItem[] = [];   // - Stores Contacts Options to select who was the Referrer if Contact Source is set as Referrer
  assignedUserOptions: SelectItem[] = [];         // - Stores Users to select the Assigned User of this contact
  Properties: Property[] = [];
  blockSaveAsNotAssigned: boolean = false;

  isAccordOpen: boolean = false;
  isPrimaryCollapsed: boolean = false;
  private fragment: string;
  crmUri:string = '';

  @ViewChild('contactReferrersAutoComplete') private contactReferrersAutoComplete: AutoComplete;
  allTags: Tag[] = [];                            // - Stores all pre-existing Contact Tags
  filteredTags: Tag[] = [];                       // - Stores all tags filtered by a search term
  contactTags: Tag[] = [];                        // - Stores current contact's saved Contact Tags
  newTagName: string;

  @ViewChild('contactTagsAutoComplete') private contactTagsAutoComplete: AutoComplete;

  //** Used by DatePicker component */
  maxDate: Date = new Date();
  dateRange: string = (new Date().getFullYear() - 150) + ':' + new Date().getFullYear(); // - Range: 150 Years Ago until Today
  leadDeskCallInfo: CallInfo;

    //** Google Maps */
    options: any;
    overlays: any[];
    marker: any;
    isGoogleMapsSearchInit = false;
    reapUri = 'localhost';
    @ViewChild("gMapsSearch") public gMapsSearchElementRef: ElementRef;
    @ViewChild("gmap") public gmap;
    tour: any;

  //private Mode
  privateMode;
  private privateModeSubscription: Subscription;

  constructor(
    public authService: AuthService,
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private contactService: ContactService,
    private contactTypeService: ContactTypeService,
    private contactSourceService: ContactSourceService,
    private organizationService: OrganizationService,
    private leadDeskService: LeadDeskService,
    private tagService: TagService,
    private toastService: ToastService,
    private appSetting: TenantAppSettingService,  // used on HTML
    private confirmationService: ConfirmationService,
    private ref: ChangeDetectorRef,
    private utilService: UtilityService,
    private _ngZone: NgZone,
    private privateModeService: PrivateModeService,
    private auditService: AuditService,
    private propertyService: PropertyService
  ) { }

  ngOnInit() {
    this.utilService.setPageTitle('Add Contact');
    this.reapUri = environment.REAP_URI;
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });
    this.setupForm();
    if(this.router.url.indexOf('PropertySection') > -1){
      this.isAccordOpen = true;
      this.isPrimaryCollapsed = true; 
    }
    this.crmUri = window.location.origin;
    this.privateModeSubscription = this.privateModeService.privateModeEmitter.subscribe(privateMode => {
      this.privateMode = privateMode;
    });
  }

  ngOnDestroy(): void {
    this.privateModeSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    
    if(this.isEdit){

      this._ngZone.runOutsideAngular(() => {
        this.tour = new Tour({                           
            debug: true,
            storage: window.localStorage,
            name: "contactManage",                          
            backdrop: true,
        });
        this.tour.addStep({
            element: "#contact-filter-reap",
            title: "Filter In Listing",
            content: "This new button allows you to continue filtering this owner's properties in Property Listing",
            placement: 'bottom',
            backdrop: true,
        });
        
        // Initialize the tour
        const runTour = localStorage.getItem('contactManage_end');
         if(!(runTour === "yes")){
          this.tour.init();
          this.tour.start(true);
        }
    
    }); 

    }
    
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
    
    
  }

  openAccord(): void  {
    this.isAccordOpen ? this.isAccordOpen = false : this.isAccordOpen = true;
  }

  getImageUrl(prop){
    //url(' + prop.propertyUrl + prop.propertyImageUrl + ')
    let imagUrl = environment.REAP_URI + prop.propertyImageUrl;

    let link = 'url(' + imagUrl + ')';

    return link;
  }

  searchAutoCompleteForContacts(event) {
    if (event.query)
      this.onGetContactsSearch(event.query);
  }

  searchAutoComplete(event) {
    if (event.query)
      this.filteredTags = this.allTags.filter(t => t.name.toLowerCase().includes(event.query.toLowerCase()));

    // - Store new tag name if it does not exist so user may hit enter and create a new tag
    if (!this.allTags.find(t => t.name.toLowerCase() === event.query.toLowerCase()))
      this.newTagName = event.query;
  }

  handleDropdown(event) {
    // - Dropdown for autocomplete was bugged...
    // - This workaround was obtained from: https://github.com/primefaces/primeng/issues/745
    event.originalEvent.preventDefault();
    event.originalEvent.stopPropagation();
    if (this.contactReferrersAutoComplete.panelVisible) {
      this.contactReferrersAutoComplete.hide();
    } else {
      this.contactReferrersAutoComplete.show();
    }
  }

  onAutoCompleteEnterKey(event) {
    if (this.newTagName)
      this.onAddTag(this.newTagName);
  }

  onSelectTag(event: Tag) {
    let tag = event as any;
    let newContactTag = {
      contactId: this.contact.id,
      tagId: 0,
      createdByUserAccountId: this.authService.applicationProfileUser().id,
    };
    tag.contactTags.push(newContactTag);

    this.tagService.updateTag(tag).subscribe(
      (response: Response) => {
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating contact tag", error);
      }
    );
  }

  onUnselectTag(tag: Tag) {
    tag.contactTags = tag.contactTags.filter(cT => cT.contactId !== this.contact.id);

    this.tagService.updateTag(tag).subscribe(
      (response: Response) => {
        let unselectedTag = (<any>response) as Tag;
        // - Filter out unselected tag if it was marked as deleted (Is marked as deleted if no ContactTags relationships)
        if (unselectedTag.contactTags.length === 0)
          this.allTags = this.allTags.filter(t => t.id !== unselectedTag.id);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error deleting contact tag", error);
      }
    );
  }

  setupForm() {
    this.route.params.subscribe(
      (params: Params) => {
        const contactId = params['id'];
        const organizationId = params['organizationId'];
        this.isEdit = contactId != null; // - Returns true if an id was present within the url

        if(organizationId !=null){
          this.organizationIdFromClientCreate = organizationId;
        }

        if (this.isEdit) {
          this.utilService.setPageTitle('Edit Contact');
          this.contactService.getContactProperty(+contactId).subscribe(
            (contactProp: ContactProperty) => {
              this.contact = contactProp.contact;
              this.Properties = contactProp.properties;

              // check 
              if (this.appSetting.isHideSensitiveInfo) {
                this.auditService.auditOpenEditScreen(this.contact.fullName, this.contact.id);
              }

              this.initForm();
            },
            (error: Response) => {
              this.toastService.createErrorMessage("Error retrieving contact", error);
            }
          );

          // Redirect to 404 page if the selected contact could not be found
          if (!this.contact) {
            this.router.navigate(['/error/404']);
            return;
          }
          this.onGetTags();  // - Retrieve all tags to populate the Tags autocomplete suggestions list

          // contactsOptions.splice(contactsOptions.indexOf(this.contact), 1); // - Don't want current contact to be selectable as a Refferer
        }
        else
          this.initForm();
      }
    );
  }

  isCallOngoingInLeadDesk() {
    // - If the contact has a mobile phone number then check if a call is ongoing in lead desk (first check if leaddesk api key is stored)
    if (this.contact.mobilePhoneNumber) {

      this.leadDeskService.getLeadDeskConfig().subscribe(
        (leadDeskConfig: LeadDeskConfig) => {
          if (leadDeskConfig) {
            // - Check if a call is ongoing in lead desk if API key was found
            this.leadDeskService.getCallInfoByPhoneNumber(this.contact.mobilePhoneNumber).subscribe(
              (callInfo: CallInfo) => {
                this.leadDeskCallInfo = callInfo;
              },
              (error) => {
                // Could be Phone Number Not Found, wrong api key..
              }
            );
          }
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error retrieving LeadDesk config", error);
        }
      );
    }
  }

  initForm() {
    this.contactForm = this.formBuilder.group({
      firstName: this.formBuilder.control(this.contact.firstName),
      lastName: this.formBuilder.control(this.contact.lastName),
      refCode: this.formBuilder.control(this.contact.refCode),
      assignedUserAccountId: this.formBuilder.control(this.contact.assignedUserAccountId),
      email: this.formBuilder.control(this.contact.email, [Validators.required]),
      emailB: this.formBuilder.control(this.contact.emailB),
      // contactTypeId: this.formBuilder.control(this.contact.contactType.id, [Validators.required]),
      contactTypeIds: this.formBuilder.control(this.contact.contactTypeIds ? this.contact.contactTypeIds : null, [Validators.required]),
      contactSourceId: this.formBuilder.control(this.contact.contactSource.id, [Validators.required]),
      organizationId: this.formBuilder.control(this.contact.organizationId),
      referredByContactId: this.formBuilder.control(this.contact.referredByContactId),
      status: this.formBuilder.control(this.contact.status === AuditManager.StatusActive || !this.contact.status ? true : false),
      birthdate: this.formBuilder.control(this.contact.birthdate ? new Date(this.contact.birthdate) : this.contact.birthdate),
      lastActionDate: this.formBuilder.control(this.contact.lastActionDate ? new Date(this.contact.lastActionDate) : this.contact.lastActionDate),
      idCardNumber: this.formBuilder.control(this.contact.idCardNumber),
      companyName: this.formBuilder.control(this.contact.companyName),
      mobilePhoneNumber: this.formBuilder.control(this.contact.mobilePhoneNumber, [Validators.required]),
      homePhoneNumber: this.formBuilder.control(this.contact.homePhoneNumber),
      officePhoneNumber: this.formBuilder.control(this.contact.officePhoneNumber),
      alternatePhoneNumber: this.formBuilder.control(this.contact.alternatePhoneNumber),
      vatNumber: this.formBuilder.control(this.contact.vatNumber),
      notes: this.formBuilder.control(this.contact.notes),
      addressLine1: this.formBuilder.control(this.contact.addressLine1),
      addressLine2: this.formBuilder.control(this.contact.addressLine2),
      addressLine3: this.formBuilder.control(this.contact.addressLine3),
      addressLine4: this.formBuilder.control(this.contact.addressLine4),
      locationLat: this.formBuilder.control(this.contact.locationLat),
      locationLng: this.formBuilder.control(this.contact.locationLng),
      postCode: this.formBuilder.control(this.contact.postCode),
      country: this.formBuilder.control(this.contact.country),
      doNotCall: this.formBuilder.control(this.contact.doNotCall),
      doNotSendEmail: this.formBuilder.control(this.contact.doNotSendEmail),
      agreedToGDPR: this.formBuilder.control(this.contact.agreedToGDPR),
      spouseName: this.formBuilder.control(this.contact.spouseName),
      spouseEmail: this.formBuilder.control(this.contact.spouseEmail),
    });

    this.onGetContactTypes(); // - Retrieve all contact types to populate the Contact Type dropdown
    this.onGetContactSources();  // - Retrieve all contact sources to populate the Contact Source dropdown
    this.onGetContactOrganizations();  // - Retrieve all contact sources to populate the Contact Source dropdown
    this.onGetUsers();  // - Retrieve all users to populate the Assigned User dropdown

    if (this.isEdit && this.authService.isAdmin())
      this.isCallOngoingInLeadDesk(); // - If viewing a contact's details as an admin check if a call is ongoing in LeadDesk

    this.onFormValueChanges();

    // Mobile and Email are Required on start and change Required state when their value changes
    // For edit check which one should be set to Required on start
    if (this.isEdit) {
      let c = this.contact.referredByContact;

      if (c) {
        let labelText = c.fullName && c.fullName.trim().length > 0 ? c.fullName : "NO-NAME";
        c.idCardNumber ? labelText += " / ID: " + c.idCardNumber : "";
        c.companyName ? labelText += " / Company: " + c.companyName : "";
        c.homePhoneNumber ? labelText += " / Tel: " + c.homePhoneNumber : "";
        c.mobilePhoneNumber ? labelText += " / Mob: " + c.mobilePhoneNumber : "";
        c.email ? labelText += " / Email: " + c.email : "";

        let defaultSelectItem: SelectItem = {
          label: labelText,
          value: this.contact.referredByContactId
        };

        let defaultValue = this.contact.referredByContactId ? defaultSelectItem : null;

        this.contactForm.patchValue({
          referredByContactId: defaultValue
        });
      }

      if (!this.contact.email && !this.contact.mobilePhoneNumber){
        // allow to show the form even if email and mobile are empty, also allow to submit... this is validated on the Back End too
        this.contactForm.controls['email'].clearValidators();
        this.contactForm.controls['email'].updateValueAndValidity({ emitEvent: false });

        this.contactForm.controls['mobilePhoneNumber'].clearValidators();
        this.contactForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
      }
        

      // If email has no value then remove Required validator
      else if (!this.contact.email) {
        // this.contactForm.controls['email'].setValidators([
        //   valueExistsValidator(contactEmails)
        // ]);
        this.contactForm.controls['email'].clearValidators();
        this.contactForm.controls['email'].updateValueAndValidity({ emitEvent: false });
      }
      // If mobilePhoneNumber has no value then remove Required validator
      else if (!this.contact.mobilePhoneNumber) {
        this.contactForm.controls['mobilePhoneNumber'].clearValidators();
        this.contactForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
      }
    }
    this.route.queryParams.subscribe(
      (queryParam: Params) => {
        let phoneNumber = queryParam['phone'];
        if(phoneNumber != null)
        {
          this.contactForm.patchValue({
            mobilePhoneNumber: phoneNumber
          });
        }
      }
    );

    if (this.isEdit) {
      this.checkIfContactCanBeEdit() 
    }


    this.isFormInit = true;
    this.initGoogleMap();
  }

  checkIfContactCanBeEdit() {

    var notAdmin = !this.authService.isAdmin();
    if (notAdmin && this.contact.assignedUserAccountId != this.authService.applicationProfileUser().id){
      var typeContactOnlyAssigned = (this.contact as any).contactTypeReferences.find(x => x.contactType.allowEditOnlyIfAssigned);
      if (typeContactOnlyAssigned != null) {
        this.blockSaveAsNotAssigned = true;
      }
    }
  }

  

  onFormValueChanges() {
    this.onEmailChange();
    this.onMobilePhoneNumberChange();
  }

  onEmailChange() {
    this.contactForm.controls["email"].valueChanges.subscribe(
      (email: string) => {
        // If email field is empty then Mobile field is set to required
        if (email.trim() === "" && this.contactForm.controls["mobilePhoneNumber"].value !== "") {
          this.contactForm.controls['mobilePhoneNumber'].setValidators([
            Validators.required
          ]);
          this.contactForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
          this.contactForm.controls['email'].clearValidators();
          this.contactForm.controls['email'].updateValueAndValidity({ emitEvent: false });
        }
        else if (email.trim() === "") {
          this.contactForm.controls['mobilePhoneNumber'].setValidators([
            Validators.required
          ]);
          this.contactForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
        }
        // If email was provided then Mobile field is no longer required
        else {
          this.contactForm.controls['mobilePhoneNumber'].clearValidators();
          this.contactForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
        }
      }
    );
  }

  onMobilePhoneNumberChange() {
    this.contactForm.controls["mobilePhoneNumber"].valueChanges.subscribe(
      (mobilePhoneNumber: string) => {
        // const contactEmails = this.contacts.map(c => c.email);
        // if (this.isEdit)
        //   contactEmails.splice(contactEmails.indexOf(this.contact.email), 1);

        // If MobilePhoneNumber field is empty then Email field is set to required
        if (mobilePhoneNumber.trim() === "" && this.contactForm.controls["email"].value !== "") {
          // this.contactForm.controls['email'].setValidators([
          //   Validators.required,
          //   valueExistsValidator(contactEmails)
          // ]);
          this.contactForm.controls['email'].setValidators([
            Validators.required
          ]);
          this.contactForm.controls['email'].updateValueAndValidity({ emitEvent: false });
          this.contactForm.controls['mobilePhoneNumber'].clearValidators();
          this.contactForm.controls['mobilePhoneNumber'].updateValueAndValidity({ emitEvent: false });
        }
        else if (mobilePhoneNumber.trim() === "") {
          // this.contactForm.controls['email'].setValidators([
          //   Validators.required,
          //   valueExistsValidator(contactEmails)
          // ]);
          this.contactForm.controls['email'].setValidators([
            Validators.required
          ]);
          this.contactForm.controls['email'].updateValueAndValidity({ emitEvent: false });
        }
        // If MobilePhoneNumber was provided then Email field is no longer required
        else {
          // this.contactForm.controls['email'].setValidators([
          //   valueExistsValidator(contactEmails)
          // ]);
          this.contactForm.controls['email'].clearValidators();
          this.contactForm.controls['email'].updateValueAndValidity({ emitEvent: false });
        }
      }
    );
  }

  onGetContactsSearch(searchTerm: string) {
    this.contactService.getContactsSearch(searchTerm).subscribe(
      (contacts: ContactSearchItem[]) => {
        let labelText = "";

        let retrievedContactsOptions = [];

        // Store retrieved contacts in the contactsOptions select list
        contacts.map(cT => {
          let labelText = cT.fullName.trim().length > 0 ? cT.fullName : "NO-NAME";
          // cT.idCardNumber ? labelText += " / ID: " + cT.idCardNumber : "";
          cT.companyName ? labelText += " / Company: " + cT.companyName : "";
          cT.telephone ? labelText += " / Tel: " + cT.telephone : "";
          cT.mobile ? labelText += " / Mob: " + cT.mobile : "";
          cT.contactEmail ? labelText += " / Email: " + cT.contactEmail : "";

          retrievedContactsOptions.push(
            {
              label: labelText,
              value: cT.id
            }
          );
        });

        this.referredByContactsOptions = retrievedContactsOptions;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  onGetContactTypes() {
    this.contactTypeService.getTypeContacts().subscribe(
      (contactTypes: ContactType[]) => {
        // Store retrieved contact types in the contactTypesOptions select list
        contactTypes.map(cT => this.contactTypesOptions.push({ label: cT.name, value: cT.id }));

        // There must be at least one Contact Type created to create or edit a contact
        if (this.contactTypesOptions.length === 0) {
          this.isFormInit = false;
          return;
        }

        let defaultValue = [];

        // if (!this.isEdit)
          //defaultValue.push(contactTypes.find(cT => cT.name === PrepopulatedTypesContact.Client).id);
        if (this.isEdit)
           defaultValue = this.contact.contactTypeIds;

        // this.contactForm.patchValue({
        //   contactTypeIds: defaultValue
        // });

        this.route.queryParams.subscribe(
          (queryParam: Params) => {
            let typeContact = queryParam['type'];
            if (typeContact === PrepopulatedTypesContact.Client) {
              let typeId = contactTypes.find(cT => cT.name === PrepopulatedTypesContact.Client).id;
              if (!defaultValue.includes(typeId))
              {
                defaultValue.push(typeId);
              }
            }
            else if (typeContact === PrepopulatedTypesContact.Vendor) {
              let typeId = contactTypes.find(cT => cT.name === PrepopulatedTypesContact.Vendor).id;
              if (!defaultValue.includes(typeId))
              {
                defaultValue.push(typeId);
              }
            }
            else if (typeContact === PrepopulatedTypesContact.Other_Agents) {
              let typeId = contactTypes.find(cT => cT.name === PrepopulatedTypesContact.Other_Agents).id;
              if (!defaultValue.includes(typeId))
              {
                defaultValue.push(typeId);
              }
            }
          }
        );

        this.contactForm.patchValue({
          contactTypeIds: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact types", error);
      }
    );
  }

  onGetContactSources() {
    this.contactSourceService.getContactSources().subscribe(
      (contactSources: ContactSource[]) => {
        // Store retrieved contact sources in the contactSourcesOptions select list
        contactSources.map(cS => this.contactSourcesOptions.push({ label: cS.name, value: cS.id }));

        // There must be at least one Contact Source created to create or edit a contact
        if (this.contactSourcesOptions.length === 0) {
          this.isFormInit = false;
          return;
        }

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.contactSourcesOptions[0].value;
        else
          defaultValue = this.contact.contactSource.id;

        this.contactForm.patchValue({
          contactSourceId: defaultValue
        });
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact sources", error);
      }
    );
  }

  onGetContactOrganizations() {
      let combined$ = combineLatest(
        this.organizationService.getOrganizations(),
        this.route.queryParams,
        (orgs, queryParam)=> ({orgs, queryParam}));

        combined$.pipe(
          tap((result) => {
            this.contactOrganizationsOptions.push({label: " - Select Organization -", value: null});

                // Store retrieved contact organization in the contactOrganizationOptions select list
                result.orgs.map(cT => this.contactOrganizationsOptions.push({ label: cT.name, value: cT.id }));
             
        let defaultValue = this.organizationIdFromClientCreate;
                
        if (!this.isEdit){
          if(defaultValue == 0)
            defaultValue = this.contactOrganizationsOptions[0].value;
        }
                else
                  defaultValue = this.contact.organizationId;
        
                this.contactForm.patchValue({
                  organizationId: defaultValue
                });
          }),
          map(result => {
            let queryParam = result.queryParam;
            let orgId = +queryParam['organization'];
            if (!isNaN(orgId))
            {
              this.contactForm.get('organizationId').setValue(orgId);
            }
          })
        ).subscribe(
          // Success
          ()=>{},
          // Error
          (error: Response) => {
            this.toastService.createErrorMessage("Error retrieving contact organization", error);
        });
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // Store retrieved users in the usersOptions select list
        users.map(u => this.assignedUserOptions.push({ label: u.fullName, value: u.id }));

        let defaultValue = 0;
        if (!this.isEdit)
          defaultValue = this.authService.applicationProfileUser().id;
        else
          defaultValue = this.contact.assignedUserAccountId ? this.contact.assignedUserAccountId : this.authService.applicationProfileUser().id;

        this.contactForm.patchValue({
          assignedUserAccountId: defaultValue
        });
      }
    );
  }

  onGetTags() {
    this.tagService.getTags().subscribe(
      (tags: Tag[]) => {
        this.allTags = tags;
        this.contactTags = tags.filter(t => t.contactTags.find(cT => cT.contactId === this.contact.id));
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact tags", error);
      }
    );
  }

  onAddTag(tagName: string) {
    let newTag: any = {
      name: tagName,
      createdByUserAccountId: this.authService.applicationProfileUser().id,
      contactTags: []
    };

    let contactTag = {
      contactId: this.contact.id,
      tagId: 0,
      createdByUserAccountId: this.authService.applicationProfileUser().id,
    };
    newTag.contactTags.push(contactTag);

    this.tagService.addTag(newTag).subscribe(
      (response: Response) => {
        let newlyCreatedTag = (<any>response) as Tag;
        this.allTags.push(newlyCreatedTag); // - Push new tag so its searchable

        // - Obtained from: https://github.com/primefaces/primeng/issues/3211 by user 'dixitk13'
        if (this.newTagName && this.contactTagsAutoComplete && this.contactTagsAutoComplete.value) {
          this.contactTagsAutoComplete.value = [...this.contactTagsAutoComplete.value, newlyCreatedTag];
          this.newTagName = null;
          this.contactTagsAutoComplete.multiInputEL.nativeElement.value = '';
        } else if (this.newTagName) {
          this.contactTagsAutoComplete.value = [newlyCreatedTag];
          this.newTagName = null;
          this.contactTagsAutoComplete.multiInputEL.nativeElement.value = '';
        }
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error adding contact tag", error);
      }
    );
  }

  onClickUpdateLastAction() {
    this.contactForm.get('lastActionDate').setValue( new Date() );
  }

  onAddContact(newContact: Contact) {
    let fullName = newContact.firstName + " " + newContact.lastName;
    this.contactService.addContact(newContact).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The contact " + fullName + " has been created.");

        let contact: Contact = (<any>response) as Contact; // - Can't use NewContact as we need the contact's ID retrieved from db after creation
        // Only ask for opportunity creation if type is set to client
        let clientTypeId = this.contactTypesOptions.find(o => o.label === PrepopulatedContactTypes.Customer).value;
        if (newContact.contactTypeIds.includes(clientTypeId))
          this.onAskForOpportunity(contact);
        else
          this.router.navigate(['/contacts/']);
      },
      (error) => {
        this.toastService.createErrorMessage("Error adding contact", error);
      }
    );
  }

  onUpdateContact(newContact: Contact) {
    let fullName = newContact.firstName + " " + newContact.lastName;
    this.contactService.updateContact(newContact).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The contact " + fullName + " has been updated.");

        // Only ask for opportunity creation if type is set to client
        let clientTypeId = this.contactTypesOptions.find(o => o.label === PrepopulatedContactTypes.Customer).value;
        if (newContact.contactTypeIds.includes(clientTypeId))
          this.onAskForOpportunity(newContact);
        else
          this.router.navigate(['/contacts/']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating contact", error);
      }
    );
  }

  onAskForOpportunity(contact: Contact) {
    this.confirmationService.confirm({
      header: 'New Opportunity',
      message: 'Would you like to create a new opportunity for this buyer / tenant?',
      accept: () => {
        this.router.navigate(['/opportunities/new'], { queryParams: { contact: contact.id } });
      },
      reject: () => {
        this.router.navigate(['/contacts/']);
      }
    });
  }

  onSubmit() {

    if (!this.blockSaveAsNotAssigned) {

      let newContact = this.formBuilder.sanitizeFormValues(this.contactForm).value;

      // This is to pass correct value (contact id) from the autocomplete selection and to sanitize it
      // (Eg: writing random string in search and submit form would return an api error response)
      if (newContact.referredByContactId && newContact.referredByContactId.value)
        newContact.referredByContactId = newContact.referredByContactId.value;
      else
        newContact.referredByContactId = null;


      // Status is a checkbox value so only true or false can be returned
      // True - Active Contact, False - Hidden Contact
      if (newContact.status)
        newContact.status = AuditManager.StatusActive;
      else
        newContact.status = AuditManager.StatusHidden;

      if (this.isEdit) {
        newContact.id = this.contact.id; // - Set id of edited contact to its original id, same with externalId and createdById
        newContact.externalId = 0; // - External ID not used for stand-alone version
        newContact.updatedByUserAccountId = this.authService.applicationProfileUser().id;
        this.onUpdateContact(newContact);
      }
      else {
        newContact.createdByUserAccountId = this.authService.applicationProfileUser().id;
        this.onAddContact(newContact);
      }
    }
  }

  isContactSourceRefferer(contactSourceId: number) {
    let referredOption = this.contactSourcesOptions.find(o => o.label === PrepopulatedContactSources.Referred);

    if (referredOption)
      if (contactSourceId === referredOption.value)
        return true;

    return false;
  }

  initGoogleMap() {
    let lat = this.isEdit ?
      this.contact.locationLat :
      GoogleMaps.DefaultLocationLat;

    let lng = this.isEdit ?
      this.contact.locationLng :
      GoogleMaps.DefaultLocationLng;

    let location = this.isEdit ?
      this.contact.location :
      GoogleMaps.DefaultLocation;

    if (lat && lng) {
      this.options = {
        center: { lat: lat, lng: lng },
        zoom: GoogleMaps.DefaultLocationZoom
      };

      this.overlays = [
        this.marker = new google.maps.Marker(
          {
            position: { lat: lat, lng: lng },
            title: location,
            animation: google.maps.Animation.DROP
          }
        )
      ];

      this.contactForm.patchValue({
        location: location,
        locationLat: lat,
        locationLng: lng
      });
    } else {
      this.options = {
        center: { lat: GoogleMaps.DefaultLocationLat, lng: GoogleMaps.DefaultLocationLng },
        zoom: GoogleMaps.DefaultLocationZoom
      };

      this.overlays = [
        new google.maps.Marker(
          {
          }
        )
      ];


    }
  }

  initGoogleMapSearch() {
    if (!this.isGoogleMapsSearchInit) {
      let input = this.gMapsSearchElementRef.nativeElement;

      let options = {
        //componentRestrictions: { country: "mt" },
        RankBy: 'PROMINENCE'
      };

      let autocomplete = new google.maps.places.Autocomplete(input, options);

      google.maps.event.addListener(autocomplete, 'place_changed', () => {
        let place = autocomplete.getPlace();
        this.onMapPlaceChanged(place);
      });

      this.isGoogleMapsSearchInit = true;
    }
  }

  onMapPlaceChanged(place) {

    if(place.address_components)
    {

      let streetnumberItem = place.address_components.find(x => x.types[0] === 'street_number');
      let streetItem = place.address_components.find(x => x.types[0] === 'route');
      let localityItem = place.address_components.find(x => x.types[0] === 'locality');
      let countryItem = place.address_components.find(x => x.types[0] === 'country');

      this.contactForm.patchValue({
          addressLine1: streetnumberItem ? streetnumberItem.long_name : "",
          addressLine3: streetItem ? streetItem.long_name : "",
          addressLine4: localityItem ? localityItem.long_name : "",
          country: countryItem ? countryItem.long_name : "",
         });

    }

    if (place.geometry) {
      let lat = place.geometry.location.lat();
      let lng = place.geometry.location.lng();
     // let locationName = place.formatted_address;

      this.overlays = [
        this.marker = new google.maps.Marker(
          {
            //title: locationName,
            position: { lat: lat, lng: lng },
            animation: google.maps.Animation.DROP
          }
        )
      ];
      this.gmap.map.panTo({ lat: lat, lng: lng });
      this.contactForm.patchValue({
     //location: locationName,
        locationLat: lat,
        locationLng: lng
      });
    }
    else {
      this.overlays = [
        this.marker = new google.maps.Marker(
          {
          }
        )
      ];
      this.contactForm.patchValue({
        locationLat: null,
        locationLng: null
      });
    }

    this.ref.detectChanges();
  }

  handleMapClick(event){

    var location = event.latLng;

   if (this.marker) {
      this.marker.setPosition(location);
    } else {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.gmap.map
      });
    }

    this.gmap.map.panTo(location);

    this.contactForm.patchValue({
      location: location,
      locationLat: location.lat(),
      locationLng: location.lng()
    });

  }

  filterInReap() {
    var title = "Properties of " + this.contact.fullName;
    window.open(this.reapUri + "/product/?contactid=" + this.contact.id + "&title=" + title, "_blank");
  }

  UpdateLastContactedDate(propId)
  {
      //send to crm
      this.propertyService.UpdateLastContactedDate(propId).subscribe(
        (response: Response) => {
          this.toastService.createSuccessMessage("Success", "The last contacted date has been updated.");
        },
        (error: Response) => {
          this.toastService.createErrorMessage("Error updating last contacted date", error);
        }
      );
  }

}
