import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { declaredViewContainer } from '@angular/core/src/view/util';
import { Route, Router } from '@angular/router';
import { AppSettings } from 'app.settings';

import { HttpClient } from '@angular/common/http';
import { AuthService } from 'app/views/auth/auth.service';
import { PrivateModeService } from 'app/views/shared/services/private-mode.service';
import { Subscription } from 'rxjs';

declare var Weavy: any;
declare var GetBadge: any;

declare let $: any;

// declare var weavy: any;
// declare inter
@Component({
  selector: 'footer',
  templateUrl: 'footer.template.html'
})
export class FooterComponent implements OnInit , OnDestroy{

  public AppSettings: AppSettings;
  public weavy: any;
  privateMode;
  private privateModeSubscription: Subscription;

  public isEpic = false;

  public showQuickLinks = false;

  constructor(public router: Router,
    public http: HttpClient,
    private authService: AuthService,
    private privateModeService: PrivateModeService) {
  }

  copyYear = (new Date()).getFullYear();

  ngOnInit() {
    var refreshOverride = true;
    var token = "";
    var weavyApp = null;

    this.isEpic = AppSettings.IS_EPIC;

    /* var weavyUrl = this.isEpic ? "https://epicagentcrm.weavy.io" : "https://reapcrm.weavy.io";

    this.http.get(AppSettings.API_ENDPOINT + 'api/account/weavytoken?refresh=' + refreshOverride)  
    .subscribe((x: string) => {
      token = x;
  
      this.weavy = new Weavy({
        url: weavyUrl,
        tokenFactory: async (refresh) => {
          return token;
        }});

        let messenger = this.weavy.app({
          uid: "messenger-demo",
          type: "messenger",
          container: "#messenger"
        });

      weavyApp = this.weavy;
      let interval = setInterval(function () {
        
      weavyApp.fetch("/api/conversations/badge", {
          name: "Conversation Badge"
        }).then(function (result) {
          var count = result.private + result.chat + result.rooms;
          if (count == 0)
            {
              $("#messenger-notification-badge").removeClass("fa-stack");
            }
          else
            {
              $("#messenger-notification-badge").addClass("fa-stack");
            }
          $("#messenger-notification-badge").attr("data-count",count);
        });
      }, 2500);
    }) */



    this.privateModeSubscription = this.privateModeService.privateModeEmitter.subscribe(privateMode => {
      this.privateMode = privateMode;
    });

    this.MinimizeQuickLinks(this.IsQuickLinkMinimized());
  }

  togglePrivateMode(){
    this.privateModeService.togglePrivateMode();
  }

  openLinkInNewPage(url: string) {
    // INSIDE CRM
    // this.router.navigateByUrl("", {} )

    /// FOREXTERNAL
    window.open(AppSettings.REAP_URI + url, "_blank");
  }

  openLink(url: string) {
    // INSIDE CRM
    this.router.navigateByUrl(url)
  }

  ngOnDestroy(): void {
    this.privateModeSubscription.unsubscribe();
  }

  ClickMinimizeQuickLinks(boolVal: boolean) {

    this.setQuickLinkCookie(!boolVal);
    this.MinimizeQuickLinks(boolVal);
  }

  MinimizeQuickLinks(boolVal: boolean) {

    this.showQuickLinks = !boolVal;
  }

  IsQuickLinkMinimized() {
    return document.cookie.split(';').find(x => x.indexOf('MinimizeQuickLinks') > -1) != null;
  }



  setQuickLinkCookie(remove = false) {
      const d = new Date();
      var host = window.location.hostname;
      host = host.indexOf('.') > -1  ? host.substr(host.indexOf('.'),host.length - host.indexOf('.')) : host;
      var days = 600 * (remove ? -1 : 1);
      d.setTime(d.getTime() + (days*24*60*60*1000));
      let expires = "expires="+  d.toUTCString() ;
      document.cookie = "MinimizeQuickLinks=true;" + expires + ";domain=" + host + ";path=/";
  }

 }
