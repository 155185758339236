import { NgModule } from '@angular/core';

import { SharedModule } from "../shared/shared.module";

import { MailchimpComponent } from './mailchimp.component';
import { MailchimpListComponent } from './mailchimp-list/mailchimp-list.component';
import { MailchimpListPipe } from '../shared/pipes/mailchimp-contact-list.pipe';

import { MailchimpRoutingModule } from './mailchimp-routing.module';
import { MailchimpConfigComponent } from './mailchimp-list/mailchimp-config/mailchimp-config.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';
import { MailchimpContactListAutomationComponent } from './mailchimp-contact-list-automation/mailchimp-contact-list-automation.component';
import { MailchimpContactListAutomationManageComponent } from './mailchimp-contact-list-automation/mailchimp-contact-list-automation-manage/mailchimp-contact-list-automation-manage.component';
import { DropdownModule } from 'primeng/primeng';

@NgModule({
  declarations: [
    MailchimpComponent,
    MailchimpListComponent,
    MailchimpConfigComponent,
    MailchimpContactListAutomationComponent,
    MailchimpContactListAutomationManageComponent,
    MailchimpListPipe
  ],
  imports: [
    SharedModule,
    MailchimpRoutingModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    DropdownModule
  ]
})
export class MailchimpModule { }
