import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";

import { Product } from "../models/product.model";

import { AuthService } from "../../auth/auth.service";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';
import { PropertyCategory } from '../models/property-category.model';
import { PropertyLocality } from '../models/property-locality.model';
import { PropertyRegion } from '../models/property-region.model';
import { PropertyDealType } from '../models/property-dealtype';
import { PropertyAttribute } from '../models/property-attribute';
import { PropertyDetails } from '../models/property-details.model';
import { LoadingSpinnerService } from './loading-spinner.service';
import { Opportunity } from '../models/opportunity.model';
import { OpportunitySelection } from '../models/opportunity-selection.model';
import { PropertyOppSelection } from '../models/property-opportunity-selection.model';
import { Property } from '../models/property.model';
import { PropertyCountry } from '../models/property-country-model';
import { RentalPriceType } from '../models/rental-price-type-model';
import { PropertyCounty } from '../models/property-county.model';
import { DefaultSelections } from '../models/default-selections.model';
import { FinishingType } from '../models/finishing.model';
import { FurnishingType } from '../models/furnishing.model';


@Injectable()
export class PropertyService {
  private url: string = AppSettings.API_ENDPOINT + 'api/opportunities/';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loadingService: LoadingSpinnerService
  ) { }

  getFinishingTypes(): Observable<FinishingType[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+'finishingtypes').map(
      (response: Response) => {
        const finishingTypes: FinishingType[] = (<any>response);
        this.loadingService.hideLoadingSpinner()
        return finishingTypes;
      }
    );
  }

  getFurnishingTypes(): Observable<FurnishingType[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+'furnishingtypes').map(
      (response: Response) => {
        const furnishingTypes: FurnishingType[] = (<any>response);
        this.loadingService.hideLoadingSpinner()
        return furnishingTypes;
      }
    );
  }

  getMainCategoryTypes(): Observable<PropertyCategory[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+'maincategories').map(
      (response: Response) => {
        const propCategories: PropertyCategory[] = (<any>response);
        this.loadingService.hideLoadingSpinner()
        return propCategories;
      }
    );
  }

  getSubCategoryTypes(selectedMainCat: number[]): Observable<PropertyCategory[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.post<PropertyCategory[]>(
      this.url+'subcategories', selectedMainCat
      ).finally(() => this.loadingService.hideLoadingSpinner());
  }


  getSubCategoryTypesAll(): Observable<PropertyCategory[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+'subcategoriesall').map(
      (response: Response) => {
        const results: PropertyCategory[] = (<any>response);
        this.loadingService.hideLoadingSpinner()
        return results;
      }
    );
  }

  getPropertyLocalities(selectedReg: number[], selectedCountry: number): Observable<PropertyLocality[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.post<PropertyLocality[]>(
      this.url+'propertylocalitiesbyregionid/' + selectedCountry, selectedReg
      ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getDefaultSelections(): Observable<DefaultSelections> {
    this.loadingService.displayLoadingSpinner();
      return this.http.get(this.url+'propertydefaultSelections').map(
        (response: Response) => {
          const results: DefaultSelections = (<any>response);
          this.loadingService.hideLoadingSpinner()
          return results;
        }
      );
  }

  getPropertyRegionsByCountry(selectedCountry: number): Observable<PropertyRegion[]> {
    this.loadingService.displayLoadingSpinner();
      return this.http.get(this.url+'propertyregionsbycountryId/' + selectedCountry).map(
        (response: Response) => {
          const results: PropertyRegion[] = (<any>response);
          this.loadingService.hideLoadingSpinner()
          return results;
        }
      );
  }

  getPropertyCountiesByRegion(selectedRegion: number): Observable<PropertyCounty[]> {
    this.loadingService.displayLoadingSpinner();
      return this.http.get(this.url+'propertycountiesbyregionId/' + selectedRegion).map(
        (response: Response) => {
          const results: PropertyCounty[] = (<any>response);
          this.loadingService.hideLoadingSpinner()
          return results;
        }
      );
  }

  getPropertyLocalitiesByCounty(selectedCounty: number): Observable<PropertyLocality[]> {
    this.loadingService.displayLoadingSpinner();
      return this.http.get(this.url+'propertylocalitiesbycountyId/' + selectedCounty).map(
        (response: Response) => {
          const results: PropertyLocality[] = (<any>response);
          this.loadingService.hideLoadingSpinner()
          return results;
        }
      );
  }

  getPropertyLocalitiesByRegion(seclectedRegion: number): Observable<PropertyLocality[]> {
    this.loadingService.displayLoadingSpinner();
      return this.http.get(this.url+'propertylocalitiesbyregionId/' + seclectedRegion).map(
        (response: Response) => {
          const results: PropertyLocality[] = (<any>response);
          this.loadingService.hideLoadingSpinner()
          return results;
        }
      );
  }

  getPropertyLocalitiesAll(): Observable<PropertyLocality[]> {
    this.loadingService.displayLoadingSpinner();
      return this.http.get(this.url+'propertylocalities').map(
        (response: Response) => {
          const results: PropertyLocality[] = (<any>response);
          this.loadingService.hideLoadingSpinner()
          return results;
        }
      );
  }

  getPropertyRegions(): Observable<PropertyRegion[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+'propertyregions').map(
      (response: Response) => {
        const propLocalities: PropertyRegion[] = (<any>response);
        this.loadingService.hideLoadingSpinner()
        return propLocalities;
      }
    );
  }

  getPropertyCountries(): Observable<PropertyCountry[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+'propertycountries').map(
      (response: Response) => {
        const propCountries: PropertyCountry[] = (<any>response);
        this.loadingService.hideLoadingSpinner()
        return propCountries;
      }
    );
  }

  getRentalPriceTypes(): Observable<RentalPriceType[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+'rentalpricetypes').map(
      (response: Response) => {
        const propPriceTypes: RentalPriceType[] = (<any>response);
        this.loadingService.hideLoadingSpinner()
        return propPriceTypes;
      }
    );
  }

  getPropertyDealTypes(): Observable<PropertyDealType[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+'dealtypes').map(
      (response: Response) => {
        const propDealTypes: PropertyDealType[] = (<any>response);
        this.loadingService.hideLoadingSpinner()
        return propDealTypes;
      }
    );
  }

  getPropertyRooms(): Observable<PropertyAttribute[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+'rooms').map(
      (response: Response) => {
        const propAttributes: PropertyAttribute[] = (<any>response);
        this.loadingService.hideLoadingSpinner()
        return propAttributes;
      }
    );
  }

  getPropertyFeatures(): Observable<PropertyAttribute[]> {
    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url+'features').map(
      (response: Response) => {
        const propAttributes: PropertyAttribute[] = (<any>response);
        this.loadingService.hideLoadingSpinner()
        return propAttributes;
      }
    );
  }

  postPropertyDetails(opportunitySelection: OpportunitySelection): Observable<Response> {
    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url+'postoppselection', opportunitySelection
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getPropertyDetails(id: number, page: number, size: number): Observable<PropertyOppSelection> {
    return this.http.get(this.url+'getoppselectionbyid/'+ id + '?page=' + page + '&size=' + size).map(
      (response: Response) => {
        let propOppSelection: PropertyOppSelection = (<any>response);
        // let oppSelection: OpportunitySelection = propOppSelection.oppSelectionModel
        // let propDetails: PropertyDetails = propOppSelection.oppSelectionModel.oppSelectionModel
        // let properties: Property[] = propOppSelection.property
        return propOppSelection;
      }
    );
  }

  updatePropertyDetails(id:number, opportunitySelection: OpportunitySelection): Observable<Response> {
    
    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + 'updateoppselection/' + id, opportunitySelection
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  UpdateLastContactedDate(id: number): Observable<Response> {
    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + 'updatelastcontacteddate/' + id,null
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }


  // getProduct(id: number): Observable<Product> {
  //   //this.authService.reauthenticateIfTokenExpired();

  //   return this.http.get(this.url + id).map(
  //     (response: Response) => {
  //       const product: Product = (<any>response);
  //       return product;
  //     }
  //   );
  // }

}
