import { Component, OnInit, ViewChild } from '@angular/core';
import { Response, URLSearchParams } from "@angular/http";
import { Router } from '@angular/router';

import { AuthService } from "../../auth/auth.service";
import { ContactService } from "../../shared/services/contact.service";
import { ToastService } from "../../shared/services/toast.service";
import { TableUtilityService } from "../../shared/services/table-utility.service";
import { ContactTypeService } from '../../shared/services/contact-type.service';
import { ContactSourceService } from '../../shared/services/contact-source.service';
import { OrganizationService } from "../../shared/services/organization.service";

import { ConfirmationService, SelectItem, LazyLoadEvent, DataTable } from "primeng/primeng";
// import { CookieService } from "ngx-cookie";
import { CookieService } from "ngx-cookie";
import { UserService } from '../../shared/services/user.service';
import { TagService } from '../../shared/services/tag.service';
import { GroupService } from '../../shared/services/group.service';
import { SavedContactSearchService } from '../../shared/services/saved-contact-search.service';

import { Contact } from "../../shared/models/contact.model";
import { ContactType } from '../../shared/models/contact-type.model';
import { ContactsSegment } from '../../shared/models/contacts-segment.model';
import { Group } from '../../shared/models/group.model';
import { SavedContactSearch } from '../../shared/models/saved-contact-search.model';

import { Subject } from "rxjs/Rx";
import { forkJoin } from 'rxjs/observable/forkJoin';
import { HttpParams } from '@angular/common/http';
import { UtilityService } from 'app/views/shared/services/utility.service';
import { PrepopulatedContactTypes } from 'app.constant';
import { AuditService } from 'app/views/shared/services/audit.service';
import { TenantAppSettingService } from 'app/views/auth/tenant-app-setting.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss']
})
export class ContactListComponent implements OnInit {
  contacts: Contact[] = [];

  /** Column Toggling */
  columnOptions: SelectItem[];
  selectedCols: any[];              // - Contains the table columns that should be visible
  allSelectableCols: any[] = [];    // - Contains all table columns that may be toggled on or off

  selectedColsDefault: any[] = [];  // - Contains the columns that are shown by default if no cookie is stored

  //#region AdvancedSearch
  saveSearchResults: boolean = false; // - If results should be saved as a contact group after search is performed
  savedGroupName: string = "";        // - The name of the new contact group

  saveContactSearch: boolean = false; // - If results should be saved as a dynamic search operation after search is performed
  savedContactSearchName: string = "";// - The name of the new contact search operation
  savedContactSearches: SavedContactSearch[] = []; // - The Saved Contact Searches list to hold saved search values

  savedContactSearchesOptions: SelectItem[] = [];
  selectedContactSearchOption: any;

  contactTypesOptions: SelectItem[] = [];
  selectedContactTypeOption: any;

  contactSourcesOptions: SelectItem[] = [];
  selectedContactSourceOption: any;

  contactOrganizationOptions: SelectItem[] = [];
  selectedContactOrganizationOption: any;

  contactTagsOptions: SelectItem[] = [];
  selectedContactTagOptions: any;

  contactGroupsOptions: SelectItem[] = [];
  selectedContactGroupOptions: any;

  userOptions: SelectItem[] = [];
  selectedAssignedToOption: any;
  selectedCreatedByOption: any;

  selectedContactName: any;
  selectedCompanyName: any;
  selectedEmail: any;
  selectedRefCode: any;

  selectedPhone: any;

  selectedIdCardNumber: any;
  selectedVatNumber: any;

  selectedCreatedOnSince: any;
  selectedCreatedOnBefore: any;

  //#endregion

  //** Tabs */
  contactListTabIndex: number = 0;
  contactGroupsTabIndex: number = 1;
  savedContactSearchTabIndex: number = 2;

  //** DatePicker */
  todayDate: Date = new Date();
  yearRange: string = this.todayDate.getFullYear() - 10 + ':' + (this.todayDate.getFullYear() + 10); // - 20 Year Range

  /** Lazy Loading */
  totalRecords: number = 0;
  latestLazyLoadEvent: LazyLoadEvent;

  isAdvancedSearchCollapsed: boolean = false;
  isContactGroupsCollapsed: boolean = false;
  isSavedContactSearchesCollapsed: boolean = false;
  confimHeaderTitle: string = "";
  isOnInit: boolean = true;

  /* Sort Options */
  sortField: string = "createdOn";
  sortOrder: number = -1;

  isNumberVisible: boolean[] = [];
  isEmailVisible: boolean[] = [];

  constructor(
    public authService: AuthService,
    private router: Router,
    private contactService: ContactService,
    private contactTypeService: ContactTypeService,
    private contactSourceService: ContactSourceService,
    private organizationService: OrganizationService,
    private tagService: TagService,
    private groupService: GroupService,
    private savedContactSearchService: SavedContactSearchService,
    private userService: UserService,
    private appSetting: TenantAppSettingService,  // used on HTML
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private cookieService: CookieService,
    private tableUtilService: TableUtilityService,
    private utilService: UtilityService,
    private auditService: AuditService
  ) { }

  ngOnInit() {
    this.utilService.setPageTitle('Contact List');

    // Use last Sort 
    try {
      let selectedSortCookie = this.cookieService.getObject("crm_contactTableSort") as string;
      if (selectedSortCookie != null) {
        var token = selectedSortCookie.split('|');
        this.sortField = token[0];
        this.sortOrder = parseInt(token[1])
      }
    } catch {}

    this.populateSearchOptions();
    //this.onGetContactsCount();
    this.initColumnOptions();
  }

  initColumnOptions() {
    this.allSelectableCols = this.tableUtilService.getAllSelectableContactCols();
    this.selectedColsDefault = this.tableUtilService.getSelectedContactColsDefault();
    this.columnOptions = this.tableUtilService.getColumnOptions(this.columnOptions, this.allSelectableCols);

    let selectedColsCookie = this.cookieService.getObject("crm_selectedContactCols") as any[];
    if (selectedColsCookie)
      this.selectedCols = selectedColsCookie;
    else
      this.selectedCols = this.selectedColsDefault;
  }

  // - Saves options in a cookie whenever they are changed
  onColumnOptionsChange() {
    const nextYear = new Date()
    nextYear.setDate(nextYear.getDate() + 365);
    this.cookieService.putObject("crm_selectedContactCols", this.selectedCols, { expires: nextYear });
  }

  onSearchOptionChange(dt: DataTable, $event, field: string, matchMode: string) {
    dt.filter($event ? $event.value : null, field, matchMode);
  }

  onSearchClick(dt: DataTable){
    this.onSearchOptionChange(dt, null, null, null);
  }

  onSelectSavedSearch(dt: DataTable) {
    if (this.selectedContactSearchOption) {
      let selectedContactSearch = this.savedContactSearches.find(cS => cS.id === this.selectedContactSearchOption);

      this.selectedContactTypeOption = selectedContactSearch.contactTypeId;
      this.selectedContactSourceOption = selectedContactSearch.contactSourceId;

      this.selectedContactTagOptions =
        selectedContactSearch.contactTagIds ?
          selectedContactSearch.contactTagIds.split(',').map(Number) :
          null;
      this.selectedContactGroupOptions =
        selectedContactSearch.contactGroupIds ?
          selectedContactSearch.contactGroupIds.split(',').map(Number) :
          null;

      this.selectedAssignedToOption = selectedContactSearch.assignedToId;
      this.selectedCreatedByOption = selectedContactSearch.createdById;

      this.selectedContactName = selectedContactSearch.fullName;
      this.selectedCompanyName = selectedContactSearch.companyName;
      this.selectedEmail = selectedContactSearch.email;
      this.selectedRefCode = selectedContactSearch.refCode;

      this.selectedPhone = selectedContactSearch.phoneNumber;

      this.selectedIdCardNumber = selectedContactSearch.idCard;
      this.selectedVatNumber = selectedContactSearch.vat;

      this.selectedCreatedOnSince = selectedContactSearch.createdOnSince ? new Date(selectedContactSearch.createdOnSince) : null;
      this.selectedCreatedOnBefore = selectedContactSearch.createdOnBefore ? new Date(selectedContactSearch.createdOnBefore) : null;

      this.onSearchOptionChange(dt, null, null, null);
    }
    else {
      this.onResetSearch(dt);
    }
  }

  onSearchAndSaveContactGroup(dt: DataTable) {
    this.saveSearchResults = true;
    this.onSearchOptionChange(dt, null, null, null);
  }

  onSearchAndSaveContactSearch(dt: DataTable) {
    this.saveContactSearch = true;
    this.onSearchOptionChange(dt, null, null, null);
  }

  onResetSearch(dt: DataTable) {
    this.selectedContactTypeOption = null;
    this.selectedContactSourceOption = null;
    this.selectedContactTagOptions = null;
    this.selectedContactGroupOptions = null;
    this.selectedAssignedToOption = null;
    this.selectedCreatedByOption = null;
    this.selectedContactOrganizationOption = null;
    this.selectedContactName = null;
    this.selectedCompanyName = null;
    this.selectedEmail = null;
    this.selectedRefCode = null;

    this.selectedPhone = null;

    this.selectedIdCardNumber = null;
    this.selectedVatNumber = null;

    this.selectedCreatedOnSince = null;
    this.selectedCreatedOnBefore = null;

    dt.filters = {};

    dt.filter(null, null, null);
  }



  loadData(event: LazyLoadEvent) {
    if(this.isOnInit)
    {
      this.isOnInit = false;
    this.contactTypeService.getTypeContacts().subscribe(
      (contactTypes: ContactType[]) => {
        let customer = PrepopulatedContactTypes.Customer
        this.selectedContactTypeOption = contactTypes.find(x => x.name == customer).id;
        this.loadDataInner(event);
        }
        );
    }
    else
    {
      this.loadDataInner(event);
    }
  }

loadDataInner(event: LazyLoadEvent)
{
  this.latestLazyLoadEvent = event;
  let contactTypeId = this.selectedContactTypeOption;
  let contactSourceId = this.selectedContactSourceOption;
  let assignedToId = this.selectedAssignedToOption;
  let createdById = this.selectedCreatedByOption;
  let organizationId = this.selectedContactOrganizationOption;

  // Save Sort Param
  const nextYear = new Date()
  nextYear.setDate(nextYear.getDate() + 365);
  this.cookieService.putObject("crm_contactTableSort", event.sortField + "|" + event.sortOrder.toString(), { expires: nextYear });

  let params = new HttpParams();
  params = params.set("from", event.first.toString());
  params = params.set("to", event.rows.toString());
  params = params.set("sortBy", event.sortField);
  params = params.set("order", event.sortOrder.toString());
  params = params.set("searchTerm", event.globalFilter);

  if(contactTypeId)
  params = params.set("contactTypeId", contactTypeId);
  if(contactSourceId)
  params = params.set("contactSourceId", contactSourceId);
  if(this.selectedContactTagOptions)
  params = params.set("contactTagIds", this.selectedContactTagOptions);
  if(this.selectedContactGroupOptions)
  params = params.set("contactGroupIds", this.selectedContactGroupOptions);
  if(this.selectedContactOrganizationOption)
  params = params.set("organizationId", this.selectedContactOrganizationOption);

  if(assignedToId)
  params = params.set("assignedToId", assignedToId);
  if(createdById)
  params = params.set("createdById", createdById);

  if(this.selectedContactName)
  params = params.set("fullName", this.selectedContactName ? this.selectedContactName.trim() : this.selectedContactName);
  if(this.selectedCompanyName)
  params = params.set("companyName", this.selectedCompanyName ? this.selectedCompanyName.trim() : this.selectedCompanyName);
  if(this.selectedEmail)
  params = params.set("email", this.selectedEmail ? this.selectedEmail.trim() : this.selectedEmail);

  if(this.selectedRefCode)
  params = params.set("refCode", this.selectedRefCode ? this.selectedRefCode.trim() : this.selectedRefCode);

  if(this.selectedPhone)
  params = params.set("phoneNumber", this.selectedPhone ? this.selectedPhone.trim() : this.selectedPhone);

  if(this.selectedIdCardNumber)
  params = params.set("idCard", this.selectedIdCardNumber ? this.selectedIdCardNumber.trim() : this.selectedIdCardNumber);
  if(this.selectedVatNumber)
  params = params.set("vat", this.selectedVatNumber ? this.selectedVatNumber.trim() : this.selectedVatNumber);

  if(this.selectedCreatedOnSince)
  params = params.set("createdOnSince", this.selectedCreatedOnSince);

  if(this.selectedCreatedOnBefore)
  params = params.set("createdOnBefore", this.selectedCreatedOnBefore);

  if (this.saveSearchResults) {
    params = params.set("saveSearchResults", 'true');
    if(this.savedGroupName)
    params = params.set("savedGroupName", this.savedGroupName);
    params = params.set("savedGroupCreatedById", this.authService.applicationProfileUser().id.toString());
  }

  if (this.saveContactSearch) {
    params = params.set("saveContactSearch", 'true');
    if(this.savedContactSearchName)
    params = params.set("savedContactSearchName", this.savedContactSearchName);
    params = params.set("savedContactSearchCreatedById", this.authService.applicationProfileUser().id.toString());
  }

  this.onGetContacts(params);
}

  

  isColVisible(colName: string) {
    if (this.selectedCols.find(sC => sC.value === colName))
      return true;

    return false;
  }

  onChangeTabView($event) {
    let tabIndex = $event.index;
    switch ($event.index) {
      case this.contactListTabIndex:
        this.router.navigate(["/contacts/list"]);
        break;
      case this.contactGroupsTabIndex:
        this.router.navigate(["/contacts/groups"]);
        break;
      case this.savedContactSearchTabIndex:
        this.router.navigate(["/contacts/saved-searches"]);
        break;
    }
  }

  onGetContacts(params: HttpParams) {
    this.contactService.getContactsSegment(params, false).subscribe(
      (contactsSegment: ContactsSegment) => {
        this.contacts = contactsSegment.contacts;
        this.totalRecords = contactsSegment.totalContactsCount;

        if (this.saveSearchResults) {
          this.toastService.createSuccessMessage("Success", "The contact group " + this.savedGroupName + " has been created.");
          this.populateContactGroupSearchOptions();
          this.saveSearchResults = false;
          this.savedGroupName = "";
        }

        if (this.saveContactSearch) {
          this.toastService.createSuccessMessage("Success", "The contact search operation " + this.savedContactSearchName + " has been created.");
          this.populateContactSavedSearchSearchOptions();
          this.saveContactSearch = false;
          this.savedContactSearchName = "";
        }
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts", error);
      }
    );
  }

  //** Used to not have to re-init all SearchOptions and only just contact groups options */
  populateContactGroupSearchOptions() {
    this.groupService.getGroupsForContactSearch().subscribe(
      (groups: Group[]) => {
        this.contactGroupsOptions = [];
        groups.map(g => {
          this.contactGroupsOptions.push({ label: g.name, value: g.id });
        });
      }
    );
  }

  populateContactSavedSearchSearchOptions() {
    this.savedContactSearchService.getSavedContactSearches().subscribe(
      (savedContactSearches: SavedContactSearch[]) => {
        this.savedContactSearches = savedContactSearches;
        this.savedContactSearchesOptions = [];
        this.savedContactSearchesOptions.push({ label: "Select a saved search", value: null });
        savedContactSearches.map(cS => {
          this.savedContactSearchesOptions.push({ label: cS.name, value: cS.id });
        });
      }
    );
  }

  populateSearchOptions() {
    let contactTypes = this.contactTypeService.getTypeContacts();
    let contactSources = this.contactSourceService.getContactSources();
    let contactOrganizations = this.organizationService.getOrganizations();
    let users = this.userService.getUsers();
    let tags = this.tagService.getTags();
    let groups = this.groupService.getGroupsForContactSearch();
    let savedContactSearches = this.savedContactSearchService.getSavedContactSearches();

    forkJoin(contactTypes, contactSources,contactOrganizations, users, tags, groups, savedContactSearches).subscribe(
      ([contactTypes, contactSources, contactOrganizations, users, tags, groups, savedContactSearches]) => {
        this.contactTypesOptions.push({ label: "All Types", value: null });
        contactTypes.map(cT => {
          this.contactTypesOptions.push({ label: cT.name, value: cT.id });
        });
        this.selectedContactTypeOption = null;
        //let customer = PrepopulatedContactTypes.Customer
        //this.selectedContactTypeOption = contactTypes.filter(x => x.name == customer)[0].id;

        this.contactSourcesOptions.push({ label: "All Sources", value: null });
        contactSources.map(cS => {
          this.contactSourcesOptions.push({ label: cS.name, value: cS.id });
        });
        this.selectedContactSourceOption = null;
     
        tags.map(t => {
          this.contactTagsOptions.push({ label: t.name, value: t.id });
        });
        this.selectedContactTagOptions = null;

        groups.map(g => {
          this.contactGroupsOptions.push({ label: g.name, value: g.id });
        });
        this.selectedContactGroupOptions = null;

        this.savedContactSearchesOptions.push({ label: "Select a saved search", value: null });
        savedContactSearches.map(cS => {
          this.savedContactSearchesOptions.push({ label: cS.name, value: cS.id });
        });
        this.selectedContactSearchOption = null;
        this.savedContactSearches = savedContactSearches;

        this.userOptions.push({ label: "All Agents", value: null }); // todo: Unassigned option?
        users.map(u => {
          this.userOptions.push({ label: u.fullName, value: u.id });
        });

        this.contactOrganizationOptions.push({ label: "All Organizations", value: null });
        contactOrganizations.map(cS => {
          this.contactOrganizationOptions.push({ label: cS.name, value: cS.id });
        });
        this.selectedContactOrganizationOption = null;


        this.selectedAssignedToOption = null;
        this.selectedCreatedByOption = null;
      }
    );

  }

  onGetContactsCount() {
    this.contactService.getContactsCount().subscribe(
      (contactsCount: number) => {
        this.totalRecords = contactsCount;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contacts length", error);
      }
    );
  }

  onDeleteContact(contact: Contact) {
    this.confimHeaderTitle = "Delete";
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the contact ' + contact.fullName + '? Please note that all tasks, opportunities and communication logs related to this contact will also be deleted.',
      accept: () => {
        contact.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.contactService.deleteContact(contact).subscribe(
          (response: Response) => {
            this.loadData(this.latestLazyLoadEvent);
            this.toastService.createSuccessMessage("Success", "The contact " + contact.fullName + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting contact", error);
          }
        );
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for contact " + contact.fullName + " has been aborted.");
      }
    });
  }

  onErasureContact(contact: Contact) {
    this.confimHeaderTitle = "Erasure";
    this.confirmationService.confirm({
      message: 'Are you sure you want to erase this contact ' + contact.fullName + '? Please note that all tasks, opportunities and communication logs related to this contact will also be masked and deleted.',
      accept: () => {
        contact.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.contactService.erasureContact(contact).subscribe(
          (response: Response) => {
            this.loadData(this.latestLazyLoadEvent);
            this.toastService.createSuccessMessage("Success", "The contact " + contact.fullName + " has been erasured.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error while erasing contact", error);
          }
        );
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The erasure for contact " + contact.fullName + " has been aborted.");
      }
    });
  }

  onSetLastActionDate(contact: Contact) {
    this.confimHeaderTitle = "Update";
    this.confirmationService.confirm({
      message: 'Update "Last Action Date" to current DateTime for Contact ' + contact.fullName + '? ',
      accept: () => {
        contact.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.contactService.updateLastActionDateContact(contact).subscribe(
          (response: Response) => {
            contact.lastActionDate = (response as any).lastActionDate;
            //this.loadData(this.latestLazyLoadEvent);
            this.toastService.createSuccessMessage("Success", "The contact " + contact.fullName + " is updated.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error updating contact", error);
          }
        );
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The updating for contact " + contact.fullName + " has been aborted.");
      }
    });
  }


  trackByFn(index: number, row: any) {
    return row.id;
  }

  displayMobileNumber(index: number, contactName, contactId): void {
    this.isNumberVisible[index] = !this.isNumberVisible[index];
    this.auditService.auditViewMobileNumber(contactName, contactId);
  }

  displayEmail(index: number, contactName, contactId): void {
    this.isEmailVisible[index] = !this.isEmailVisible[index];
    this.auditService.auditViewEmail(contactName, contactId);
  }

}
