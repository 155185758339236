import { Contact } from "./contact.model";
import { Task } from "./task.model";
import { CrmUser } from "./user.model";
import { OpportunityProgress } from "./opportunity-progress.model";
import { OpportunityType } from "./opportunity-type.model";
import { OpportunityLostType } from "./opportunity-lost-type.model";
import { OpportunityProduct } from "./opportunity-product.model";
import { PropertyDetails } from './property-details.model';

export class Opportunity {
  constructor(
        public id: number = 0,
        public opportunityProducts: OpportunityProduct[] = [],        
        public opportunityProgress: OpportunityProgress[] = [],
        public opportunityLostTypeId: number = null,
        public opportunityLostType: OpportunityLostType = new OpportunityLostType(),
        public lostTypeDescription: string = null,
        public assignedContactId: number = null,
        public assignedContact: Contact = new Contact(),
        public assignedUserAccountId: number = null,
        public assignedUser: CrmUser = new CrmUser(),
        public opportunityTypeId: number = null,
        public opportunityType: OpportunityType = new OpportunityType,
        public name: string = null,
        public description: string = null,
        public uniqueId: string = null, // - This is a unique product ID inputted by the user, nullable, Eg: REMS would input Propert Ref Code.
        public probability: number = null,
        public value: number = null,
        public closedDate: string = null,        
        public lostDate: string = null,        
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number = null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public isFavorite: boolean = null,
        public status: string = null,
        public opportunitySelectionModel: PropertyDetails = new PropertyDetails
    ) { }
}

export class OpportunityDashboardCount {
  constructor(
        public open: number = 0,
        public closed: number = 0,
        public lost: number = 0,
        
    ) { }
}