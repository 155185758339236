import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from "@angular/forms";
import { Response } from '@angular/http';
import { ActivatedRoute, Router, Params } from "@angular/router";
import { CompanyDetails } from 'app/views/shared/models/company-details.model';
import { ConfirmationService } from "primeng/primeng";
import { AuthService } from "../../auth/auth.service";
import { CustomFormBuilder } from "../../shared/classes/CustomFormBuilder";
import { OrganizationService } from '../../shared/services/organization.service';
import { ToastService } from "../../shared/services/toast.service";
import { UtilityService } from 'app/views/shared/services/utility.service';

@Component({
  selector: 'app-company-details-manage',
  templateUrl: './company-details-manage.component.html',
  styleUrls: ['./company-details-manage.component.scss']
})
export class CompanyDetailsManageComponent implements OnInit {

  companyDetailsForm: FormGroup;                         // - Stores the organization form's values
  isFormInit: boolean = false;                    // - The form is not created until it is initialised
  isEdit: boolean = false;                        // - The form is set to update a organization if true and add a new organization if false

  companyDetails: CompanyDetails = new CompanyDetails();

  isAdditionalCollapsed: boolean = false;
  isLocationCollapsed: boolean = false;
  isExtraDetailsCollapsed: boolean = false;

  constructor(
    public authService: AuthService,
    private formBuilder: CustomFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    private organizationService: OrganizationService,
    private confirmationService: ConfirmationService,
    private utilService: UtilityService
  ) { }

  ngOnInit() {
    this.utilService.setPageTitle('Company Details');
    this.setupForm();
  }

  setupForm() {

          this.organizationService.getCompanyDetails().subscribe(
            (companyDetails: CompanyDetails) => {
              this.companyDetails = companyDetails;

              this.isEdit = this.companyDetails != null;
              this.ngOnInitForm();
            },
            (error: Response) => {
              this.toastService.createErrorMessage("Error retrieving company details", error);
            }
          );
  }


  ngOnInitForm() {
    this.companyDetailsForm = this.formBuilder.group({
      // id: this.formBuilder.control(this.companyDetails ? this.companyDetails.id : null),
      name: this.formBuilder.control(this.companyDetails ? this.companyDetails.name : null),
      email: this.formBuilder.control(this.companyDetails ? this.companyDetails.email : null, [Validators.required]),
      website: this.formBuilder.control(this.companyDetails ? this.companyDetails.website : null),
      mobilePhoneNumber: this.formBuilder.control(this.companyDetails ? this.companyDetails.mobilePhoneNumber : null, [Validators.required]),
      officePhoneNumber: this.formBuilder.control(this.companyDetails ? this.companyDetails.officePhoneNumber : null),
      vatNumber: this.formBuilder.control(this.companyDetails ? this.companyDetails.vatNumber : null),
      addressLine1: this.formBuilder.control(this.companyDetails ? this.companyDetails.addressLine1 : null),
      addressLine2: this.formBuilder.control(this.companyDetails ? this.companyDetails.addressLine2 : null),
      addressLine3: this.formBuilder.control(this.companyDetails ? this.companyDetails.addressLine3 : null),
      addressLine4: this.formBuilder.control(this.companyDetails ? this.companyDetails.addressLine4 : null),
      postCode: this.formBuilder.control(this.companyDetails ? this.companyDetails.postCode : null),
      country: this.formBuilder.control(this.companyDetails ? this.companyDetails.country : null),
      locationLat: this.formBuilder.control(this.companyDetails ? this.companyDetails.locationLat : null),
      locationLng: this.formBuilder.control(this.companyDetails ? this.companyDetails.locationLng : null),
      notes: this.formBuilder.control(this.companyDetails ? this.companyDetails.notes : null),
    });

    this.isFormInit = true;
  }

  onSubmit() {
    let newCompanyDetails = this.formBuilder.sanitizeFormValues(this.companyDetailsForm).value;

    if (this.isEdit) {
      // newCompanyDetails.id = this.companyDetails.id; // - Set id of edited organization to its original id, same with externalId and createdById
      this.onUpdateCompanyDetails(newCompanyDetails);
    }
    else {
      this.onCreateComanyDetails(newCompanyDetails);
    }
  }

  onUpdateCompanyDetails(companyDetails: CompanyDetails) {
    companyDetails.id = this.companyDetails.id;
    this.organizationService.updateCompanyDetails(companyDetails).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The company details have been updated.");
        this.router.navigate(['/settings/company-details']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error updating company details", error);
      }
    );
  }

  onCreateComanyDetails(companyDetails: CompanyDetails) {
    this.organizationService.createCompanyDetails(companyDetails).subscribe(
      (response: Response) => {
        this.toastService.createSuccessMessage("Success", "The company details have been created.");
        this.router.navigate(['/settings/company-details']);
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error creating company details", error);
      }
    );
  }
}
