import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response } from "@angular/http";
import { Router, ActivatedRoute } from "@angular/router";
import { LoadingSpinnerService } from "../shared/services/loading-spinner.service";
import { AppSettings } from 'app.settings'
import { GoogleMaps } from "app.constant";
import { Observable } from "rxjs/Rx";
import { tap, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from 'app/shared/profile/profile.service';
import { ToastService } from '../shared/services/toast.service';
import { TenantAppSetting, TenantAppSettingItem } from 'app/shared/profile/_models/appsetting.model';

@Injectable()
export class TenantAppSettingService {  
  private appSettingsUrl: string = AppSettings.API_ENDPOINT + 'api/appsettings';



  appSettingList: TenantAppSettingItem[] = [];

  loggedIn = false;

  nextUpdateAt: Date = null;

  isHideSensitiveInfo: Boolean = false;
  useRegionCounty: boolean = false;//AppSettings.USE_REGION_COUNTIES;

  // authHeaders: Headers;

  private loginRequest: Observable<boolean>;

  constructor(private http: HttpClient, 
    private _profileService: ProfileService
    ) {
  }

  // ----- add new appSettings as Parameters -----
  private setSettings() {
    this.isHideSensitiveInfo = this.getBoolSetting("HideSensitiveInfo");
    this.useRegionCounty = this.getBoolSetting("UseRegionCounty");
  }

  //---  get Setting according to the Type ----
  public getBoolSetting(name: string) {
    var setting = this.appSettingList.find(x => x.name == name);
    return setting != null && setting.value.toLowerCase() == 'true'; 
  }

  public loadTenantAppSetting() {

    try {
      this.checkDefaultMapCenterSetting();
      if (localStorage.getItem('tenantAppSetting') != null && localStorage.getItem('tenantAppSetting') != "" ) {

        var tmp = JSON.parse(localStorage.getItem('tenantAppSetting')) as TenantAppSetting;

        if (new Date(tmp.nextUpdate) > new Date()) {
          
          this.appSettingList = tmp.values;
          this.setSettings();
          return;
        }
      }
    } catch {}

        this.loadApiTenantAppSetting().subscribe(
          (success: boolean) => {

            if (!success) {
              console.log("Error loading Tenant App Settings."); 
            }
              
          },
          (error: Response) => {
            console.log(error);
            console.log("Error loading Tenant App Settings.");
          }
        )
    

  }
  
  loadApiTenantAppSetting(): Observable<boolean> {

    return this.http.get(this.appSettingsUrl).map(
      (response: any) => {
        console.log(response);

        if (response) {
          this.appSettingList = response;

          var tmp = new TenantAppSetting();
          tmp.nextUpdate = new Date();
          tmp.nextUpdate.setMinutes( tmp.nextUpdate.getMinutes() + 5);
          tmp.values = response

          localStorage.setItem('tenantAppSetting', JSON.stringify(tmp));
          this.setSettings();
          return true;
        }
        else
          return false;
      }
    );
  }

  checkDefaultMapCenterSetting() {
    try {
      if (GoogleMaps.DefaultLocationLat == 0 && GoogleMaps.DefaultLocationLng == 0) {
        this._profileService.getDefaultMapCenter().subscribe((item: any) => {
          var cords = item.split(',');
          GoogleMaps.DefaultLocationLat = parseFloat(cords[0]);
          GoogleMaps.DefaultLocationLng = parseFloat(cords[1]);
          GoogleMaps.DefaultLocationZoom = 11;
        })
      }
    } catch {}

  }

  
}