import { CrmUser } from "./user.model";
import { PropertyCategory } from './property-category.model';
import { PropertyRegion } from './property-region.model';
import { PropertyLocality } from './property-locality.model';
import { PropertyDealType } from './property-dealtype';
import { PropertyAttribute } from './property-attribute';
import { PropertyCountry } from './property-country-model';
import { PropertyCounty } from "./property-county.model";

export class PropertyDetails {
    constructor(
        public mainCatIds: number[] = [],
        public mainCatId: PropertyCategory[] = null,
        public subCatIds: number[] = [],
        public subCatId: PropertyCategory[] = null,
        public regionIds: number[] = [],
        public regionId: number = 0,
        public regionIdModel: PropertyRegion[] = null,
        public countyId: number = 0,
        public countyIdModel: PropertyCounty = null,
        public countryId: number = 0,
        public countryIdModel: PropertyCountry = null,
        public rentalPriceTypeId: number = 0,
        public localityIds: number[] = [],
        public localityId: PropertyLocality[] = null,
        public dealTypeIds: number[] = [],
        public dealTypeId: PropertyDealType[] = null,
        public startingBudget: number = null,
        public maximumBudget: number = null,
        public minimumBedrooms: number = null,
        public minimumGarages: number = null,
        public minimumBathrooms: number = null,
        public roomsIds: number[] = [],
        public roomId: PropertyAttribute[] = null,
        public featureIds: number[] = [],
        public featureId: PropertyAttribute[] = null,
        public propertyNotes: string = null,
        public finishingTypeIds: number[] = [],
        public furnishingTypeIds: number[] = [],
    ) { }
}