import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { TasksRoutingModule } from "./tasks-routing.module";
import { TaskTypesModule } from '../tasks/task-types/task-types.module';

import { SharedModule } from "../shared/shared.module";

import { TaskManageComponent } from './task-manage/task-manage.component';
import { TasksComponent } from './tasks.component';

import { CalendarModule, DropdownModule, MultiSelectModule, GMapModule } from 'primeng/primeng';
import { TaskContactManageComponent } from './task-manage/task-contact-manage/task-contact-manage.component';
import { ModalModule } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [
    TasksComponent,
    TaskManageComponent,
    TaskContactManageComponent
  ],
  imports: [
    SharedModule,
    TasksRoutingModule,
    TaskTypesModule,
    ReactiveFormsModule,
    CalendarModule,
    BrowserAnimationsModule,
    DropdownModule,
    GMapModule,
    ModalModule.forRoot()
  ],
})
export class TasksModule { }
