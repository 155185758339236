import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";
import { TasksComponent } from "./tasks.component";
import { TaskListComponent } from "./task-list/task-list.component";
import { TaskManageComponent } from "./task-manage/task-manage.component";
import { TaskContactManageComponent } from './task-manage/task-contact-manage/task-contact-manage.component';
import { HomeAccessGuard } from '../auth/home-access-guard.service';

const tasksRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard,HomeAccessGuard],
    children: [
      {
        path: 'tasks', component: TasksComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: TaskListComponent },
          { path: 'mylist', component: TaskListComponent },
          { path: ':id/edit', component: TaskManageComponent, 
          children: [
            { path: 'new-contact', component: TaskContactManageComponent }
        ] },
          { path: 'new', component: TaskManageComponent,
          children: [
            { path: 'new-contact', component: TaskContactManageComponent}
        ] }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(tasksRoutes)],
  exports: [RouterModule]
})
export class TasksRoutingModule { }