import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "../auth/auth-guard.service";

import { BasicLayoutComponent } from "../../components/common/layouts/basicLayout.component";

import { ContactsComponent } from "../contacts/contacts.component";
import { ContactListComponent } from "../contacts/contact-list/contact-list.component";
import { ContactManageComponent } from "../contacts/contact-manage/contact-manage.component";

import { ContactFilesComponent } from "../contacts/contact-files/contact-files.component";
import { ContactFileListComponent } from "../contacts/contact-files/contact-file-list/contact-file-list.component";
import { ContactFileManageComponent } from "../contacts/contact-files/contact-file-manage/contact-file-manage.component";
import { ContactImportComponent } from '../contacts/contact-import/contact-import.component';
import { CommunicationLogTypeComponent } from './communication-log-types/communication-log-types.component';
import { RoleGuard } from '../auth/role-guard.service';
import { CommunicationLogTypeModalComponent } from './communication-log-types/communication-log-types-modal/communication-log-types-modal.component';
import { HomeAccessGuard } from '../auth/home-access-guard.service';
import { ContactManageV2Component } from './contact-manage-v2/contact-manage.component';

const contactsRoutes: Routes = [
  {
    path: '',
    component: BasicLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'contacts', component: ContactsComponent,
        children: [
          { path: '', redirectTo: 'list', pathMatch: 'full' },
          { path: 'list', component: ContactListComponent, canActivate: [HomeAccessGuard] },  
          { path: ':id/edit', component: ContactManageComponent, canActivate: [HomeAccessGuard]  },
          { path: ':id/edit-v2', component: ContactManageV2Component, canActivate: [HomeAccessGuard]  },
          { path: 'new', component: ContactManageComponent, canActivate: [HomeAccessGuard]  },
          { path: 'new/:organizationId', component: ContactManageComponent, canActivate: [HomeAccessGuard]  },
          { path: 'import', component: ContactImportComponent, canActivate: [HomeAccessGuard]  },
          { path: 'communication-log-types', component: CommunicationLogTypeComponent, children: [ 
            { path: 'new', component: CommunicationLogTypeModalComponent, canActivate: [RoleGuard] },
            { path: ':id/edit', component: CommunicationLogTypeModalComponent, canActivate: [RoleGuard] }

          ] },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(contactsRoutes)],
  exports: [RouterModule]
})
export class ContactsRoutingModule { }
