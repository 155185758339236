import { CrmUser } from "./user.model";

export class ContactType {
    constructor(
        public id: number = 0,
        public name: string = null,
        public isPrivate: boolean = false,
        public allowEditOnlyIfAssigned: boolean = false,
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number= null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public status: string = null
    ) { }
}