import { CrmUser } from "./user.model";
import { UserTask } from "./user-task.model";
import { ContactTask } from "./contact-task.model";
import { OpportunityTask } from "./opportunity-task.model";
import { TaskType } from "./task-type.model";
import { PropertyIdCode } from './property-id-codes.model';
import {TaskProperty } from './task-property.model';

export class Task {
    constructor(
        public id: number = 0,
        public taskType: TaskType = new TaskType(),
        public taskTypeId: number = null,
        public userTasks: UserTask[] = [],
        public contactTasks: ContactTask[] = [],
        public opportunityTasks: OpportunityTask[] = [],
        public taskDescription: string = null,
        public location: string = null,
        public locationLat: number = null,
        public locationLng: number = null,
        public reminderDate: string = null,
        public isTaskComplete: boolean = false,
        public createdByUserAccount: CrmUser = null,
        public createdByUserAccountId: number= null,
        public createdOn: string = null,
        public updatedByUserAccount: CrmUser = null,
        public updatedByUserAccountId: number = null,
        public updatedOn: string = null,
        public status: string = null,
        public calendarDuration: number = null,
        public propertyId: number = null,
        public properties: TaskProperty[] = [],
        public propCode: PropertyIdCode = new PropertyIdCode(),
        public userTaskNames: string = null,
        public contactTasksNames: string = null,
        public completionDate: string = null,
        public priority: number = null
    ) { }
}

export class DaskboardTasksDto {
    constructor(
        public taskLate: number = 0,
        public upcomingTasks: number = 0,
        public todayTasksNotDone: Task[] = [],
        public todayTasksDone: Task[] = [],
    ) { }
}

export class SqlTask {
    constructor(
        public id: number = 0,
        public taskTypeName: string = null,
        public userTaskNames: string = null,
        public contactTaskNames: string = null,
        public firstContactId: string = null,
        public createdByUserAccountId: number = null,
        public reminderDate: string = null,
        public location: string = null,
        public taskDescription: string = null,
        public isTaskComplete: boolean = false,
        public createdBy: string = null,
        public updatedBy: string = null,
        public createdOn: string = null,
        public updatedOn: string = null,
        public completionDate: string = null,
        public priority: number = null
    ) { }
}