import { Injectable } from '@angular/core';
import { Response, Http } from "@angular/http";
import { AuthHttp } from "angular2-jwt/angular2-jwt";

import { UserService } from "./user.service";
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { Group } from "../models/group.model";
import { CrmUser } from "../models/user.model";

import 'rxjs/Rx';
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';


@Injectable()
export class GroupService {
  private url: string = AppSettings.API_ENDPOINT + 'api/groups/';

  public groupsChanged = new Subject();

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private userService: UserService,
    private loadingService: LoadingSpinnerService
  ) { }

  getGroups(): Observable<Group[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url).map(
      (response: Response) => {
        let groups: Group[] = (<any>response);
        groups = groups.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return groups;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getGroupsForContactSearch(): Observable<Group[]> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + "getGroupsForContactSearch").map(
      (response: Response) => {
        let groups: Group[] = (<any>response);
        groups = groups.map(
          cT => {
            // cT.createdByUserAccount = this.userService.getUser(cT.createdByUserAccountId);
            // cT.updatedByUserAccount = this.userService.getUser(cT.updatedByUserAccountId);
            return cT;
          }
        );
        return groups;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  getGroup(id: number): Observable<Group> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.get(this.url + id).map(
      (response: Response) => {
        let group: Group = (<any>response);
        // group.createdByUserAccount = this.userService.getUser(group.createdByUserAccountId);
        // group.updatedByUserAccount = this.userService.getUser(group.updatedByUserAccountId);
        return group;
      }
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  addGroup(group: Group): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.post<Response>(
      this.url,
      group
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  updateGroup(group: Group): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + group.id,
      group
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }

  deleteGroup(group: Group): Observable<Response> {
    //this.authService.reauthenticateIfTokenExpired();

    this.loadingService.displayLoadingSpinner();
    return this.http.put<Response>(
      this.url + "Delete/" + group.id,
      group
    ).finally(() => this.loadingService.hideLoadingSpinner());
  }
}
