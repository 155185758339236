import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from "@angular/http";
import { BaseComponent } from 'app/shared/base.component';

import { CommunicationHistoryService } from "../../../shared/services/communication-history.service";
import { ContactService } from "../../../shared/services/contact.service";
import { ToastService } from "../../../shared/services/toast.service";
import { AuthService } from "../../../auth/auth.service";
import { ConfirmationService } from "primeng/primeng";

import { Contact } from "../../../shared/models/contact.model";
import { CommunicationLog, CommunicationLogListRow } from "../../../shared/models/communication-log.model";

import { UtilityService } from 'app/views/shared/services/utility.service';
import { TenantAppSettingService } from 'app/views/auth/tenant-app-setting.service';
import { AuditService } from 'app/views/shared/services/audit.service';

@Component({
  selector: 'app-communication-history-list',
  templateUrl: './communication-history-list.component.html',
  styleUrls: ['./communication-history-list.component.scss']
})
export class CommunicationHistoryListComponent extends BaseComponent implements OnInit {
  contactId: number = 0;
  opportunityId: number = 0;
  complaintId: number = 0;
  communicationLogs: CommunicationLogListRow[] = [];

  isNumberVisible: boolean[] = [];
  isEmailVisible: boolean[] = [];

  public isGlobalList = true;

  public fromDate: Date = new Date()
  public toDate: Date = new Date()

  dateRange: string = (new Date().getFullYear() - 10) + ':' + (new Date().getFullYear() + 5); 

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private communicationHistoryService: CommunicationHistoryService,
    private contactService: ContactService,
    public authService: AuthService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private utilService: UtilityService,
    private appSetting: TenantAppSettingService,  // used on HTML
    private auditService: AuditService
  ) { super() }

  ngOnInit() {
    this.utilService.setPageTitle('Communication History');

    this.fromDate.setMonth(this.fromDate.getMonth()-6);
    this.toDate.setMonth(this.toDate.getMonth()+6);

    this.route.params.subscribe(
      (params: Params) => {
        const contactId = params['contactId'];
        var opportunityId = params['opportunityId'];
        const complaintId = params['complaintId'];

        if(opportunityId == undefined)
        opportunityId = "all";

        this.setupCommunicationLogs(contactId, opportunityId,complaintId);
      }
    );

    // Listen to changes made to communicationLogs
    this.subsriptions$.push(this.communicationHistoryService.logsChanged.subscribe(
      () => {
        this.setupCommunicationLogs(this.contactId, this.opportunityId,this.complaintId);
      }
    ));
  }

  setupCommunicationLogs(contactId: any, opportunityId: any, complaintId:any) {
    debugger;
    if (contactId !== 'all' && contactId !== 0) {
      this.isGlobalList = false;
      this.contactId = +contactId;

      if (opportunityId == "all") {
        this.opportunityId =0
        this.complaintId = 0;
        this.onGetCommunicationLogsByContactId(this.contactId,true);
      }
      else if (opportunityId && opportunityId !== 'general') {
        this.opportunityId = + opportunityId;
        this.complaintId = 0;
        this.onGetCommunicationLogsByOpportunityId(this.opportunityId);
      }
      else if(complaintId && complaintId !== 'select'){
        this.complaintId = + complaintId;
        this.opportunityId = 0;
        this.onGetCommunicationLogsByComplaintId(this.complaintId);
      }
      else {
        this.complaintId = 0;
        this.opportunityId = 0;
        this.onGetCommunicationLogsByContactId(this.contactId,false);
      }
    }
    else {
      this.isGlobalList = true;
      this.contactId = 0;
      this.opportunityId = 0;
      this.complaintId = 0;
      this.onGetCommunicationLogs();
    }
  }

  onGetCommunicationLogsByContactId(contactId: number, includeAll: boolean) {
    this.communicationHistoryService.getCommunicationLogsByContactId(contactId, includeAll).subscribe(
      (communicationLogs: CommunicationLogListRow[]) => {
        this.communicationLogs = communicationLogs;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication logs", error);
      }
    );
  }

  onGetCommunicationLogsByOpportunityId(opportunityId: number) {
    this.communicationHistoryService.getCommunicationLogsByOpportunityId(opportunityId).subscribe(
      (communicationLogs: CommunicationLogListRow[]) => {
        this.communicationLogs = communicationLogs;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication logs", error);
      }
    );
  }

  onGetCommunicationLogsByComplaintId(complaintId: number) {
    this.communicationHistoryService.getCommunicationLogsByComplaintId(complaintId).subscribe(
      (communicationLogs: CommunicationLogListRow[]) => {
        this.communicationLogs = communicationLogs;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication logs", error);
      }
    );
  }

  onGetCommunicationLogs() {
    var from = this.fromDate.toLocaleDateString("en-GB");
    var to = this.toDate.toLocaleDateString("en-GB");
    this.communicationHistoryService.getCommunicationLogs(from,to).subscribe(
      (communicationLogs: CommunicationLogListRow[]) => {
        this.communicationLogs = communicationLogs;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving communication logs", error);
      }
    );
  }

  onDeleteCommunicationLog(communicationLog: CommunicationLogListRow) {
   

    this.confirmationService.confirm({
      key: 'deleteLog',
      message: 'Are you sure you want to delete this communication log assigned to ' + name + '?',
      accept: () => {
        communicationLog.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.communicationHistoryService.deleteCommunicationLog(communicationLog).subscribe(
          (response: Response) => {
            this.communicationHistoryService.logsChanged.next();
            this.toastService.createSuccessMessage(
              "Success",
              "The communication log has been deleted."
            );
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting communication log", error);
          }
        )
      },
      reject: () => {
        this.toastService.createInfoMessage(
          "Aborted",
          "The delete for the communication log assigned to " + name + " has been aborted."
        );
      }
    });
  }

  onDataChange() {
    this.setupCommunicationLogs(this.contactId, this.opportunityId, this.complaintId);
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }

  displayMobileNumber(index: number, contactName, contactId): void {
    this.isNumberVisible[index] = !this.isNumberVisible[index];
    this.auditService.auditViewMobileNumber(contactName, contactId);
  }

  displayEmail(index: number, contactName, contactId): void {
    this.isEmailVisible[index] = !this.isEmailVisible[index];
    this.auditService.auditViewEmail(contactName, contactId);
  }
}