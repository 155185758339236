import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'app/shared/base.component';

import { ActivatedRoute, Params, Router } from "@angular/router";
import { Response } from "@angular/http";

import { AppSettings } from 'app.settings'

import { AuthService } from "../../../auth/auth.service";
import { ContactService } from "../../../shared/services/contact.service";
import { ContactFileService } from "../../../shared/services/contact-file.service";
import { ToastService } from "../../../shared/services/toast.service";

import { Contact } from "../../../shared/models/contact.model";
import { ContactFile } from "../../../shared/models/contact-file.model";

import { DropzoneConfigInterface } from "ngx-dropzone-wrapper/dist";
import { ConfirmationService } from "primeng/primeng";
import { UtilityService } from 'app/views/shared/services/utility.service';

@Component({
  selector: 'app-contact-file-list',
  templateUrl: './contact-file-list.component.html',
  styleUrls: ['./contact-file-list.component.scss']
})
export class ContactFileListComponent extends BaseComponent implements OnInit {
  contactFiles: ContactFile[] = [];
  contact: Contact = new Contact();

  headingText: string = "File Uploads for ";

  crmApiEndPoint: string = AppSettings.API_ENDPOINT;

  //** Dropzone */
  DROPZONE_CONFIG: DropzoneConfigInterface = {
    url: this.crmApiEndPoint + 'api/contactfiles/',
    headers: {
      'Authorization': 'Bearer ' + this.authService.getUser().access_token
    },
    paramName: "file",
    parallelUploads: 1,
    errorReset: 3,
    acceptedFiles: "image/*, application/pdf, application/vnd.ms-excel, application/msword",
    maxFilesize: 50 // - 50MB
    // autoReset: 1,
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactService: ContactService,
    private contactFileService: ContactFileService,
    private authService: AuthService,
    private toastService: ToastService,
    private confirmationService: ConfirmationService,
    private utilService: UtilityService
  ) { super() }

  ngOnInit() {
    this.utilService.setPageTitle('Contact File Uploads');
    this.route.parent.params.subscribe(
      (parentParams: Params) => {
        const contactId = +parentParams['id'];

        if (!contactId) {
          this.router.navigate(['/error/404']);
          return;
        }

        this.contactService.getContact(contactId).subscribe(
          (contact: Contact) => {
            this.contact = contact;

            let headingTextSuffix =
              this.contact.fullName.trim().length > 0 ?
                this.contact.fullName :
                this.contact.email ? this.contact.email :
                this.contact.mobilePhoneNumber;

            this.headingText += headingTextSuffix;

            this.onGetContactFiles(); // - Get contact files after contact is retrieved
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error retrieving contact", error);
          }
        );

        // - Listen to changes made to contact files to update table
        this.subsriptions$.push(this.contactFileService.contactFilesChanged.subscribe(
          () => {
            this.onGetContactFiles();
          }
        ));
      }
    );
  }

  onGetContactFiles() {
    this.contactFileService.getContactFilesByContactId(this.contact.id).subscribe(
      (contactFiles: ContactFile[]) => {
        this.contactFiles = contactFiles;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving contact files", error);
      }
    );
  }

  onDeleteContactFile(contactFile: ContactFile) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the contact file ' + contactFile.displayFileName + '?',
      accept: () => {
        contactFile.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.contactFileService.deleteContactFile(contactFile).subscribe(
          (response: Response) => {
            this.contactFileService.contactFilesChanged.next();
            this.toastService.createSuccessMessage("Success", "The contact file " + contactFile.displayFileName + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting contact file", error);
          }
        );
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for contact file " + contactFile.displayFileName + " has been aborted.");
      }
    });
  }

  onUploadError($event) {
    this.toastService.createErrorMessage("Error uploading files", $event[2] ? $event[2].response : $event[1]);
  }

  onUploadSuccess($event) {
    this.toastService.createSuccessMessage("Success", "The file has been uploaded.");
  }

  onQueueComplete($event) {
    this.contactFileService.contactFilesChanged.next();
  }

  onSending($event) {
    let file = $event[0];
    let xmlHttpRequest = $event[1];
    let formData = $event[2];

    formData.append("contactId", this.contact.id);
    formData.append("createdByUserAccountId", this.authService.applicationProfileUser().id);
  }

  getFile(contactFile: ContactFile) {
      console.log(contactFile);
      
      this.contactFileService.downloadContactFile(contactFile).subscribe(
        (response: Blob) => {
          const url = window.URL.createObjectURL(response as any);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = contactFile.displayFileName;
          document.body.appendChild(a);
          a.click();
          setTimeout( _ => {
            window.URL.revokeObjectURL(url);
          })
        },
        (error: Response) => {
          console.log(error);
          this.toastService.createErrorMessage("Error downloading the file", error);
        }
      );
  }
}
