import { Injectable, OnInit } from '@angular/core';

import 'rxjs/Rx';
import { AuthService } from "../../auth/auth.service";
import { LoadingSpinnerService } from "./loading-spinner.service";

import { AppSettings } from 'app.settings'
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/Rx';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PrivateModeService {
  private urlToggle: string = AppSettings.API_ENDPOINT + 'api/PrivateMode/toggle';
  private urlGet: string = AppSettings.API_ENDPOINT + 'api/PrivateMode/get';

  privateModeEmitter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private loadingService: LoadingSpinnerService
  ) {
    this.getPrivateMode();
  }

  ngOnInit(isPrivateModeEnabled : boolean): void {
    this.privateModeEmitter.next(isPrivateModeEnabled);
  }

  togglePrivateMode() {
    this.http.get(this.urlToggle)
      .subscribe((data: { isPrivateModeEnabled: boolean, subjectId: string }) => {
        this.privateModeEmitter.next(data.isPrivateModeEnabled);
      })
  }

 async getPrivateMode() {
   await this.http.get(this.urlGet)
      .subscribe((data: { isPrivateModeEnabled: boolean, subjectId: string }) => {
        this.ngOnInit(data.isPrivateModeEnabled);
      })
  }
}