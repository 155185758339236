import { Component, OnInit, ViewChild } from '@angular/core';
import { Response } from "@angular/http";

import { AuthService } from "../../auth/auth.service";
import { UserService } from "../../shared/services/user.service";
import { ToastService } from "../../shared/services/toast.service";
import { ConfirmationService } from "primeng/primeng";

import { CrmUser } from "../../shared/models/user.model";
import { UpdateModel } from "app/views/shared/models/update.model";
import { AppSettings } from 'app.settings';

import { Subject } from "rxjs/Rx";
import { UtilityService } from 'app/views/shared/services/utility.service';

@Component({
  selector: 'app-account-list',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.scss']
})
export class AccountListComponent implements OnInit {

  users: CrmUser[] = [];

  inviteUserUrl : string = `${AppSettings.AUTHORITY_ENDPOINT}/account/invite?returnUrl=${AppSettings.PORTAL_URI}`

  constructor(
    public authService: AuthService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService,
    private utilService: UtilityService
  ) { }

  ngOnInit() {
    this.utilService.setPageTitle('User List');
    this.onGetUsers();
  }

  onGetUsers() {
    this.userService.getUsers().subscribe(
      (users: CrmUser[]) => {
        // 
        this.users = users;
      },
      (error: Response) => {
        this.toastService.createErrorMessage("Error retrieving users", error);
      }
    );
  }

  onDeleteUser(user: UpdateModel) {
    
    let fullName = user.firstName + " " + user.lastName;

    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the user ' + fullName + '?',
      accept: () => {

        user.updatedByUserAccountId = this.authService.applicationProfileUser().id;

        this.userService.deleteUser(user).subscribe(
          (response: Response) => {
            this.onGetUsers();
            this.toastService.createSuccessMessage("Success", "The user " + fullName + " has been deleted.");
          },
          (error: Response) => {
            this.toastService.createErrorMessage("Error deleting user", error);
          }
        );
      },
      reject: () => {
        this.toastService.createInfoMessage("Aborted", "The delete for user " + fullName + " has been aborted.");
      }
    });
  }

  trackByFn(index: number, row: any) {
    return row.id;
  }
}
